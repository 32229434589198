<template>
    <div>
        <div class="upload-work-class"></div>
        <div class="upload-work-select-class" ref="treeWrap">
            <div class="upload-Work-Select-Class">
                <div class="close-Button-Class">
                    <i class="el-icon-close" @click="$emit('editButClick')"></i>
                </div>
            </div>
            <div class="upload-Submit-From">
                <el-form ref="form" :model="form" label-width="80px">

                    <el-form-item label="课时类型">
                        <el-select @change="selectLessonChange" v-model="lesson_id" placeholder="请选择课时类型">
                            <el-option v-for="(item,index) in lessonList" :key="index" :label="item.lesson_name"
                                       :value="item.lesson_id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="作品类型">
                        <el-select @change="selectClassChange" v-model="class_id" placeholder="请选择作品类型">
                            <el-option v-for="(item,index) in searchTypeData" :key="index" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="作品名字">
                        <el-input v-model="work_name" placeholder="请输入作品名字"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit"
                                   style="background: #FF8B0C;border: 1px #ff8a0c solid">修改作品
                        </el-button>
                        <el-button @click="$emit('editButClick')">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import LessonVideo from "../../models/Home/LessonVideo";
import Works from "../../models/Home/Works";
import Qs from 'qs';
import {Toast} from 'vant';

export default {
    props: ['workId'],
    name: "CommentEditAlert",
    data() {
        return {
            lessonList: [],
            class_id: '',
            class_name: '',
            lesson_name: '',
            lesson_id: '',
            work_name: '',
            form: {},
            searchTypeData: [{
                name: '课堂练习',
                state: false,
                icon: './img/search-study.png',
                cutIcon: './img/search-cut-study.png',
                svgIcon: 'study-icon',
                id: 1
            }, {
                name: '课后作业',
                state: false,
                icon: './img/search-work.png',
                cutIcon: './img/search-cut-work.png',
                svgIcon: 'class-work-icon',
                id: 2
            }, {
                name: '自由编程',
                state: false,
                icon: './img/search-pro.png',
                cutIcon: './img/search-cut-pro.png',
                svgIcon: 'pro-icon',
                id: 3
            },
            ],
        }
    },
    created() {
        this.getLessonListInfo();
        // this.getWorkListInfo();
    },
    mounted() {
    },
    methods: {
        selectLessonChange(value) {
            this.lessonList.forEach(item => {
                if (item.lesson_id === value) {
                    this.lesson_name = item.lesson_name;
                }
            })
        },
        selectClassChange(value) {
            this.searchTypeData.forEach(item => {
                if (item.id === value) {
                    this.class_name = item.name;
                }
            })
        },
        onSubmit() {
            console.log('submit!');
            if (this.lesson_id === '') {
                Toast({
                    message: '请选择课时类型',
                });
                return false
            }
            if (this.class_id === '') {
                Toast({
                    message: '请选择作品类型',
                });
                return false
            }
            if (this.work_name === '') {
                Toast({
                    message: '请输入作品名字',
                });
                return false
            }

            this.$Dialog.confirm(
                {
                    message: '确定修改此作品吗？',
                    confirmButtonColor: '#FF8D15',
                })
                .then(() => {
                    const params = {
                        id: this.workId,
                        edu_lesson_id: this.lesson_id,
                        category: this.class_id,
                        name: this.work_name
                    }
                    Works.editWork(params, ({type, list}) => {
                        if (type === 200) {
                            this.$emit('editButClick');
                            const selectInfo = {
                                class_id: this.class_id,
                                class_name: this.class_name,
                                lesson_name: this.lesson_name,
                                lesson_id: this.lesson_id,
                                work_name: this.work_name,
                                work_id: this.workId,
                            }
                            console.log(selectInfo)
                            this.$emit('saveEditWorkInfo', selectInfo)
                        }
                    });

                })
                .catch(() => {
                    // on cancel
                });
        },
        getWorkListInfo() {
            let params = {
                work_id: this.workId,
                group_id: this.$store.getters.getGroupData.group_id,
                page: 1,
                limit: 10
            }
            Works.getWorksShow(params, ({type, list}) => {
                if (type === 200) {
                    this.lesson_id = list.list[0].lesson_id;
                    this.class_id = list.list[0].class_id;
                    this.work_name = list.list[0].work_name;
                    this.lesson_name = list.list[0].lesson_name;
                    this.class_name = list.list[0].class_name;
                }
            })
        },
        getLessonListInfo() {
            let params = {
                course_id: this.$store.getters.getCourseData[0].id,
                group_id: this.$store.getters.getGroupData.group_id,
                page: 1,
                limit: 500
            }
            LessonVideo.getLessonList(params, ({type, list}) => {
                if (type === 200) {
                    this.lessonList = list.list;
                    this.getWorkListInfo();
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.upload-work-class {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center; /*实现水平居中*/
    align-items: center; /*实现垂直居中*/
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
}

.upload-work-select-class {
    width: 500px;
    background: #ffffff;
    opacity: 1;
    border-radius: 20px;
    position: fixed;
    top: 50%;
    z-index: 501;
    left: 50%;
    transform: translate(-50%, -50%); /*向左向上分别平移自身的一半*/
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);

    .close-Button-Class {
        line-height: 40px;
        height: 40px;
        //float: right;
        text-align: right;
        margin-right: 10px;
        font-size: 26px;
        overflow: hidden;

        i {
            cursor: pointer;
        }
    }

    .upload-Submit-From {
        float: none;
        width: 80%;
        margin: 0 auto;
    }
}


</style>
