var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"share-alert-class"},[_c('div',{staticClass:"mask-layer-class"}),_c('div',{ref:"treeWrap",staticClass:"share-box-class"},[_c('div',{staticClass:"share-padding-class"},[_c('i',{staticClass:"el-icon-close",on:{"click":function($event){return _vm.$emit('closeShareAlert')}}})]),_c('div',{staticClass:"work-text-name"},[_c('div',{staticClass:"text"},[_vm._v("我完成了")]),_c('div',{staticClass:"text text-name",staticStyle:{"color":"#ffc000"}},[_vm._v(_vm._s(_vm.shareData.work_name))]),_c('div',{staticClass:"text"},[_vm._v("真的很棒！")])]),_c('div',{staticClass:"share-mian"},[_c('div',{staticClass:"share-center-main"},[_vm._m(0),_c('div',{staticClass:"work-img"},[(
              _vm.shareData.work_image_url !== '' &&
              _vm.shareData.work_image_url != undefined
            )?_c('img',{attrs:{"src":_vm.shareData.work_image_url,"alt":""}}):_c('img',{attrs:{"src":_vm.workImg,"alt":""}})]),_c('div',{staticClass:"boby-msg"},[_c('div',{staticClass:"box-info"},[_c('div',{staticClass:"name"},[_vm._v("我家宝贝："+_vm._s(_vm.shareData.user_name))]),_c('div',{staticClass:"boss-name"},[_vm._v(_vm._s(_vm.shareData.bureau_name))]),_c('div',{staticClass:"text"},[_vm._v("学习果宝编程课程")]),_c('div',{staticClass:"work-num"},[_vm._v(" 累计完成作品 "),_c('span',[_vm._v(_vm._s(_vm.shareData.work_num))]),_vm._v(" 件 ")])])]),_c('img',{staticClass:"static-img1",attrs:{"src":require("../../assets/img/share/robot.png"),"alt":""}}),_c('img',{staticClass:"static-img2",attrs:{"src":require("../../assets/img/share/robot-2.png"),"alt":""}})])]),_c('div',{staticClass:"school-msg-qrcode",staticStyle:{"height":"83px","border-radius":"0 0 15px 15px"}},[_c('div',{staticClass:"address-phone"},[_c('div',{staticClass:"school-msg"},[_c('img',{attrs:{"src":require("../../assets/img/share/school-icon1.png"),"alt":""}}),_c('div',{staticClass:"msg-it"},[_vm._v(" "+_vm._s(_vm.shareData.bureau_name)+" ")])]),_c('div',{staticClass:"school-msg"},[_c('img',{attrs:{"src":require("../../assets/img/share/school-icon2.png"),"alt":""}}),_c('div',{staticClass:"msg-it"},[(
                _vm.shareData.group_phone !== undefined &&
                _vm.shareData.group_phone !== ''
              )?_c('span',[_vm._v(_vm._s(_vm.shareData.group_phone))]):_c('span',[_vm._v(_vm._s(_vm.shareData.bureau_phone))])])]),_c('div',{staticClass:"school-msg"},[_c('img',{attrs:{"src":require("../../assets/img/share/school-icon3.png"),"alt":""}}),_c('div',{staticClass:"msg-it"},[_vm._v(" "+_vm._s(_vm.shareData.bureau_location)+" ")])])]),_c('div',{staticClass:"tip-qrcode"},[_c('div',{staticClass:"tip-text"},[_vm._v("扫码查看作品")]),_c('img',{staticClass:"samll-img",attrs:{"src":_vm.shareData.qr_code,"alt":""},on:{"click":_vm.hanleQrcode}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.qrCodeState),expression:"qrCodeState"}],staticClass:"big-qr-code"},[_c('img',{staticClass:"big-img",attrs:{"src":_vm.shareData.qr_code,"alt":""}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"work-title"},[_c('img',{attrs:{"src":require("../../assets/img/share/radius-icon.png"),"alt":""}}),_c('div',[_vm._v("作品封面")])])
}]

export { render, staticRenderFns }