<template>
    <div class="image-alert-class" ref="treeWrap">
        <div class="top-box">
            <div class="photo-num-class">{{ index + 1 }}/{{ resultImg.length }}</div>
            <div class="icon-class" @click="closeAlertClick"><i class="el-icon-close"></i></div>
        </div>
        <div class="main-class">
            <i class="el-icon-arrow-left" @click="leftClick"></i>
            <el-image
                class="comment-img-class"
                :src="resultImg[index].url"
                fit="cover">
            </el-image>
            <i class="el-icon-arrow-right" @click="rightClick"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "CommentImageAlert",
    props: ['resultImg'],
    data() {
        return {
            index: 0,
        }
    },
    mounted() {
        document.addEventListener('mouseup', (e) => {
            let tree = this.$refs.treeWrap
            if (tree) {
                if (!tree.contains(e.target)) {
                    this.$emit('lookCommentImgClick')
                    // this.isShowTree =false
                }
            }
        })
    },
    methods: {
        closeAlertClick() {
            this.$emit('lookCommentImgClick')
        },
        rightClick() {
            console.log(this.index);
            if (this.index + 1 < this.resultImg.length) {
                this.index++;
            }
        },
        leftClick() {
            if (this.index != 0) {
                this.index--;
            }
        }
    }
}
</script>

<style lang="less" scoped>

.image-alert-class {
    position: fixed;
    width: 480px;
    height: 310px;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /*向左向上分别平移自身的一半*/
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    z-index: 20000000;

    .top-box {
        width: 100%;
        height: 40px;
        background: #121212;
        opacity: 0.65;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        line-height: 40px;
        color: #FFFFFF;

        .photo-num-class {
            line-height: 40px;
            font-size: 18px;
            padding: 0 10px;
        }

        .icon-class {
            margin-left: auto;
            font-size: 28px;
            cursor: pointer;
            padding: 0 10px;
        }
    }

    .main-class {
        width: 480px;
        height: 270px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
        opacity: 1;
        border-radius: 0px 0px 10px 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .comment-img-class {
            height: 200px;
        }

        .el-icon-arrow-left {
            font-size: 40px;
            font-weight: bold;
            cursor: pointer;
            margin-right: auto;
        }

        .el-icon-arrow-right {
            font-size: 40px;
            font-weight: bold;
            cursor: pointer;
            margin-left: auto;
        }
    }
}
</style>
