<template>
  <el-dialog
    class="student-list"
    title="查看素材"
    :center="true"
    :visible.sync="dialogState"
    width="100%"
    z-index="500"
    fullscreen
  >
    <input
      v-show="false"
      ref="fileRef"
      type="file"
      accept="image/png, image/jpeg, image/jpg"
      @change="fileChange($event)"
    />
    <div class="img-list">
      <div class="img-container" v-for="(it, index) in photoData" :key="index">
        <div class="original-ai">
          <div style="flex: 1">
            <div
              class="origin-img"
              :style="
                it.is_old_valid &&
                `backgroundImage: url(${it.old_image_url});backgroundSize:contain`
              "
            ></div>
            <div class="upload-btn" @click="uploadImage(it)">上传原图</div>
          </div>

          <div style="flex: 1">
            <div
              class="ai-img"
              :style="
                it.is_new_valid
                  ? `backgroundImage: url(${it.new_image_url});`
                  : `background:#eeeeee`
              "
            >
              <span v-if="!it.is_new_valid">待生成图片</span>
            </div>
            <div class="img-label">生成图</div>
          </div>
        </div>
        <div class="intro">
          <div class="example">
            <div class="ex-label">示例图</div>
            <div
              class="ex-img"
              :style="{
                backgroundImage: 'url(' + it.intro_image_url + ')',
              }"
            ></div>
          </div>
          <div class="action-role">
            <div class="title">名称：{{ it.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Student from "@/models/Home/Student";
import axios from "axios";
import { apiRequest } from "../../router/apiRequest";
import { MsgTip } from "../common/MsgTip";
export default {
  data() {
    return {
      dialogState: false,
      ticket_id: 0,
      ticket_index: 0,
      ticket_title: "",
      photoData: [],
    };
  },
  created() {},
  watch: {
    dialogState(newVal) {
      newVal && this.getPhotoData();
    },
  },
  methods: {
    getPhotoData() {
      const params = { ticket_id: this.ticket_id };
      Student.getPhotoList(params, ({ type, list }) => {
        if (type === 200) {
          this.photoData = list;
          console.log(this.photoData);
        }
      });
    },
    uploadImage(item) {
      this.ticket_index = item.index;
      this.ticket_title = item.title;
      this.$refs.fileRef.dispatchEvent(new MouseEvent("click"));
    },
    fileChange(event) {
      const files = event.target.files;
      let formDatas = new FormData();

      formDatas.append("file", files[0]);
      formDatas.append("ticket_id", this.ticket_id);
      formDatas.append("index", this.ticket_index);
      let cndTestUrl = apiRequest();
      axios({
        method: "POST",
        url: cndTestUrl + "/bureau/v1/uploadCartoonTicketImage",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        //这部分非常重要，否则formdata会被转格式
        transformRequest: [
          function () {
            return formDatas;
          },
        ],
        data: formDatas,
        params: formDatas,
      }).then((res) => {
        console.log(res);
        MsgTip({ type: "success", message: "上传成功" });
        this.getPhotoData();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.img-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  .img-container {
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 16px;
    position: relative;
    .original-ai {
      display: flex;
      justify-content: center;
      gap: 16px;
      .origin-img {
        background-image: url("../../assets//img/null-photo.png");
        flex: 1;
        height: 160px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
      .ai-img {
        flex: 1;
        height: 160px;
        text-align: center;
        line-height: 160px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .upload-btn {
        width: 100px;
        margin: 4px auto;
        height: 32px;
        line-height: 32px;
        background: #ff8d0d;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
        color: #ffffff;
        font-weight: bold;
        font-size: 14px;
      }
      .img-label {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin: 8px 0;
      }
    }

    .intro {
      display: flex;
      gap: 16px;
      border-top: 1px solid #f3f3f3;
      .example {
        width: 80px;
        .ex-img {
          width: 100%;
          height: 100px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
        .ex-label {
          text-align: center;
          font-size: 14px;
          margin: 8px 0;
        }
      }
      .action-role {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        .title {
          margin-top: 16px;
        }
      }
    }
  }
}
/deep/ .el-dialog.is-fullscreen {
  background: #f4f5fa;
}

/deep/ .el-dialog__headerbtn .el-dialog__close {
  font-weight: bold;
  font-size: 30px;
}
</style>
