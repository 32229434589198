<template>
    <div class="select-menu" id="menu-id" ref="treeWrap">
        <div class="Triangle" :class="{'first-select':selectIndex === 0}"></div>
        <ul id="menu-list">
            <li v-for="(item,index) in list" :key="index" @click="handleMenu(index,item)"
                :class="{'active-li':index===selectIndex}">
                <div class="box-border">
                    <svg-icon :icon-class="item.icon" class="svg-icon-class"></svg-icon>
                    <div>{{ item.name }}</div>
                </div>
            </li>
        </ul>

        <MyCenter v-if="centerState" :visible.sync='centerState'></MyCenter>
        <HelpList v-if="helpState" :visible.sync='helpState'></HelpList>
        <SavePassword v-if="pwdState" :visible.sync='pwdState'></SavePassword>
        <Logout v-if="logoutState" :visible.sync='logoutState'></Logout>
    </div>
</template>

<script>
import MyCenter from "@/components/ipad/MyCenter";
import HelpList from "@/components/ipad/HelpList";
import SavePassword from "@/components/ipad/SavePassword";
import Logout from "@/components/ipad/Logout";

export default {
    name: "SelectMenu",
    components: {
        MyCenter,
        HelpList,
        SavePassword,
        Logout
    },
    data() {
        return {
            list: [{
                icon: 'ipad-center-1',
                name: '个人中心',
                url: '',
                state: 'alert',
            },
                //     {
                //     icon: 'ipad-center-2',
                //     name: '帮助',
                //     url: '',
                //     state: 'alert',
                // },
                {
                    icon: 'ipad-center-3',
                    name: '修改密码',
                    url: '',
                    state: 'alert',
                }, {
                    icon: 'ipad-center-4',
                    name: '下载',
                    url: '',
                    state: 'page',
                }, {
                    icon: 'ipad-center-5',
                    name: '退出',
                    url: '',
                    state: 'alert',
                }],
            selectIndex: null,
            menuState: false,
            centerState: false,
            helpState: false,
            pwdState: false,
            logoutState: false
        }
    },

    mounted() {
        let menuHeight = document.getElementById('menu-list').clientHeight;
        let menu_id = document.getElementById('menu-id');
        menu_id.style.bottom = `-${menuHeight / 10 + 3.5}rem`;

        let _this = this;
        document.addEventListener('mouseup', (e) => {
            let tree = this.$refs.treeWrap
            if (tree) {
                if (!tree.contains(e.target)) {
                    _this.$emit('update:visible', false);
                }
            }
        })
    },
    methods: {
        handleMenu(index, item) {
            this.selectIndex = index;
            if (item.state === 'alert') {
                switch (item.name) {
                    case '个人中心':
                        this.centerState = true;
                        break;
                    case '帮助':
                        this.helpState = true;
                        break;
                    case '修改密码':
                        this.pwdState = true;
                        break;

                    case '退出':
                        this.logoutState = true;
                        break;
                }
            }
            if (item.state === 'page') {
                switch (item.name) {
                    case '下载':
                        this.$router.push({ path: '/ipad/download' })
                        break;
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
.select-menu {
    position: absolute;
    right: 0;
    z-index: 10;

    .Triangle {
        width: 2.5rem;
        height: 2.5rem;
        -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        background: #FFFFFF;
        margin-left: auto;
        //margin-bottom: -0.3rem;
        overflow: hidden;
        margin-right: 0.8rem;
    }
    .first-select{
        background: #FFC17F;
    }

    ul {
        width: 14rem;
        background: #FFFFFF;
        border-radius: 1rem 0.5rem 1rem 1rem;
        overflow: hidden;

        .active-li {
            background: #FFC17F;

            .box-border {
                .svg-icon-class {
                    color: #FFFFFF !important;
                }

                div {
                    color: #FFFFFF !important;
                }
            }
        }

        li {

            .box-border {
                display: flex;
                align-items: center;
                width: 12rem;
                margin: 0 auto;
                padding: 1rem 0rem;
                border-bottom: 0.1rem solid #F2F2F2;
                box-sizing: border-box;
            }

            .svg-icon-class {
                width: 2.4rem;
                height: 2.4rem;

            }

            div {
                color: #B2B2B2;
                font-size: 1.4rem;
                font-weight: bold;
                margin-left: 1rem;
            }
        }
    }
}
</style>
