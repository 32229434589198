import BaseModel from "../../common/model/BaseModel";
import BaseCall from "../../common/model/BaseCall";

export default class CommentTag extends BaseModel {
    constructor(data = {}) {
        super(data);
        this.format = {
            load_text: {},
        }
    }

    // 展示评论默认标签
    static getListCommentTags(params, fn) {
        BaseCall.get('getListCommentTags', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 创建新的标签
    static createCommentTag(params, fn) {
        BaseCall.post('createCommentTag', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 删除标签
    static deleteCommentTag(params, fn) {
        BaseCall.get('deleteCommentTag', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 展示评论内容
    static getListComment(params, fn) {
        BaseCall.get('getListComment', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    //评论绑定tag
    static bindCommentTag(params, fn) {
        BaseCall.post('bindCommentTag', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    //评论绑定tag
    static editComment(params, fn) {
        BaseCall.post('editComment', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    //解绑评论绑定tag
    static unbindCommentTag(params, fn) {
        BaseCall.post('unbindCommentTag', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
    //解绑评论绑定tag
    static editCommentTag(params, fn) {
        BaseCall.post('editCommentTag', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
}
