<template>
    <div class="works-gather-class">
        <div class="gather-class">
            <div class="works-screen-class">
                <div>
                    <ul>
                        <li v-for="(item,index) in searchTypeData" :key="index">
                            <div class="search-type-class" :class="{'select-but-class' : selectStyle === index}">
                                <svg-icon :icon-class="item.svgIcon" :class="{'svgIconColor':selectStyle === index}"
                                          class="svg-icon-class"/>
                                <div class="type-name-class">{{ item.name }}</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="cut-pattern-class">
                    <div class="pattern1-class" @click="sortButClick">按作品集合排序
                        <svg-icon :class="{'svg-icon-active':sortButState}" icon-class="point-icon"
                                  class="svg-icon-class"/>
                    </div>
                    <div class="pattern2-class" ref="treeWrap" v-if="sortButState" @click="$router.push({path:'studentWorks'})">按时间排序
                    </div>
                </div>
            </div>

            <div class="works-main-class" id="works-main-class">
                <div class="works-content-class">
                    <div>
                        <div class="works-detail-class" v-for="(item,index) in worksList" :key="index">
                            <div class="cover-class" @click="coverClick(item)" @mouseenter="enter(index)"
                                 @mouseleave="leave(index)">
                                <div class="cover-box-class">
                                    <div class="detail-class" v-if="conMoveState === index">
                                        <div class="title-name-class">
                                            <div class="name-class">
                                                <span class="headline-class">
                                                    共上传作品数量：{{ item.num }}
                                                </span>
                                                <!--                                                <span class="hot-icon-class">-->
                                                <!--                                                    <el-image class="hot-icon" :src="hotIcon"-->
                                                <!--                                                              fit="cover">-->
                                                <!--                                                    </el-image>-->
                                                <!--                                                </span>-->
                                            </div>

                                        </div>
                                        <div class="tags-class">
                                            <span>
                                                <svg-icon icon-class="figure-icon" class="svg-icon-class"/>
                                            </span>
                                            &nbsp;登录账号：{{ item.student_name }}
                                        </div>
                                        <div class="praise-class">
                                        </div>
                                        <div class="user-class">
                                            <div style="display: flex">
                                                <div class="user-box-class">

                                                    <el-image v-if="item.student_avatar_url === ''" class="head-class"
                                                              :src="defaultAvatar"
                                                              fit="cover">
                                                    </el-image>
                                                    <el-image v-else class="head-class" :src="item.student_avatar_url"
                                                              fit="cover">
                                                    </el-image>
                                                </div>
                                                <div class="user-box-class nickname-class">
                                                    {{ item.nickname }}
                                                </div>
                                                <div class="time-box-class">
                                                    {{
                                                        Number(item.update_time) * 1000 | formatDate3
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-box-class">
                                <div class="back-box1-class"></div>
                                <div class="back-box2-class"></div>
                                <div class="back-box3-class"></div>
                                <div class="big-box-class">
                                    <div class="con-class" :class="{ 'cssActive': conMoveState === index }">
                                    <span>
                                        <el-image class="work-back-img" :src="worksImg1"
                                                  fit="cover">
                                        </el-image>
                                    </span>
                                    </div>
                                    <div class="detail-class" v-if="conMoveState !== index">
                                        <div class="title-name-class">
                                            <div class="name-class">
                                                <span class="headline-class">
                                                    共上传作品数量：{{ item.num }}
                                                </span>
                                                <!--                                                <span class="hot-icon-class">-->
                                                <!--                                                    <el-image class="hot-icon" :src="hotIcon"-->
                                                <!--                                                              fit="cover">-->
                                                <!--                                                    </el-image>-->
                                                <!--                                                </span>-->
                                            </div>

                                        </div>
                                        <div class="tags-class">
                                            <span>
                                                <svg-icon icon-class="figure-icon" class="svg-icon-class"/>
                                            </span>
                                            &nbsp;登录账号：{{ item.student_name }}
                                        </div>
                                        <div class="praise-class">
                                        </div>
                                        <div class="user-class">
                                            <div style="display: flex">
                                                <div class="user-box-class">

                                                    <el-image v-if="item.student_avatar_url === ''" class="head-class"
                                                              :src="defaultAvatar"
                                                              fit="cover">
                                                    </el-image>
                                                    <el-image v-else class="head-class" :src="item.student_avatar_url"
                                                              fit="cover">
                                                    </el-image>
                                                </div>
                                                <div class="user-box-class nickname-class">
                                                    {{ item.nickname }}
                                                </div>
                                                <div class="time-box-class">
                                                    {{
                                                        Number(item.update_time) * 1000 | formatDate3
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="show-all-class" v-if="count > 8 && showButState">
                        <div class="show-but-class" @click="showButClick()">展开全部 <i class="el-icon-arrow-down"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="works-class">

        </div>

        <div class="return-top-class">
            <div class="icon-box-class" @click="returnButClick">
                <div class="icon-back-class">

                </div>
                <div class="icon-text-class">回顶部</div>
            </div>
        </div>
    </div>
</template>

<script>
import Works from "../../models/Home/Works";
import {formatDate} from "../../assets/js/date.js";

export default {
    name: "WorksGather",
    data() {
        return {
            conMoveState: '',
            showButState: true,
            selectStyle: 0,
            gatherState: false,
            searchTypeData: [
                {
                    name: '作品集合',
                    state: true,
                    icon: './img/search-all.png',
                    cutIcon: './img/search-cut-all.png',
                    svgIcon: 'gather-icon',
                    id: 0
                }
            ],
            page: 1,
            limit: 4,
            offset: 10,
            loading: false,
            finished: false,
            hotIcon: './img/hot-icon.png',
            worksImg1: './img/file-img-new.png',
            defaultAvatar: './img/default-head-img2.png',
            worksList: [],
            count: 0,
            sortButState: false,
        }
    },
    created() {
        let _this = this;
        document.addEventListener('mouseup', (e) => {
            let tree = this.$refs.treeWrap
            if (tree) {
                console.log(tree);
                if (tree !== undefined) {
                    if (!tree.contains(e.target)) {
                        // this.isShowTree =false
                        this.gatherState = false;
                        this.sortButState = false;
                    }
                }
            }
        })
        this.getWorksGatherList()
    },
    computed: {
        CourseData() {//监听store的数据
            return this.$store.getters.getCourseData
        }
    },
    watch: {
        CourseData() {
            this.page = 1;
            this.getWorksGatherList();
        }
    },
    methods: {
        //跳转路径
        coverClick(item) {
            this.$router.push({path: 'singleStudentWorks?student_id=' + item.student_id})
        },
        //切换排序
        sortButClick() {
            this.sortButState = this.sortButState === false;
        },
        enter(index) {
            this.conMoveState = index;
        },
        leave(index) {
            console.log(index)
            this.conMoveState = '';
            // this.conMoveState = index;
        },
        // 鼠标移入加入class
        changeActive($event) {
            this.gatherState = true;
        },
        removeActive($event) {
            // this.gatherState = false;
        },
        onLoad() {

        },
        //作品集合
        getWorksGatherList(limit = 8) {
            let param = {
                group_id: this.$store.getters.getGroupData.group_id,// 从缓存里面拿
                page: this.page,
                limit: limit,
            }

            Works.getWorksList(param, ({type, list}) => {
                if (type === 200) {
                    this.worksList = list.list;
                    this.count = list.count;
                }
            })
        },
        showButClick() {
            this.showButState = false;
            this.getWorksGatherList(100);
        },
        returnButClick() {
            let arr = [];
            this.worksList.forEach((item, index) => {
                if (index < 8) {
                    arr.push(item);
                }
            })
            this.worksList = arr;
            console.log(this.worksList);
            this.showButState = true;
            let top = document.getElementById('works-main-class').scrollTop;
            let self = this;
            // 实现滚动效果
            const timeTop = setInterval(() => {
                document.getElementById('works-main-class').scrollTop = top -= 50;
                if (top <= 0) {
                    clearInterval(timeTop);
                }
            }, 10);
        }

    },
    filters: {
        /*
    时间格式自定义 只需把字符串里面的改成自己所需的格式
   */
        formatDate(time) {
            var date = new Date(time);
            return formatDate(date, "yyyy.MM.dd");
        },
        formatDate2(time) {
            var date = new Date(time);
            return formatDate(date, "hh:mm:ss");
        },
        formatDate3(time) {
            var date = new Date(time);
            return formatDate(date, "yyyy.MM.dd. hh:mm");
        },
    },
}
</script>

<style lang="less" scoped>
.works-gather-class {
    width: 100%;
    margin-top: 5px;

    .gather-class {
        .works-screen-class {
            width: 1240px;
            height: 60px;
            margin: 0 auto;
            display: flex;

            li {
                float: left;
                font-size: 14px;
                margin-top: 10px;
            }

            .search-type-class {
                min-width: 60px;
                height: 30px;
                padding: 6px 18px;
                border-radius: 15px;
                cursor: pointer;
                background: #FFFFFF;
                color: #666666;
                display: flex;

                .type-name-class {
                    font-size: 18px;
                    font-weight: bold;
                    margin-left: 2px;
                }

                .svg-icon {
                    width: 20px;
                    height: 20px;
                    color: #FFFFFF;
                    margin-top: 4px;
                }

                .svgIconColor {
                    color: #FFFFFF;
                }
            }

            .search-img-class {
                vertical-align: middle;
            }

            li:not(:first-child) {
                margin-left: 20px;
            }

            .cut-pattern-class {
                margin-left: auto;

                .pattern1-class {
                    max-width: 200px;
                    padding: 0 20px;
                    height: 40px;
                    background: #FFFFFF;
                    line-height: 40px;
                    font-size: 18px;
                    border-radius: 15px;
                    text-align: center;
                    margin-top: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    white-space: nowrap;

                    .svg-icon {
                        width: 20px;
                        height: 20px;
                        vertical-align: middle;
                        color: #949494;
                    }

                    @keyframes leaf1 {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(180deg);
                        }
                    }

                    .svg-icon-active {
                        animation: leaf1 ease 0s both;
                        color: #FF8D0D;
                        margin-top: -4px;
                    }

                }

                .pattern1-class {
                    cursor: pointer;

                    .svg-icon:hover {
                        color: #FF8D0D;
                    }
                }

                .pattern2-class {
                    height: 40px;
                    background: #FFFFFF;
                    line-height: 40px;
                    font-size: 18px;
                    border-radius: 15px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    position: relative;
                    z-index: 1
                }
            }
        }

        .works-main-class::-webkit-scrollbar {
            width: 0 !important
        }

        .works-main-class {
            -ms-overflow-style: none;
        }

        .works-main-class {
            overflow: -moz-scrollbars-none;
        }

        .works-main-class {
            width: 1260px;
            height: calc(100vh - 220px);
            //background: #FF8B0C;
            margin: 30px auto 0 auto;
            //margin: 0 auto;
            overflow-y: scroll;
        }

        .works-content-class {
            width: 1260px;
            height: 740px;
            //background: #FF8B0C;
            margin: 0 auto;
            position: relative;

            .back-box1-class {
                width: 230px;
                height: 10px;
                background: #FFFFFF;
                margin: 0 auto;
                box-shadow: 0px -3px 10px rgba(255, 224, 188, 0.25);
                opacity: 1;
                border-radius: 5px 5px 0 0;
            }

            .back-box2-class {
                width: 250px;
                height: 10px;
                background: #FFFFFF;
                margin: 0 auto;
                box-shadow: 0px -3px 10px rgba(255, 224, 188, 0.25);
                opacity: 1;
                border-radius: 5px 5px 0 0;
            }

            .back-box3-class {
                width: 270px;
                height: 10px;
                background: #FFFFFF;
                margin: 0 auto;
                box-shadow: 0px -3px 10px rgba(255, 224, 188, 0.25);
                opacity: 1;
                border-radius: 5px 5px 0 0;
            }

            .works-detail-class {
                width: 315px;
                height: 380px;
                //display: flex;
                float: left;
                position: relative;

                .cover-class {
                    width: 290px;
                    height: 330px;
                    margin: 0 auto;
                    border-radius: 5px;
                    top: 30px;
                    left: 13px;
                    //opacity: 0.25;
                    position: absolute;
                    //background: #00DBB4;
                    z-index: 20;

                    .cover-box-class {
                        width: 290px;
                        height: 160px;
                        margin: 0 auto;
                        border-radius: 5px;
                        position: absolute;
                        top: 170px;

                        .detail-class {
                            width: 90%;
                            height: 160px;
                            margin: 0 auto;
                            padding: 0 5%;
                            border-radius: 0 0 5px 5px;
                            color: #FFFFFF;

                            .title-name-class {
                                .name-class {
                                    font-size: 14px;
                                    font-weight: bold;
                                    //width: 90%;
                                    height: 40px;
                                    line-height: 40px;

                                    .headline-class {
                                        display: inline-block;
                                        width: 90%;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        font-size: 18px;
                                    }

                                    .hot-icon-class {
                                        display: inline-block;
                                        width: 10%;

                                        .hot-icon {
                                            vertical-align: top;
                                            width: 11px;
                                            height: 14px;
                                        }
                                    }
                                }
                            }

                            .tags-class {
                                width: 100%;
                                font-size: 14px;

                                .svg-icon {
                                    width: 16px;
                                    height: 16px;
                                }
                            }

                            .praise-class {
                                display: flex;
                                height: 37px;
                                line-height: 37px;
                            }

                            .user-class {
                                width: 100%;
                                height: 50px;
                                //float: left;
                                //line-height: 50px;
                                border-top: 1px solid #FFFFFF;
                                //background: #FFFFFF;
                                padding: 10px 0 0 0;

                                .head-class {

                                    width: 30px;
                                    height: 30px;
                                    border-radius: 2px;
                                    vertical-align: middle;
                                }

                                .nickname-class {
                                    //width: 100px;
                                    margin-left: 10px;
                                    font-size: 14px;
                                    color: #FFFFFF;
                                    line-height: 30px;
                                    font-weight: bold;
                                }

                                .time-box-class {
                                    flex: 2;
                                    font-size: 12px;
                                    line-height: 30px;
                                    justify-content: right;
                                    text-align: right;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                    }
                }

                .cover-class:hover {
                    cursor: pointer;

                    .cover-box-class {
                        height: 330px;
                        background: rgba(18, 18, 18, 0.35);
                        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        z-index: 20;
                    }
                }

                @-webkit-keyframes slide-top {
                    0% {
                        -webkit-transform: translateY(0);
                        transform: translateY(0);
                    }
                    100% {
                        -webkit-transform: translateY(-170px);
                        transform: translateY(-170px);
                    }
                }
                @keyframes slide-top {
                    0% {
                        -webkit-transform: translateY(0);
                        transform: translateY(0);
                    }
                    100% {
                        -webkit-transform: translateY(-170px);
                        transform: translateY(-170px);
                    }
                }
                //.cover-class:hover{
                //    //.cover-class{
                //        background: #00DBB4;
                //        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                //        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                //        z-index: 20;
                //    //}
                //
                //}

                .main-box-class {
                    width: 290px;
                    height: 360px;
                    margin: 0 auto;
                    border-radius: 5px;

                    .big-box-class {
                        background: #FFFFFF;
                        border-radius: 5px;
                    }

                    .con-class {
                        width: 100%;
                        height: 170px;

                        .work-back-img {
                            width: 100%;
                            height: 170px;
                            border-radius: 5px 5px 0 0;
                        }
                    }

                    .detail-class {
                        width: 90%;
                        height: 160px;
                        margin: 0 auto;
                        background: #FFFFFF;
                        padding: 0 5%;
                        border-radius: 0 0 5px 5px;

                        .title-name-class {
                            .name-class {
                                font-size: 14px;
                                font-weight: bold;
                                //width: 90%;
                                height: 40px;
                                line-height: 40px;

                                .headline-class {
                                    display: inline-block;
                                    width: 90%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    font-size: 18px;
                                }

                                .hot-icon-class {
                                    display: inline-block;
                                    width: 10%;

                                    .hot-icon {
                                        vertical-align: top;
                                        width: 11px;
                                        height: 14px;
                                    }
                                }
                            }
                        }

                        .tags-class {
                            width: 100%;
                            //height: 20px;
                            font-size: 14px;
                            color: #C4C4C4;

                            .svg-icon {
                                width: 16px;
                                height: 16px;
                            }
                        }

                        .praise-class {
                            display: flex;
                            height: 37px;
                            line-height: 37px;
                        }

                        .user-class {
                            width: 100%;
                            height: 50px;
                            //float: left;
                            //line-height: 50px;
                            border-top: 1px solid rgba(18, 18, 18, 0.10196078431372549);
                            //background: #FFFFFF;
                            padding: 10px 0 0 0;

                            .head-class {

                                width: 30px;
                                height: 30px;
                                border-radius: 2px;
                                vertical-align: middle;
                            }

                            .nickname-class {
                                //width: 100px;
                                margin-left: 10px;
                                font-size: 14px;
                                color: #666666;
                                line-height: 30px;
                                font-weight: bold;
                            }

                            .time-box-class {
                                flex: 2;
                                font-size: 12px;
                                color: #C4C4C4;
                                line-height: 30px;
                                justify-content: right;
                                text-align: right;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }

            .main-box-class:hover {
                height: 380px;
                margin-top: 0;
                cursor: pointer;
            }
        }

        .show-all-class {
            position: absolute;
            //width: 100%;
            height: 100px;
            //margin-top: 20px;
            position: absolute;
            bottom: -150px;

            .show-but-class {
                //width: 100px;
                margin-left: 560px;
                color: #666666;
                font-size: 16px;
                background: #FFFFFF;
                padding: 6px 18px;
                border-radius: 22px;
                cursor: pointer;
            }

            .show-but-class:hover {
                background: #121212;
                color: #FFFFFF;
                opacity: 0.25;
            }
        }

        .return-top-class {
            position: fixed;
            right: 50px;
            bottom: 100px;

            .icon-box-class {
                width: 60px;
                height: 60px;
                background: #FFFFFF;
                border-radius: 5px;
                text-align: center;
                vertical-align: center;
                font-size: 12px;
                color: #C4C4C4;
                cursor: pointer;
                padding-top: 10px;

                .icon-back-class {
                    margin: 0 auto;
                    width: 30px;
                    height: 30px;
                    background-image: url("../../assets/icon/return-top-icon.png");
                    background-size: 100% 100%;
                }

                .icon-text-class {
                    margin-top: 8px;
                }
            }

            .icon-box-class:hover {
                opacity: 0.25;
                background-image: url("../../assets/icon/return-top-cut-icon.png");
                background-size: 100% 100%;
                background: #121212;
            }
        }
    }

    .return-top-class {
        position: fixed;
        right: 50px;
        bottom: 100px;

        .icon-box-class {
            width: 60px;
            height: 60px;
            background: #FFFFFF;
            border-radius: 5px;
            text-align: center;
            vertical-align: center;
            font-size: 12px;
            color: #C4C4C4;
            cursor: pointer;
            padding-top: 10px;

            .icon-back-class {
                margin: 0 auto;
                width: 30px;
                height: 30px;
                background-image: url("../../assets/icon/return-top-icon.png");
                background-size: 100% 100%;
            }

            .icon-text-class {
                margin-top: 8px;
            }
        }

        .icon-box-class:hover {
            opacity: 0.25;
            background-image: url("../../assets/icon/return-top-cut-icon.png");
            background-size: 100% 100%;
            background: #121212;
        }
    }

    .select-but-class {
        background: #FF8B0C !important;
        color: #FFFFFF !important;
    }

    .cssActive {

        animation: css-active 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

        .work-back-img {
            border-radius: 0 0 5px 5px !important;
        }
    }

    @-webkit-keyframes css-active {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(160px);
            transform: translateY(160px);
        }
    }
    @keyframes css-active {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(160px);
            transform: translateY(160px);
        }
    }
}
</style>
