import { Message } from "element-ui";
export function MsgTip(data = {
    type: "info",
    message: "",
}) {
    Message(data);
    const msgIcons = document.getElementsByClassName("el-message__icon");
    Array.from(msgIcons).map((it) => {
        it.style.fontSize = "20px";
    });
}
