<template>
  <div id="work">
    <div id="div1">
      <div id="list" v-infinite-scroll="loadworks" infinite-scroll-disabled="disabled">
        <div class="item" v-for="(item, index) in info" :key="index" @click="hrefClick(item)">
          <div class="work-num">
            <div>{{ item.type_name }}项目</div>
          </div>
          <div class="work-img">
            <img :src="item.work_image_url" alt="">
          </div>
          <div class="info-box">
            <span class="tag">{{ item.class_name }}</span>
            <div class="name-share">
              <div>{{ item.lesson_name }}</div>
              <svg-icon @click.stop="showMyWorks(item)" icon-class="share-icon" class="svg-icon-class" />
            </div>
            <div class="user">
              <div><img :src="item.avatar_url" alt=""></div>
              <div>{{ item.student_name }}</div>
              <div>{{ Number(item.issue_time) * 1000 | formatDate3 }}</div>
            </div>
          </div>
        </div>
      </div>
      <p class="floor" v-if="loading">加载中...</p>
      <p class="floor" v-if="noMore">没有更多了</p>
    </div>
    <div class="works_popout" :class="workpopout ? 'clicked' : ''">
      <div class="con-box">
        <div class="close-btn el-icon-close" @click="closeMyWorks"></div>
        <div class="top-box">
          <div class="lottie-box">
            <Lottie :options="defaultOptions" />
            <div class="text-box">
              <p>我完成了</p>
              <p>{{ workInfo.work_name }}作品</p>
              <p>真的太棒了！！！</p>
            </div>
          </div>
          <div class="er-code">
            <img :src="workInfo.qr_code" alt="">
          </div>
        </div>
        <div class="work-box">
          <div class="work-con">
            <div class="img-box">
              <img
                :src="workInfo.work_image_url ? workInfo.work_image_url : `${this.$cdnUrl}${this.$fileName}/img/bf-works.png`"
                alt="">
            </div>
            <div class="info-box">
              <p class="bureau-box"><i class="el-icon-school"></i>{{ workInfo.bureau_name }}</p>
              <hr>
              <p class="course-box"><span>{{ workInfo.edu_course_name }}</span></p>
              <div class="head-box">
                <div class="head-img">
                  <img :src="workInfo.avatar_url" alt="">
                </div>
                <div class="text-box">
                  <p class="name">{{ workInfo.nickname }}同学</p>
                  <p class="tit">累计完成</p>
                </div>
                <div class="num-box">
                  <p><span>{{ workInfo.work_num }}</span>件</p>
                </div>
              </div>
            </div>
          </div>
          <div class="bureau-info-box">
            <p><i class="el-icon-phone-outline"></i>{{ workInfo.bureau_phone }}</p>
            <p><i class="el-icon-location-outline"></i>{{ workInfo.bureau_location }}</p>
          </div>
        </div>
      </div>
    </div>
    <IpadAlert v-if="alertState" :visible.sync='alertState' tip="请先激活课程" @handleAlertConfirm="alertState = false">
    </IpadAlert>
    <div class="not-box" v-if="notInfoState">
      <div class="big-box">
        <div class="center-box">
          <div>
            <img src="https://storage.shubenji.cn/GuoBao/ipad/ip6.png" alt="">
          </div>
          <div>
            <img src="https://storage.shubenji.cn/GuoBao/ipad/not-info.png" alt="">
            <div>还没有作品哦～</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/assets/js/date";
import back from "@/views/ipad/Home/components/back";
import { createdLoad } from "@/views/ipad/common";
import SelectMenu from "@/components/ipad/SelectMenu";
import Works from "@/models/Home/Works";

import * as animationData from '../../../assets/json/share-back-img.json';
import Lottie from 'vue-lottie/src/lottie.vue';
import IpadAlert from "@/components/ipad/IpadAlert";
import { navToEditor } from "../../../router/navs";
import UnloadClass from "../../../models/Home/UnloadClass";

export default {
  name: "index",
  components: {
    back,
    SelectMenu,
    Lottie,
    IpadAlert
  },
  computed: {
    noMore() {
      return this.info.length === this.count
    },
    disabled() {
      return this.loading || this.noMore
    },
  },
  data() {
    return {
      imgUrl: 'https://storage.shubenji.cn/GuoBao/ipad/',
      name: 'JOJO',
      menuState: false,
      defaultOptions: {
        animationData: animationData.default
      },
      workpopout: false,
      info: [],
      worksImg1: './img/works-1.png',
      worksImg2: './img/works-2.png',
      worksImg3: './img/works-3.png',

      loading: false,
      count: 0,
      page: 1,
      limit: 3,
      total: 0,
      workInfo: {},
      alertState: false,
      notInfoState: false
    }
  },
  created() {
    createdLoad();
    // this.getWorksShow();
    this.getWorksShow(this.page, () => {
      this.page = this.page + 1;
      this.loading = false;
    })
  },
  methods: {
    handleMenu() {
      this.menuState = true;
    },
    showMyWorks(item) {
      let load = this.$loading({ background: 'rgba(0,0,0,0.2)' });
      Works.getWorksQrCode({ work_id: item.work_id }, ({ type, list }) => {
        if (type === 200) {
          load.close();
          this.workInfo = list;
          let avatar_url = ''
          if (item.avatar_url === '') {
            avatar_url = 'https://storage.shubenji.cn/GuoBao/ipad/ip3.png';
          } else {
            avatar_url = item.avatar_url;
          }
          this.workInfo.avatar_url = avatar_url;
          this.workpopout = true;
        }
      })
    },
    closeMyWorks() {
      this.workpopout = false;
    },
    getWorksShow(page, fun = () => {
    }) {
      let param =
      {
        class_id: this.class_id,
        group_id: this.$store.getters.getGroupData.group_id,
        page: page,
        limit: this.limit,
      }
      Works.getWorksShow(param, ({ type, list }) => {
        if (type === 200) {
          // this.count = list.count;
          // this.list = list.list;
          list.list.forEach((item, index) => {
            if (item.avatar_url === '') {
              item.avatar_url = 'https://storage.shubenji.cn/GuoBao/ipad/ip3.png';
            }
            if (item.type === 'sb3') {
              item.type_name = 'scratch';
            } else if (item.type === 'python') {
              item.type_name = 'python';
            } else if (item.type === 'sjr') {
              item.type_name = 'scratchJr';
            }

            if (item.work_image_url === '' || item.work_image_url === '0') {
              if (item.class_name === '课后作业') {
                item.work_image_url = this.worksImg1;
              }
              if (item.class_name === '课堂练习') {
                item.work_image_url = this.worksImg2;
              }
              if (item.class_name === '自由编程') {
                item.work_image_url = this.worksImg3;
              }
            }
          });
          if (this.page === list.total) {
            this.count = list.count;
          }
          this.info = this.info.concat(list.list);
          if (this.page === 1 && this.info.length === 0) {
            this.notInfoState = true;
          }
          fun();
        }
      })
    },
    loadworks() {
      if (this.loading == true) return
      this.loading = true;
      this.getWorksShow(this.page, () => {
        this.page = this.page + 1;
        this.loading = false;
      })
    },
    hrefClick(item) {
      if (this.$store.getters.getUserData.role === 2) {
        UnloadClass.canUnlock({}, ({ type, list }) => {
          if ((type === 200 && list > 0) || localStorage.getItem('unloadGroupData') * 1 > 0) {
            this.openWork(item)
          } else {
            this.alertState = true;
          }
        })
      } else {
        this.openWork(item);
      }
    },
    openWork(item) {
      if (item.type === 'sb3') {
        window.open("/gbscratch/?workId=" + item.work_id);
      }
      if (item.type === 'python') {
        window.open("/pygame/?workId=" + item.work_id);
      }
      if (item.type === 'sjr') {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          navToEditor({ target: "jr", workId: item.work_id })
        } else {
          window.open(`/jr/?workId=${item.work_id}`);
        }
      }
      if (item.type === 'guodongsjr') {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          navToEditor({ target: "guobaojr", workId: item.work_id })
        } else {
          window.open(`/guobaojr/?workId=${item.work_id}`);
        }
      }

    },
  },
  filters: {
    /*
时间格式自定义 只需把字符串里面的改成自己所需的格式
*/
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy.MM.dd");
    },
    formatDate2(time) {
      var date = new Date(time);
      return formatDate(date, "hh:mm:ss");
    },
    formatDate3(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy.MM.dd. hh:mm");
    },
  },
}
</script>

<style lang="less" scoped>
@import "../commom.css";
@import "./work-index";
</style>
