<template>
    <div class="btn" v-on="attr.click" @click="handleSelectCourse" :class="{'no-select-color':color === false}">
        <div class="btn-name" :class="{'btn-handle':state === true}">{{ attr.name }}</div>
        <audio id="audio">
            <source src="../../assets/pop.mp3" type="audio/mpeg">
        </audio>
    </div>
</template>

<script>
export default {
    name: "Button-1",
    props: {
        attr: {
            type: Object
        },
        audio: {
            type: Boolean,
            default: false
        },
        color: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            state: false
        }
    },
    methods: {
        handleSelectCourse() {
            if (this.audio === true) {
                let sound = document.getElementById("audio");
                sound.play();
            }

            this.state = true;
            this.$emit('update:visible', true)
            setTimeout(() => {
                this.state = false;
            }, 500)
        }
    }
}
</script>

<style lang="less" scoped>
.btn {
    border-radius: 1.8rem;
    background: #FFFFFF;
    box-shadow: 0 0.2rem 0.1rem 0 #FA9860;
    padding: 0.5rem 0.6rem;

    .btn-name {
        padding: 0.3rem 2.2rem;
        border-radius: 1.8rem;
        background-image: linear-gradient(to right, #FEB169, #FB8000);
        font-size: 1.6rem;
        box-shadow: 0 0.3rem 0.9rem 0.1rem rgba(251, 135, 16, 0.45);
        color: #FFFFFF;
        font-weight: bold;
        transition: 0.2s all;
        position: relative;
        text-align: center;

        &::after {
            content: '';
            display: inline-block;
            width: 0.3rem;
            height: 0.3rem;
            border-top: 0.3rem solid #FFFFFF;
            border-left: 0.3rem solid #FFFFFF;
            border-radius: 1rem 0 0 0;
            position: absolute;
            left: 0.6rem;
        }
    }

    .btn-handle {
        transform: scale(0.98);
        font-size: 1.6rem;
        box-shadow: 0.3rem 0.2rem 2.2rem 0.1rem rgba(0, 0, 0, 0.24);
    }
}

.no-select-color{
    background: #FFFFFF !important;
    box-shadow: 0 0.2rem 0.1rem 0 #969594 !important;
    .btn-name{
        background-image: linear-gradient(to right, #ffffff, #ffffff);
        box-shadow: 0 0.3rem 0.9rem 0.1rem rgba(161, 158, 156, 0.45);
        color: #999999;
        //&::after {
        //
        //}
    }
}
</style>
