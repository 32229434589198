<template>
    <div class="list-class">
        <div class="list-box-class" id="main-class" ref="playRef">
            <div class="top-box-class">
                <van-list v-model="loading" :error.sync="error" error-text="请求失败，点击重新加载" :finished="finished"
                    finished-text="没有更多了" :offset="offset" @load="onLoad">
                    <el-row class="row-bg" justify="space-around" :gutter="50">
                        <el-col :span="8" v-for="(item, index) in info" :key="index">
                            <div class="play-box-class">
                                <div class="video-back-1-class" :style="{ marginTop: item.randomMargin + '%' }" @click="
                                    playVideoClick(
                                        index,
                                        item.lesson_id,
                                        item.lesson_name,
                                        item
                                    )
                                    " :class="{ newVideoBack1Class: item.state === true }">
                                    <div class="video-back-2-class" :style="{
                                        backgroundImage: 'url(' + item.lesson_image + ')',
                                        backgroundRepeat: 'no-repeat',
                                        cursor: 'pointer',
                                    }">
                                        <span class="lesson-title-class"
                                            :class="{ newLessonTitleClass: item.state === true }">第{{ index + 1 }}节</span>
                                    </div>
                                    <div class="left-strip-class" :class="{ newStripClass: item.state === true }"></div>
                                    <div class="right-strip-class" :class="{ newStripClass: item.state === true }"></div>
                                    <div class="left-small-strip-class" :class="{ newStripClass: item.state === true }">
                                    </div>
                                    <div class="right-small-strip-class" :class="{ newStripClass: item.state === true }">
                                    </div>

                                    <div class="video-title-1-class" :class="{ newVideoBack1Class: item.state === true }">
                                        <div class="video-title-2-class" :title="item.lesson_name"
                                            v-html="item.lesson_name"></div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </van-list>
            </div>
        </div>
        <div class="hide-box-class" v-show="showState"></div>
    </div>
</template>

<script>
import LessonVideo from "../../models/Home/LessonVideo";
import TeacherUser from "../../models/Home/TeacherUser";
import eventVue from "../../assets/js/event";

export default {
    name: "VideoList",
    data() {
        return {
            error: false,
            playBackNum: "",
            page: 0,
            info: [],
            list: [],
            offset: 100,
            loading: false,
            finished: false,
            scroll_lesson_id: 0,
            num: 0,
            firstState: 0,
            showState: true,
        };
    },
    computed: {
        CourseData() {
            //监听store的数据
            return this.$store.getters.getCourseData;
        },
    },
    created() {
        console.log(65)
        window.collectEvent('config', {
            user_unique_id: localStorage.getItem('info')
        });
        const _this = this;
        eventVue.$on("courseState", function (message) {
            _this.showState = true;
            _this.getNewLastLesson(3);
            _this.getLessonListInfo(3);
        });
        this.getNewLastLesson();
        setTimeout(function () {
            _this.showState = false;
        }, 1000);
    },
    mounted() {
        const _this = this;
        document.addEventListener("mousewheel", _this.scrollFun, true);
    },
    methods: {
        scrollFun() {
            this.offset = 100;
        },
        getNewLastLesson(type = 1) {
            if (type === 3) {
                this.page = 1;
                this.loading = false;
                this.finished = false;
            }
            let group_id = this.$store.getters.getGroupData.group_id;
            LessonVideo.getLatestStep(
                { lesson_id: -1, group_id: group_id },
                ({ type, list }) => {
                    if (type === 200) {
                        this.scroll_lesson_id = list.lesson_id;
                    }
                }
            );
        },
        playVideoClick(i, lesson_id, lesson_name, item) {
            let group_id = this.$store.getters.getGroupData.group_id;
            LessonVideo.updateStepHistory(
                { lesson_id: lesson_id, step_id: 1, group_id: group_id },
                ({ type, list }) => {
                    if (type === 200) {
                    }
                }
            );

            let _key = null;
            let _element = null;
            this.info.forEach((element, key) => {
                if (element.state === true) {
                    _key = key;
                    _element = element;
                }
            });
            if (_key !== null) {
                _element.state = false;
                this.$set(this.info, _key, _element);
            }
            item.state = true;
            console.log(this.$fileName);
            window.open(
                this.$fileName + "/#/lessonDetail3?lesson_id=" +
                lesson_id +
                "&lesson_name=" +
                lesson_name +
                "&group_id=" +
                this.$store.getters.getGroupData.group_id +
                "&course_id=" +
                this.$store.getters.getCourseData[0].id,
                "_blank"
            );
            //   this.playBackNum = i;
            //   let element = document.documentElement;
            //   if (element.requestFullscreen) {
            //     element.requestFullscreen();
            //   } else if (element.webkitRequestFullScreen) {
            //     element.webkitRequestFullScreen();
            //   } else if (element.mozRequestFullScreen) {
            //     element.mozRequestFullScreen();
            //   } else if (element.msRequestFullscreen) {
            //     // IE11
            //     element.msRequestFullscreen();
            //   }
            //   let routeData = this.$router.resolve({
            //     path: "/lessonDetail3",
            //     query: { downTrueUpFalse: "false" },
            //   });
            //   window.open(routeData.href, "_blank");
        },

        onLoad() {
            //上拉加载
            this.page++;
            this.getLessonListInfo();
        },
        getLessonListInfo(type = 1) {
            if (type === 3) {
                if (document.getElementById("main-class") != null) {
                    let top = document.getElementById("main-class").scrollTop;
                    console.log("yyy", top);
                    let self = this;
                    // 实现滚动效果
                    const timeTop = setInterval(() => {
                        document.body.scrollTop =
                            document.getElementById("main-class").scrollTop =
                            top -=
                            50;
                        if (top <= 0) {
                            clearInterval(timeTop);
                        }
                    }, 0);
                    this.page = 1;
                    this.offset = 100;
                    this.num = 0;
                    this.firstState = 0;
                    this.loading = false;
                    this.finished = false;
                }
            }

            let params = {
                course_id: this.$store.getters.getCourseData[0].id,
                group_id: this.$store.getters.getGroupData.group_id,
                page: this.page,
                limit: 30,
            };
            this.loading = true;
            LessonVideo.getLessonList(params, ({ type, list }) => {
                console.log(11166)
                if (type === 200) {
                    list.list.forEach((item) => {
                        if (item.lesson_name.indexOf("<br>") > -1) {
                            item.lesson_name = item.lesson_name.replace("<br>", "-");
                        }
                        item.randomMargin = Math.ceil(Math.random() * 18);
                    });
                    if (this.page === 1) {
                        this.list = [];
                        list.list.forEach((element) => {
                            if (element.extra_code.length == 1) {
                                element.code_url = element.extra_code[0].code_url;
                            }
                            this.list.push(element);
                        });
                    } else {
                        list.list.forEach((element) => {
                            if (element.extra_code.length == 1) {
                                element.code_url = element.extra_code[0].code_url;
                            }
                            this.list.push(element);
                        });
                    }

                    const _this = this;
                    this.info = this.list;
                    let stateNum = 0;
                    // let firstState = 0;
                    this.info.forEach((item, index) => {
                        if (item.lesson_id === _this.scroll_lesson_id) {
                            stateNum = index + 1;
                            item.state = true; //选中状态
                            _this.num++;
                            _this.firstState = 1;
                        } else {
                            item.state = false;
                        }
                    });

                    let scrollPage = stateNum / 6;

                    // console.log(parseInt(scrollPage) * this.$refs.playRef.scrollHeight)
                    if (this.num === 1) {
                        this.$nextTick(() => {
                            this.$refs.playRef.scrollTop =
                                parseInt(scrollPage) * this.$refs.playRef.scrollHeight;
                            this.offset = -100;
                        });
                    }

                    if (list.list.length < 6) {
                        this.finished = true;
                    }
                    this.loading = false;

                    // if (
                    //   this.firstState === 0 &&
                    //   this.scroll_lesson_id !== -1 &&
                    //   this.scroll_lesson_id !== 0
                    // ) {
                    //   this.page++;
                    //   this.getLessonListInfo();
                    // }
                    if (list.list.length !== 0 && this.firstState === 0 && this.scroll_lesson_id !== -1 && this.scroll_lesson_id !== 0) {
                        this.page++;
                        this.getLessonListInfo();
                    }

                    setTimeout(function () {
                        _this.showState = false;
                    }, 1000);
                }
            });
        },
    },
};
</script>

<style scoped lang="less">
.list-class {
    width: 100%;

    //height: calc(99.5vh - 120px);
    .list-box-class::-webkit-scrollbar {
        width: 0 !important;
    }

    .list-box-class {
        -ms-overflow-style: none;
    }

    .list-box-class {
        overflow: -moz-scrollbars-none;
    }

    .list-box-class {
        width: calc(100% - 40px);
        margin: 12px auto;
        height: calc(99.7vh - 175px);
        background: #ffffff;
        border-radius: 10px;
        //box-shadow: 0 15px 30px 0 #d7d7d7;
        background: url("../../assets/img/video-back.png");
        background-size: cover;
        overflow-y: auto;
    }

    .hide-box-class {
        width: calc(100% - 40px);
        margin: 12px auto;
        height: calc(99.7vh - 175px);
        background: #ffffff;
        border-radius: 10px;
        background: url("../../assets/img/video-back.png");
        background-size: cover;
        overflow-y: auto;
        position: absolute;
    }

    .top-box-class {
        width: 90%;
        margin: 0 auto;

        //height: calc(99.7vh - 150px);
        //background: #bcbec2;
    }

    .play-box-class {
        //background: #42b983;
        height: calc((99.7vh - 150px - 50px) / 2);
        margin-top: 20px;
        position: relative;
    }

    .video-back-1-class {
        width: 70%;
        margin: 0 auto;
        height: 200px;
        border-radius: 20px;
        background: hsla(0, 0%, 100%, 0.5);
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        /*向左向上分别平移自身的一半*/
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
    }

    .video-back-2-class {
        width: 96%;
        //margin: 0;
        height: 190px;
        background: #ececec;
        margin: 0 15px 20px 6px;
        text-align: center;
        border-radius: 20px;
        background-size: 100% 100%;
    }

    .lesson-title-class {
        font-weight: bold;
        font-size: 18px;
        padding: 10px 20px;
        background: #f3f3f3;
        position: relative;
        top: -10px;
        color: hsla(0, 0%, 38%, 1);
        border-radius: 25px;
    }

    .left-strip-class {
        width: 10px;
        height: 100px;
        position: absolute;
        top: 24%;
        left: -3px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 3px;
    }

    .right-strip-class {
        width: 10px;
        height: 100px;
        position: absolute;
        top: 24%;
        right: -3px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 3px;
    }

    .left-small-strip-class {
        width: 8px;
        height: 12px;
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        bottom: -6%;
        left: 20%;
    }

    .right-small-strip-class {
        width: 8px;
        height: 12px;
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        bottom: -6%;
        right: 20%;
    }

    .video-title-1-class {
        width: 100%;
        height: 70px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 16px;
    }

    .video-title-2-class {
        width: 96%;
        height: 60px;
        margin: 0 auto;
        background: #ffffff;
        border-radius: 16px;
        position: relative;
        top: 5px;
        text-align: center;
        line-height: 60px;
        font-size: 20px;
        font-weight: bold;
        color: #494949;
        overflow: hidden;
        /*超出的部分隐藏起来。*/
        white-space: nowrap;
        /*不显示的地方用省略号...代替*/
        text-overflow: ellipsis;
        /* 支持 IE */
    }

    .newLessonTitleClass {
        background: linear-gradient(127deg, #ffbb1b 0%, #ff8c0d 100%);
        color: #ffffff;
    }

    .newVideoBack1Class {
        background: linear-gradient(127deg, #ffbb1b 0%, #ff8c0d 100%);
    }

    .newStripClass {
        background: #fbe9c8;
    }
}

.theBtn {
    position: absolute;
    border: 1px solid;
    background-color: pink;
    padding: 10px 16px;
    border-radius: 12px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    top: 104px;
    z-index: 20;
}
</style>
