<template>
    <div class="video-alert-class" ref="treeWrap">
        <div class="main-class">
            <div class="video-class">
                <video controls="" class="theVideo"
                       :src="resultVid[0].url"
                       poster=" " alt="" controlslist="nodownload" contextmenu="false"></video>
            </div>
            <div class="return-class">
                <i class="el-icon-arrow-left" @click="closeAlertClick"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CommentVideoAlert",
    props: ['resultVid'],
    mounted() {
        document.addEventListener('mouseup', (e) => {
            let tree = this.$refs.treeWrap
            if (tree) {
                if (!tree.contains(e.target)) {
                    this.$emit('lookCommentVideoClick')
                    // this.isShowTree =false
                }
            }
        })
    },
    methods: {
        closeAlertClick() {
            this.$emit('lookCommentVideoClick')
        }
    }

}
</script>

<style lang="less" scoped>
.video-alert-class {
    width: 1240px;
    height: calc(100vh - 340px);
    //margin-top: 20px;

    //.top-box {
    //    width: 100%;
    //    height: 40px;
    //    background: #121212;
    //    opacity: 0.65;
    //    border-radius: 10px 10px 0px 0px;
    //    display: flex;
    //    line-height: 40px;
    //    color: #FFFFFF;
    //
    //    .icon-class {
    //        margin-left: auto;
    //        font-size: 28px;
    //        cursor: pointer;
    //        padding: 0 10px;
    //    }
    //
    //}
    .main-class {
        width: 1240px;
        height: calc(100vh - 340px);
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
        opacity: 1;
        border-radius: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .video-class {
            background: black;
            border-radius: 10px;

            video {
                width: 1240px;
                height: calc(100vh - 340px);
            }
        }

        .return-class {
            position: absolute;
            top: 0;
            left: 0;

            i {
                font-weight: bold;
                font-size: 24px;
                padding: 10px;
                cursor: pointer;
                color: #FFFFFF;
            }
        }
    }

}
</style>
