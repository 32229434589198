<template>
    <div class="my-center">
        <div class="z-index-box"></div>
        <div class="orange-box">
            <div class="info">
                <div class="div0">
                    <div></div>
                    <div>个人中心</div>
                    <div @click="handleClose">
                        <svg-icon icon-class="close-icon" class="close-icon-class"></svg-icon>
                    </div>
                </div>
                <div class="div1">
                    <!--                    <img :src="avatarImg" alt="">-->
                    <!--                    <svg-icon icon-class="photograph-icon" class="photograph-icon-class"></svg-icon>-->
                    <div class="cover-class">
                        <el-upload
                            class="avatar-uploader"
                            accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                            action="/resource/v1/uploadFile"
                            :headers="headerObj"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                            <div class="head-default-class"
                                 :style="{backgroundImage:'url(' + avatarImg + ')',backgroundRepeat:'no-repeat',cursor: 'pointer'}">
                                <svg-icon icon-class="photograph-icon" class="photograph-icon-class" />
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div class="div2">更换头像</div>
                <div class="div3">
                    <el-input v-model="nickname" placeholder="输入你的昵称..."></el-input>
                </div>
                <div class="div4">
                    <Button1 :attr='attr' :audio="true"></Button1>
                    <Button1 :attr='attr1' :audio="true" :color="false"></Button1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button1 from "@/components/ipad/Button-1";
import Qs from "qs";
import Login from "@/models/Login/Login";
import eventVue from "@/assets/js/event";
import TeacherUser from "@/models/Home/TeacherUser";
export default {
    name: "MyCenter",
    components:{
        Button1
    },
    data() {
        return {
            headerObj: {
                token: JSON.parse(localStorage.getItem('info')).token
            },
            avatarImg: './img/default-head-img2.png',
            avatarId: 0,
            state: false,
            nickname: '',
            attr: {
                name: '确定修改',
                click: { click: _ => this.handleUpdateInfo() }
            },attr1: {
                name: '取消修改',
                click: { click: _ => this.handleClose() }
            },
        }
    },
    created() {
        if (this.$store.getters.getUserData !== null) {
            this.avatarImg = this.$store.getters.getUserData.avatar;
            this.nickname = this.$store.getters.getUserData.nickname;
        }
        this.getUserInfo();
    },
    methods: {
        handleClose() {
            // this.state = false;
            this.$emit('update:visible', false)
        },
        handleAvatarSuccess(res, file) {
            console.log(res,file)
            this.avatarId = res.data.id;
            this.avatarImg = res.data.relativeUrl

        },
        beforeAvatarUpload(file) {
            console.log(file);
        },
        getUserInfo(){
            TeacherUser.getBasisInfo({}, ({ type, list }) => {
                if (type === 200) {
                    // this.$refs.basicRef.info = list;
                    this.avatarId = list.userinfo.user_avatar_id;
                }
            })
        },
        handleUpdateInfo(){
            const request = Qs.stringify({
                slogan: ' ',
                nickname: this.nickname,
                avatar_id: this.avatarId,
            });
            Login.saveUserInfo(request, ({type, list}) => {
                if (type === 200) {
                    //修改父级id
                    // this.$emit('onBasic')
                    eventVue.$emit('onBasicInfo', {
                        nickname: this.nickname,
                        avatarImg: this.avatarImg
                    })
                    this.$emit('update:visible', false)
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.my-center {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .z-index-box {
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3000);
        position: fixed;
        top: 0;
        left: 0;
    }

    .orange-box {
        width: 46rem;
        background: #ff921d;
        border-radius: 2rem;
        position: relative;
        z-index: 12;

        .info {
            //width: 46rem;
            margin: 1.2rem;
            //margin: 0 auto;
            background: #FFFFFF;
            border-radius: 2rem;
            padding: 2rem;

            .div0 {
                display: grid;
                grid-template-columns: 4rem auto 4rem;
                align-items: center;

                font-size: 2rem;
                font-weight: bold;
                color: #FDA349;

                div:nth-child(2) {
                    text-align: center;
                }

                div:nth-child(3) {
                    width: 4rem;
                    height: 4rem;
                    border: 0.1rem solid #FDA249;
                    border-radius: 100%;
                    text-align: center;

                    .close-icon-class {
                        margin-top: 0.5rem;
                        width: 3rem;
                        height: 3rem;
                        border-radius: 3rem;
                        background: #FC9D3E;
                        color: #FFFFFF !important;
                        box-shadow: 0 0.3rem 0.5rem 0.5rem rgba(251, 135, 16, 0.451);
                    }
                }
            }

            .div1 {
                width: 100%;
                text-align: center;
                margin: 2rem 0 0rem 0;

                .cover-class {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;

                    .head-default-class {
                        width: 10rem;
                        height: 10rem;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-size: 100%;

                        .photograph-icon-class {
                            width: 50%;
                            height: 50%;
                        }
                    }
                }

                img {
                    width: 12rem;
                    height: 12rem;
                    border-radius: 100%;
                }
            }

            .div2 {
                font-size: 1.6rem;
                font-weight: bold;
                color: #333333;
                text-align: center;
                margin: 1rem 0;
            }

            .div3 {
                /deep/ .el-input__inner {
                    width: calc(100%);
                    border: none;
                    font-size: 1.6rem;
                    height: 5rem;
                    background: #EEEEEE;
                    border-radius: 5rem;
                    padding: 0 2rem;
                }
            }

            .div4 {
                display: flex;
                justify-content: space-around;
                margin: 1rem 0 2rem 0;

                #course-btn {
                    flex: 1 0 40%;
                    padding: 0.5rem;
                    display: flex;
                    justify-content: center;

                    .w-btn {
                        width: 100%;
                        background: #FFFFFF;
                        padding: 1rem;
                        border-radius: 3rem;
                        position: relative;
                        z-index: 1;
                        box-shadow: 0 0.42rem 0.03rem 0.001rem #FA9860;

                        div {
                            padding: 0.8rem 3rem;
                            flex: 0 0 100%;
                            text-align: center;
                            color: #FFFFFF;
                            font-size: 2rem;
                            border-radius: 3rem;
                            font-weight: bold;
                            background: linear-gradient(135deg, #FEB169 0%, #FB8000 100%);
                            box-shadow: 0 0.3rem 0.9rem 0.12rem rgba(251, 135, 16, 0.451);
                        }

                        i {
                            font-weight: bold;
                            font-size: 2rem;
                            position: absolute;
                            left: 2.5rem;
                            top: 1rem;
                            color: #FFFFFF;
                        }
                    }
                }

                #course2-btn {
                    margin-left: 3rem;
                    flex: 1 0 40%;
                    padding: 0.5rem;
                    display: flex;
                    justify-content: center;

                    .w-btn {
                        width: 100%;
                        background: #F0F0F0;
                        padding: 1rem;
                        border-radius: 3rem;
                        position: relative;
                        z-index: 1;
                        box-shadow: 0 0.42rem 0.03rem 0.001rem #CCCCCC;

                        div {
                            padding: 0.8rem 3rem;
                            flex: 0 0 100%;
                            text-align: center;
                            color: #999999;
                            font-size: 2rem;
                            border-radius: 3rem;
                            font-weight: bold;
                            background: #FFFFFF;
                            //background: linear-gradient(135deg, #FEB169 0%, #FB8000 100%);
                            //box-shadow: 0 0.3rem 0.9rem 0.12rem rgba(251, 135, 16, 0.451);
                        }

                        i {
                            font-weight: bold;
                            font-size: 2rem;
                            position: absolute;
                            left: 2.5rem;
                            top: 1rem;
                            color: #CCCCCC;
                        }
                    }
                }
            }
        }
    }
}
</style>
