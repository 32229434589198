<template>
    <div class="video-alert-class">
        <!--        <p>-->
        <!--            <span class="theBtn" @click="cutType">控制按钮：{{isShadeText}}</span>-->
        <!--        </p>-->
        <div class="close-icon-class">
            <div @click="$parent.fullVideoState = false"><i class="el-icon-close" id="video-close-id"></i></div>
        </div>
        <div style="width: 100%">
            <div class="play-box-class">
                <video-player class="video-player vjs-custom-skin"
                              ref="videoPlayer"
                              :playsinline="true"
                              @play="onPlayerPlay($event)"
                              @pause="onPlayerPause($event)"
                              @ready="playerReadied"
                              :options="playerOptions">
                </video-player>
            </div>
        </div>

    </div>
</template>

<script>
import {videoPlayer} from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";

export default {
    props: ['fullVideoData'],
    name: "videoAlert",
    components: {
        videoPlayer
    },
    data() {
        return {
            isShade: '',
            isShadeText: '没遮住',
            playTime: '',
            current: '',
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: "video/mp4", // 类型
                        src:
                        this.fullVideoData.video_url// url地址
                    }
                ],
                poster: this.fullVideoData.video_img, // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                },
                videoButton: '../../assets/lock-icon.png',
                fullscreenToggle: true
            },
        };


    },
    mounted() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            let videoIconId = document.getElementById('video-close-id');
            videoIconId.style.fontSize = '3rem'
        }

        console.log(this.fullVideoData);
        // this.playerOptions.sources.src = this.fullVideoData.video_url;
        // this.playerOptions.poster = this.fullVideoData.video_img;
        $("video").bind('contextmenu', function () {
            return false;
        })
    },
    methods: {
        // 播放回调
        onPlayerPlay(player) {
            // console.log("player play!", player);
        },
        // 暂停回调
        onPlayerPause(player) {
            // console.log("player pause!", player);
        },
        // 视频播完回调
        onPlayerEnded($event) {
            // console.log(player);
        },
        // DOM元素上的readyState更改导致播放停止
        onPlayerWaiting(player) {
            this.playerReadied(player)
        },
        // 已开始播放回调
        onPlayerPlaying($event) {
            // console.log($event)
        },
        // 当播放器在当前播放位置下载数据时触发
        onPlayerLoadeddata($event) {
            // console.log($event)
        },
        // // 当前播放位置发生变化时触发。
        onPlayerTimeupdate(player) {

        },
        //媒体的readyState为HAVE_FUTURE_DATA或更高
        onPlayerCanplay(player) {
            // // console.log('player Canplay!', player)
        },
        //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
        onPlayerCanplaythrough(player) {
            // // console.log('player Canplaythrough!', player)
        },
        //播放状态改变回调
        playerStateChanged(playerCurrentState) {
            // // console.log('player current update state', playerCurrentState)
        },
        //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
        playerReadied(player) {
            // console.log('example player 1 readied', player);
            player.currentTime(this.current)
        },
        cutType() {

        }
    }
}
</script>

<style lang="less" scoped>
.video-alert-class {
    width: 60%;
    margin: 0 auto;
    //height: 80vh;
    background: #F2F2F2;
    position: absolute;
    top: 12vh;
    left: 15%;
    border-radius: 10px;
    z-index: 10000;
    padding: 10px;
    box-shadow: -3px 3px 6px rgba(18, 18, 18, 0.16);

    .close-icon-class {
        font-size: 30px;
        cursor: pointer;
        display: flex;
        div{
            width: 30px;
            height: 30px;
            margin-left: auto;
        }

    }
    .close-icon-class:hover{
        div{
            color: #FF8B0C;
        }
    }
}
</style>
