var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-class"},[_c('div',{ref:"playRef",staticClass:"list-box-class",attrs:{"id":"main-class"}},[_c('div',{staticClass:"top-box-class"},[_c('van-list',{attrs:{"error":_vm.error,"error-text":"请求失败，点击重新加载","finished":_vm.finished,"finished-text":"没有更多了","offset":_vm.offset},on:{"update:error":function($event){_vm.error=$event},"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('el-row',{staticClass:"row-bg",attrs:{"justify":"space-around","gutter":50}},_vm._l((_vm.info),function(item,index){return _c('el-col',{key:index,attrs:{"span":8}},[_c('div',{staticClass:"play-box-class"},[_c('div',{staticClass:"video-back-1-class",class:{ newVideoBack1Class: item.state === true },style:({ marginTop: item.randomMargin + '%' }),on:{"click":function($event){return _vm.playVideoClick(
                                    index,
                                    item.lesson_id,
                                    item.lesson_name,
                                    item
                                )}}},[_c('div',{staticClass:"video-back-2-class",style:({
                                    backgroundImage: 'url(' + item.lesson_image + ')',
                                    backgroundRepeat: 'no-repeat',
                                    cursor: 'pointer',
                                })},[_c('span',{staticClass:"lesson-title-class",class:{ newLessonTitleClass: item.state === true }},[_vm._v("第"+_vm._s(index + 1)+"节")])]),_c('div',{staticClass:"left-strip-class",class:{ newStripClass: item.state === true }}),_c('div',{staticClass:"right-strip-class",class:{ newStripClass: item.state === true }}),_c('div',{staticClass:"left-small-strip-class",class:{ newStripClass: item.state === true }}),_c('div',{staticClass:"right-small-strip-class",class:{ newStripClass: item.state === true }}),_c('div',{staticClass:"video-title-1-class",class:{ newVideoBack1Class: item.state === true }},[_c('div',{staticClass:"video-title-2-class",attrs:{"title":item.lesson_name},domProps:{"innerHTML":_vm._s(item.lesson_name)}})])])])])}),1)],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showState),expression:"showState"}],staticClass:"hide-box-class"})])
}
var staticRenderFns = []

export { render, staticRenderFns }