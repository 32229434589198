<template>
    <div class="help-list">
        <div class="z-index-box"></div>
        <div class="orange-box">
            <div class="info">
                <div class="div0">
                    <div></div>
                    <div>激活课时</div>
                    <div @click="handleClose">
                        <svg-icon icon-class="close-icon" class="close-icon-class"></svg-icon>
                    </div>
                </div>
                <div class="div1"  v-infinite-scroll="$parent.loadworks" infinite-scroll-disabled="disabled">
                    <ul>
                        <li v-for="(item,index) in list" :class="{'select-active':index === selectIndex}"
                            @click="handleSelect(index,item)">
                            <div>第{{ index+1 }}节</div>
                            <div>{{ item.lesson_name }}</div>
                        </li>
                    </ul>
                    <p class="floor" v-if="$parent.loading" style="text-align: center">加载中...</p>
                    <p class="floor" v-if="noMore" style="text-align: center">没有更多了</p>
                </div>
                <div class="div2">
                    <Button1 :attr="attr" :audio="true"></Button1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button1 from "@/components/ipad/Button-1";
import TeacherUser from "@/models/Home/TeacherUser";
import eventVue from "@/assets/js/event";

export default {
    name: "HelpList",
    props: {
        list: {
            type: Array
        }
    },
    components: {
        Button1
    },
    computed: {
        noMore() {
            return this.list.length === this.$parent.count
        },
        disabled() {
            return this.$parent.loading || this.noMore
        },
    },
    data() {
        return {
            selectIndex: null,
            selectItem: null,
            attr: {
                name: '激活',
                click: {
                    click: _ => {
                        this.handleConfirm()
                    }
                }
            },
        }
    },
    methods: {
        handleClose() {
            // this.state = false;
            this.$emit('update:visible', false)
        },
        handleSelect(index, item) {
            this.selectIndex = index;
            this.selectItem = item;
        },
        handleConfirm() {
            if(this.selectItem !== null){
                this.$emit('handleConfirmGroup', this.selectItem)
            }else{
                this.$emit('handleConfirmGroup', null)
            }
            this.$emit('update:visible', false);
        },
        //首页课程标题
        homeCourseList(cut = 1) {
            const params = {
                group_id: this.$store.getters.getGroupData.group_id,
            }
            TeacherUser.getCourseList(params, ({ type, list }) => {
                if (type === 200) {
                    this.$store.commit('setCourseData', list.list);
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.help-list {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:1;

    .z-index-box {
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3000);
        position: fixed;
        top: 0;
        left: 0;
    }

    .orange-box {
        width: 50rem;
        background: #ff921d;
        border-radius: 2rem;
        position: relative;
        z-index: 12;

        .info {
            //width: 46rem;
            margin: 1rem;
            //margin: 0 auto;
            background: #FFFFFF;
            border-radius: 2rem;
            padding: 2rem;

            .div0 {
                display: grid;
                grid-template-columns: 4rem auto 4rem;
                align-items: center;

                font-size: 2rem;
                font-weight: bold;
                color: #FDA349;

                div:nth-child(2) {
                    text-align: center;
                }

                div:nth-child(3) {
                    width: 4rem;
                    height: 4rem;
                    border: 0.1rem solid #FDA249;
                    border-radius: 100%;
                    text-align: center;

                    .close-icon-class {
                        margin-top: 0.5rem;
                        width: 3rem;
                        height: 3rem;
                        border-radius: 3rem;
                        background: #FC9D3E;
                        color: #FFFFFF !important;
                        box-shadow: 0 0.3rem 0.5rem 0.5rem rgba(251, 135, 16, 0.451);
                    }
                }
            }

            .div1 {
                background: #FFEEE1;
                border-radius: 2rem;
                height: 30rem;
                overflow: scroll;

                .select-active {
                    background: #FDA349 !important;
                    color: #FDA349 !important;

                    div:nth-child(1) {
                        color: #FFFFFF;
                    }

                    div:nth-child(2) {
                        background: #FFEEE1;
                    }
                }

                ul {
                    height: 30rem;
                    overflow: scroll;

                    li {
                        margin-top: 0.8rem;
                        font-size: 1.6rem;
                        display: flex;
                        justify-content: space-between;
                        //background: #EEEEEE;
                        padding: 0.5rem 2rem;
                        border-radius: 1.2rem;
                        align-items: center;
                        color: #FDA349;
                        font-weight: bold;

                        div:nth-child(2) {
                            margin-left: auto;
                            background: #FFFFFF;
                            padding: 0.3rem 2.4rem;
                            border-radius: 1rem 2rem 1rem 2rem;
                        }
                    }
                }
            }

            .div2 {
                width: 30%;
                margin: 1rem auto;
            }
        }
    }
}
</style>
