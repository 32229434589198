<template>
  <div class="student-mange">
    <div class="check-all">
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选</el-checkbox
      >
      <el-button
        type="text"
        class="unlock-photograph"
        @click="handleUnlockPhotograph"
        >批量解锁素材包</el-button
      >
      <div
        style="font-size: 14px"
        v-if="cartoonAmount !== null && cartoonAmount.enabled"
      >
        支持解锁素材包，剩余次数：
        <span style="font-size: 16px; font-weight: bold; color: #ff8d0d">{{
          cartoonAmount.amount
        }}</span>
        次
      </div>
      <div style="font-size: 14px" v-else>不支持解锁素材包</div>
    </div>

    <div>
      <el-checkbox-group
        v-model="checkeds"
        @change="handleCheckedChange"
        class="stu-list"
      >
        <div
          class="stu-items"
          v-for="(item, index) in studentData.list"
          :key="index"
        >
          <div class="it-top">
            <el-checkbox :label="item.id" v-if="item.ticket_status === 0">{{
              ``
            }}</el-checkbox>
            <div
              class="upload-cancel"
              @mouseenter="item.photoState = true"
              @mouseleave="item.photoState = false"
            >
              <svg-icon
                v-if="item.ticket_status !== 0"
                icon-class="photo-icon"
                class="svg-icon-class"
              />
              <div class="edit-box" v-if="item.photoState">
                <div class="label" @click="handleCheckPhotp(item)">
                  查看素材
                </div>
                <div class="label" @click="handleCancelTicket(item)">
                  撤销权限
                </div>
              </div>
            </div>
          </div>

          <div class="end-lesson-name" v-if="item.latest_lesson_name !== ''">
            {{ item.latest_lesson_name }}
          </div>
          <div class="end-lesson-name" v-else>&nbsp;</div>
          <el-progress
            class="percent rn1"
            :width="100"
            color="#FF8D0D"
            :stroke-width="7"
            type="circle"
            :percentage="
              Math.round((item.lessons_num / item.total_lesson_num) * 100)
            "
          ></el-progress>
          <div class="line"></div>
          <div class="user-name-phone">
            <div class="name">
              <div class="label">姓名</div>
              <div class="text">{{ item.nickname }}</div>
            </div>
            <div class="phone">
              <div class="label">账号</div>
              <div class="text">{{ item.name }}</div>
            </div>
          </div>
          <div class="unlock-lesson-log">
            <div class="btn" @click="studentListDiaClick(item.id)">
              解锁游戏
            </div>
            <div class="btn" @click="handleUnlockLessonLog(item)">解锁日志</div>
          </div>
        </div>
      </el-checkbox-group>
    </div>
    <div class="block" style="text-align: center; margin-top: 30px">
      <el-pagination
        background
        layout="prev, pager, next, total, jumper"
        :page-size="8"
        v-if="studentData.list !== undefined && studentData.list.length !== 0"
        :total="studentData.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      class="student-list"
      title="游戏列表"
      :center="true"
      :visible.sync="studentListDialog"
      width="57%"
      z-index="500"
    >
      <div>
        <el-row :gutter="45">
          <el-col
            v-for="item in gameData"
            :lg="{ span: '4-8' }"
            :span="6"
            :key="item.rec_id"
            class="list-le-col"
          >
            <div @click="checkClick(item.rec_id)">
              <el-card class="box-card">
                <div>
                  <el-avatar
                    size="medium"
                    class="avatar-box"
                    :src="item.game_img"
                  ></el-avatar>
                </div>
                <p class="name">{{ item.game_name }}</p>
                <span
                  v-if="studentLockGames.indexOf(item.rec_id) >= 0"
                  class="pitch-box"
                  :class="
                    studentLockGames.indexOf(item.rec_id) >= 0
                      ? 'checked'
                      : 'unchecked'
                  "
                >
                  <i class="el-icon-success success"></i>
                </span>
                <span v-else class="pitch-box1"> </span>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <el-dialog
      class="student-list"
      :title="stuTitleUnlockTitle"
      :center="true"
      :visible.sync="stuLessonUnlockState"
      width="57%"
      z-index="500"
    >
      <div>
        <div class="column-2">
          <div class="les-name">&nbsp;&nbsp;&nbsp;&nbsp;课时名</div>
          <div class="let-time">解锁时间</div>
          <div class="let-time">上课开始时间</div>
          <div class="let-time">上课结束时间&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </div>
        <div class="info-list-2">
          <div class="item-info-2" v-for="item in lessonUnlockData" :key="item">
            <div class="les-name">{{ item.lesson_name }}</div>
            <div class="let-time">{{ item.create_time }}</div>
            <div class="let-time">{{ item.start_time }}</div>
            <div class="let-time">{{ item.end_time }}</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <PhotoShow ref="PhotoShow"></PhotoShow>
  </div>
</template>

<script>
import Api from "@/models/Home/Api";
import Student from "@/models/Home/Student";
import { MsgTip } from "../common/MsgTip";
import PhotoShow from "./PhotoShow.vue";

export default {
  name: "StudentMange",
  components: {
    PhotoShow,
  },
  data() {
    return {
      page: 1,
      studentListDialog: false,
      stuLessonUnlockState: false,
      studentData: [],
      gameData: [],
      studentLockGames: [],
      lessonUnlockData: [],
      stuTitleUnlockTitle: "",

      cartoonAmount: null, //状态
      checkAll: false,
      isIndeterminate: false,
      checkeds: [],
    };
  },
  created() {
    this.getStudentInfo();
    this.getGameInfo();
    this.getCartoonAmount();
  },
  computed: {
    GroupData() {
      //监听store的数据
      return this.$store.getters.getGroupData;
    },
    CourseData() {
      //监听store的数据
      return this.$store.getters.getCourseData;
    },
  },
  watch: {
    GroupData() {
      console.log(this.$store.getters.getCourseData);
      this.getStudentInfo();
    },
    CourseData() {
      this.getCartoonAmount();
    },
  },
  methods: {
    //学生管理列表
    getStudentInfo() {
      let param = {
        group_id: this.$store.getters.getGroupData.group_id,
        page: this.page,
        limit: 8,
      };
      Student.getStudentList(param, ({ type, list }) => {
        if (type === 200) {
          list.list.forEach((item) => {
            if (item.latest_lesson_name.indexOf("<br>") > -1) {
              item.latest_lesson_name = item.latest_lesson_name.replace(
                "<br>",
                "-"
              );
            }
            item.photoState = false;
          });
          this.studentData = list;

          console.log(this.studentData);
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getStudentInfo();
    },
    getGameInfo() {
      Api.fetchData(
        { params: {} },
        "/bureau/v1.2/listGames",
        ({ type, list }) => {
          this.gameData = list;
        }
      );
    },
    /**解锁课程日志*/
    handleUnlockLessonLog(item) {
      this.stuTitleUnlockTitle = item.nickname + "的解锁课程日志";
      this.stuLessonUnlockState = true;
      let param = {
        group_id: this.$store.getters.getGroupData.group_id,
        student_id: item.id,
      };
      Student.stuLessonUnlock(param, ({ type, list }) => {
        if (type === 200) {
          this.lessonUnlockData = list;
        }
      });
    },
    /**点击角色小游戏*/
    studentListDiaClick(studentId) {
      this.studentListDialog = true;
      this.theStudent = studentId;
      this.getLockGames(studentId);
    },
    getLockGames(id) {
      Api.fetchData(
        {
          student_id: id,
        },
        "/bureau/v1.2/getLockGamesByStudent",
        ({ type, list }) => {
          this.studentLockGames = list;
        }
      );
    },
    //选中状态
    checkClick(game_id) {
      var index = this.studentLockGames.indexOf(game_id),
        mes = index >= 0 ? "上锁" : "开锁",
        is_lock = index >= 0 ? 0 : 1;
      this.$Dialog
        .confirm({
          message: '<span style="font-size: 16px">确定' + mes + "吗？</span>",
          confirmButtonColor: "#FF8D15",
        })
        .then(() => {
          Api.fetchData(
            {
              students: this.theStudent,
              games: game_id,
              is_lock: is_lock,
            },
            "/bureau/v1.2/lockGamesByStudent",
            () => {
              if (is_lock === 0) {
                this.studentLockGames.splice(index, 1);
                this.$message({
                  message: "上锁成功",
                  type: "success",
                  iconClass: "iconClass",
                });
              } else {
                this.studentLockGames.push(game_id);
                this.$message({
                  message: "开锁成功",
                  type: "success",
                  iconClass: "iconClass",
                });
              }
            }
          );
        });
    },
    /**全选按钮 */
    handleCheckAllChange(val) {
      let selectData = [];
      this.studentData.list.forEach((it) => {
        if (it.ticket_id === 0) {
          selectData = [...selectData, it.id];
        }
      });
      console.log(selectData);
      this.checkeds = val ? selectData : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.studentData.list.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.studentData.list.length;
    },
    /**查询是否可以解锁 */
    getCartoonAmount() {
      Student.getCartoonAmount(
        { course_id: this.$store.getters.getCourseData[0].id },
        ({ type, list }) => {
          if (type === 200) {
            this.cartoonAmount = list;
          }
        }
      );
    },
    /**批量解锁素材包 */
    handleUnlockPhotograph() {
      if (this.cartoonAmount === null || !this.cartoonAmount.enabled) {
        MsgTip({ type: "error", message: "该班级课程下不可以解锁素材包" });
        return;
      }
      if (this.checkeds.length === 0) {
        MsgTip({ type: "error", message: "请选择一名学生解锁素材包" });
        return;
      }

      const params = {
        course_id: this.$store.getters.getCourseData[0].id,
        student_id: this.checkeds,
      };
      this.$Dialog
        .confirm({
          message: `<span style="font-size: 16px">确定解锁素材包吗？</span>`,
          confirmButtonColor: "#FF8D15",
        })
        .then(() => {
          Student.assignTicket(params, ({ type, list }) => {
            console.log(type, list);
            if (type === 200) {
              MsgTip({ type: "success", message: "解锁成功" });
              this.getStudentInfo();
              this.getCartoonAmount();
            }
          });
        });
    },
    /**查看图片 */
    handleCheckPhotp(item) {
      this.$refs.PhotoShow.ticket_id = item.ticket_id;
      this.$refs.PhotoShow.dialogState = true;
    },
    /**撤销权限 */
    handleCancelTicket(item) {
      this.$Dialog
        .confirm({
          message: `<span style="font-size: 16px">确定撤销拍照吗？</span>`,
          confirmButtonColor: "#FF8D15",
        })
        .then(() => {
          const params = {
            ticket_id: item.ticket_id,
          };
          Student.cancelTicket(params, ({ type, list }) => {
            console.log(type, list);
            if (type === 200) {
              MsgTip({ type: "success", message: "撤销成功" });
              this.getStudentInfo();
              this.getCartoonAmount();
            }
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.student-mange {
  width: calc(100% - 40px);
  margin: 12px auto;
  height: calc(99.7vh - 175px);
  overflow-y: scroll;
  border-radius: 10px;
}
.stu-list {
  padding: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-row-gap: 10px;
  gap: 32px;
}
.stu-items {
  border-radius: 30px;
  background: #ffffff;
  padding: 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .it-top {
    position: absolute;
    left: 16px;
    top: 16px;
    display: flex;
    align-items: center;
    width: 90%;

    .upload-cancel {
      margin-left: auto;
      position: relative;
    }
    .svg-icon-class {
      width: 36px;
      height: 36px;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        color: #ff8d0d;
      }
    }
    .edit-box {
      position: absolute;
      width: 100px;
      height: 80px;
      z-index: 1;
      color: #ffffff;
      box-shadow: 0px 2px 10px #666666;
      background: #ffffff;
      right: -10px;
      border-radius: 10px;
      .label {
        height: 24px;
        padding: 8px 10px;
        color: #666666;
        font-size: 18px;
        &:hover {
          color: #ff8d0d;
          cursor: pointer;
        }
      }
    }
  }
  .end-lesson-name {
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 240px;
  }

  .rn1 {
    margin: 24px 0;
  }

  .line {
    width: calc(100% - 72px);
    height: 1px;
    border-top: 1px solid #f8f8f8;
  }
}

.user-name-phone {
  width: calc(100% - 72px);
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.label {
  color: #666666;
  font-size: 24px;
}

.text {
  color: #333333;
  font-size: 24px;
  font-weight: 500;
}

.name {
  flex: 1;
  text-align: center;
}

.phone {
  text-align: center;
  flex: 1;
}

.unlock-lesson-log {
  width: calc(100% - 72px);
  display: flex;
}
.btn:nth-child(1) {
  margin-right: 20px;
}

.btn {
  flex: 1;
  background: #ff8d0d;
  height: 44px;
  border-radius: 10px;
  text-align: center;
  line-height: 44px;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
}

.column-1 {
  background: #ff8d0d;
  border-radius: 8px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.info-list {
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 12px 0;
}
.item-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  border-bottom: 1px solid #dddddd;
  //background: pink;
}
.unlock-btn {
  margin: 0 auto;
  color: #ffffff;
  cursor: pointer;
  width: 100px;
  padding: 8px 0;
  text-align: center;
  background: #ff8d15;
  border-radius: 4px;
  font-weight: bold;
}

.lesson-img {
  //width: 300px;
}

.center {
  text-align: center;
}

.text-font-size {
  font-size: 14px;
  color: #606266;
}

.subject-name {
  width: 240px;
}

.group-name {
  width: 240px;
}

.lesson-name {
  width: 240px;
}

.student-list {
}
.list-le-col {
  margin-bottom: 30px;
}
.box-card {
  text-align: center;
  position: relative;
  cursor: pointer;
}
.avatar-box {
  width: 100px;
  height: 100px;
  background-color: #fff;
}

.name {
  font-size: 24px;
  color: #898989;
}

.pitch-box {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 16px;
  border: 1px solid #ddd;

  .success {
    font-size: 24px;
  }
}

.pitch-box1 {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 16px;
  border: 1px solid #ddd;
  width: 18px;
  height: 18px;

  .success {
    font-size: 24px;
  }
}

.checked {
  border: none;
  color: #ff8d0d;
}

.unchecked {
  i {
    color: #fff;
  }
}

.disable {
  border: none;

  i {
    color: #fff;
  }
}

.in-use {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.column-2 {
  background: #ff8d0d;
  border-radius: 10px;
  display: flex;
  align-items: center;

  height: 40px;
  color: #ffffff;
  font-weight: bold;
  .les-name {
    width: 320px;
  }
  .let-time {
    width: 200px;
    margin: 0 20px;
  }
}
.info-list-2 {
  height: 500px;
  overflow-y: scroll;
}
.item-info-2 {
  display: flex;
  border-bottom: 1px solid #ddd;
  height: 40px;
  line-height: 40px;
}
.les-name {
  width: 320px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.let-time {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 20px;
}

.check-all {
  margin: 8px 32px 0;
  .unlock-photograph {
    color: #666666;
    margin-left: 32px;
    &:hover {
      color: #ff8d0d;
    }
  }
}

/deep/.el-message .el-icon-error {
  font-size: 12px !important;
}
/deep/ .el-progress__text {
  font-size: 40px !important;
  font-weight: bold;
}

/deep/ .el-progress-circle {
  width: 140px !important;
  height: 140px !important;
}

/deep/ .el-pagination.is-background .btn-next,
/deep/ .el-pagination.is-background .btn-prev,
/deep/ .el-pagination.is-background .el-pager li {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ff8d0d;
  color: #fff;
}

/deep/ .el-pagination.is-background .el-pager li.active {
  color: #fff;
  cursor: default;
}

/deep/ .el-pagination.is-background .el-pager li:hover {
  color: #ff8d0d;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #ff8d0d;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: #ff8d0d;
  color: #fff;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ff8d0d;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ff8d0d;
  border-color: #ff8d0d;
}
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ff8d0d;
  border-color: #ff8d0d;
}
</style>
