<template>
  <div class="single-student-work-class">
    <div class="works-screen-class">
      <div>
        <ul>
          <li v-for="(item, index) in searchTypeData" :key="index">
            <div class="search-type-class" :class="{ 'select-but-class': selectStyle === index }"
              @click="searchButClick(index)">
              <svg-icon :icon-class="item.svgIcon" :class="{ svgIconColor: selectStyle === index }"
                class="svg-icon-class" />
              <div class="type-name-class">
                <span v-if="item.id === 0">&nbsp;&nbsp;&nbsp;&nbsp;{{ item.name }}</span>
                <span v-else>{{ item.name }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="flag && basicData !== undefined" class="single-detail-class">
      <div class="student-box-class">
        <div class="tag-title-class">
          <div>
            <svg-icon icon-class="figure-icon" class="svg-icon-class" />
          </div>
          <div>学生</div>
        </div>
        <div class="name-class">
          {{ this.basicData.nickname }}
        </div>
      </div>
      <div class="cut-off-class"></div>
      <div class="student-box-class">
        <div class="tag-title-class">
          <div>
            <svg-icon icon-class="gather-icon" class="svg-icon-class" />
          </div>
          <div>共上传作品数量</div>
        </div>
        <div class="name-class">
          {{ this.count }}
        </div>
      </div>
      <div class="cut-off-class"></div>
      <div class="student-box-class">
        <div class="tag-title-class">
          <div><i class="el-icon-time"></i></div>
          <div>最新上传时间</div>
        </div>
        <div class="name-class">
          {{ (Number(basicData.issue_time) * 1000) | formatDate3 }}
        </div>
      </div>
      <div class="history-work-class">
        <div class="return-class" @click="historyCommentClick">
          <div class="history-but-class" :class="{ 'history-click-css': historyCommentState }">
            <div class="box1-class">
              <svg-icon icon-class="history-comment-icon" class="svg-icon-class" />
            </div>
            <div class="box2-class">历史评价</div>
            <div class="box3-class">
              <svg-icon icon-class="point2-icon" class="svg-icon-class" />
            </div>
          </div>
          <!--                    <svg-icon icon-class="exit-icon" class="svg-icon-class"/>-->
        </div>
      </div>
      <div class="return-box-class">
        <div class="return-class" v-if="$store.getters.getUserData.role === 1"
          @click="$router.push({ path: 'worksGather' })">
          <div>返回学生作品集合</div>
          <svg-icon icon-class="exit-icon" class="svg-icon-class" />
        </div>
      </div>
    </div>
    <div v-if="flag && basicData !== undefined && historyCommentState === false" class="periphery-class"
      id="periphery-class">
      <div class="works-content-class">
        <van-list v-model="loading" :finished="finished" :offset="offset" @load="onLoad">
          <div>
            <div class="works-detail-class" v-for="(item, index) in info" :key="index">
              <div class="main-box-class" @click="hrefClick(item)" @mouseleave="editDeleteClick = false">
                <div class="edit-box-class" v-if="$store.getters.getUserData.role === 2">
                  <div class="edit-but-class" @click.stop="editDeleteClick = editDeleteClick === false">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div class="edit-delete-class" v-if="editDeleteClick">
                    <div class="box-class" @click.stop="editButClick(index, item.work_id)">
                      <svg-icon icon-class="edit-icon" class="svg-icon-class" />
                      编辑
                    </div>
                    <div class="box-class" @click.stop="deleteButClick(index, item.work_id)">
                      <svg-icon icon-class="delete-icon" class="svg-icon-class" />
                      删除
                    </div>
                  </div>
                </div>
                <div :class="{ 'z-index-class': commentButState === index }"></div>
                <div class="con-class">
                  <span v-if="
                    item.work_image_url === '' || item.work_image_url === '0'
                  ">
                    <el-image v-if="item.class_name === '课后作业'" class="work-back-img" :src="worksImg1" fit="cover">
                    </el-image>
                    <el-image v-if="item.class_name === '课堂练习'" class="work-back-img" :src="worksImg2" fit="cover">
                    </el-image>
                    <el-image v-if="item.class_name === '自由编程'" class="work-back-img" :src="worksImg3" fit="cover">
                    </el-image>
                  </span>
                  <span v-else>
                    <el-image class="work-back-img" :src="item.work_image_url" fit="cover">
                    </el-image>
                  </span>
                </div>
                <div class="detail-class">
                  <div class="title-name-class">
                    <div class="name-class">
                      <span class="headline-class">
                        {{ item.work_name }}
                      </span>
                      <!--                                            <span class="hot-icon-class">-->
                      <!--                                                <el-image class="hot-icon" :src="hotIcon"-->
                      <!--                                                          fit="cover">-->
                      <!--                                                </el-image>-->
                      <!--                                            </span>-->
                    </div>
                  </div>
                  <div class="tags-class">
                    <span>{{ item.class_name }}</span>
                    <span>{{ item.type_name }}</span>
                  </div>
                  <div class="praise-class">
                    <div class="lesson-name-class">{{ item.lesson_name }}</div>
                    <div class="comment-box-class" :class="{ 'but-click-class': commentButState === index }"
                      v-if="$store.getters.getUserData.role === 1">
                      <div class="comment-but-class" @click.stop="
                        commentButClick(
                          item.work_id,
                          index,
                          item.teacher_comment
                        )
                        ">
                        <div class="hover-style-class">
                          <svg-icon icon-class="comment-icon" class="svg-icon-class" />
                        </div>
                      </div>
                    </div>
                    <!--                                        <div class="comment-box-class" :class="{'but-click-class':commentButState === index}"-->
                    <!--                                             v-else-if="$store.getters.getUserData.role === 2">-->
                    <!--                                            <div class="comment-but-class"-->
                    <!--                                                 @click.stop="deleteButClick(index,item.work_id)">-->
                    <!--                                                <svg-icon icon-class="delete-icon" class="svg-icon-class"/>-->
                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <div class="share-box-class" :class="{ 'but-click-class': shareButState === index }">
                      <div class="share-but-class" @click.stop="shareButClick(index, item.work_id)">
                        <svg-icon icon-class="share-icon" class="svg-icon-class" />
                      </div>
                    </div>
                  </div>
                  <div class="user-class">
                    <div>
                      <div class="user-box-class">
                        <el-image v-if="item.avatar_url === ''" class="head-class" :src="headImg" fit="cover">
                        </el-image>
                        <el-image v-else class="head-class" :src="item.avatar_url" fit="cover">
                        </el-image>
                      </div>
                      <div class="user-box-class nickname-class">
                        <el-tooltip class="item" effect="dark" :content="item.nickname" placement="bottom">
                          <span>{{ item.nickname }}</span>
                        </el-tooltip>
                      </div>
                      <div class="time-box-class">
                        {{ (Number(item.issue_time) * 1000) | formatDate3 }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <div class="return-top-class">
      <div class="icon-box-class" @click="returnButClick">
        <div class="icon-back-class"></div>
        <div class="icon-text-class">回顶部</div>
      </div>
    </div>
    <!--评论弹窗-->
    <CommentAlertV class="comment-alert-class" v-if="commentState" :commentData="commentData"
      @closeCommentAlert="closeCommentAlert"></CommentAlertV>

    <!-- <ShareAlert v-if="shareState" :shareData="shareData" @closeShareAlert="closeShareAlert"></ShareAlert> -->
    <SharePopPc v-if="shareState" :shareData="shareData" @closeShareAlert="closeShareAlert"></SharePopPc>

    <HistoryComment v-if="historyCommentState" :classId="class_id" id="history-comment-id"></HistoryComment>

    <CommentEditAlert v-if="editAlertState" :workId="workId" @editButClick="editButClick"
      @saveEditWorkInfo="saveEditWorkInfo"></CommentEditAlert>
  </div>
</template>

<script>
import Works from "../../models/Home/Works";
import { formatDate } from "../../assets/js/date.js";
import CommentAlertV from "../../components/comment/CommentAlertV";
import ShareAlert from "../../components/share/ShareAlert";
import HistoryComment from "../../components/history/HistoryComment";
import CommentEditAlert from "../../components/comment/CommentEditAlert";
import SharePopPc from "../../components/share/SharePopPc.vue";
import UnloadClass from "../../models/Home/UnloadClass.js";

export default {
  name: "SingleStudentWorks",
  components: {
    CommentAlertV,
    ShareAlert,
    HistoryComment,
    CommentEditAlert,
    SharePopPc,
  },
  data() {
    return {
      searchTypeData: [
        {
          name: "全部",
          state: true,
          icon: "./img/search-all.png",
          cutIcon: "./img/search-cut-all.png",
          svgIcon: "works-all-icon",
          id: 0,
        },
        {
          name: "课堂练习",
          state: false,
          icon: "./img/search-study.png",
          cutIcon: "./img/search-cut-study.png",
          svgIcon: "study-icon",
          id: 1,
        },
        {
          name: "课后作业",
          state: false,
          icon: "./img/search-work.png",
          cutIcon: "./img/search-cut-work.png",
          svgIcon: "class-work-icon",
          id: 2,
        },
        {
          name: "自由编程",
          state: false,
          icon: "./img/search-pro.png",
          cutIcon: "./img/search-cut-pro.png",
          svgIcon: "pro-icon",
          id: 3,
        },
      ],
      selectStyle: 0,
      worksImg1: "./img/works-1.png",
      worksImg2: "./img/works-2.png",
      worksImg3: "./img/works-3.png",
      hotIcon: "./img/hot-icon.png",
      headImg: "./img/default-head-img2.png",
      workImg: "./img/works-3.png",
      returnTopImg: "./img/icon/return-top-icon.png",
      gatherState: false,
      count: 0,
      page: 1,
      limit: 4,
      class_id: 0,
      list: [],
      info: [],
      offset: 10,
      loading: false,
      finished: false,
      commentData: {
        work_id: 0,
        content: "",
      },
      commentState: false,
      commentButState: false,
      shareButState: false,
      shareState: false,
      shareData: {},
      deleteButState: false,
      sortButState: false,
      basicData: {},
      flag: false,
      historyCommentState: false,
      editDeleteClick: false,
      editAlertState: false,
      workId: 0,
    };
  },
  created() {
    this.getWorksShow();
  },
  computed: {
    CourseData() {
      //监听store的数据
      return this.$store.getters.getCourseData;
    },
  },
  watch: {
    CourseData() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.getWorksShow();
    },
  },
  methods: {
    saveEditWorkInfo(selectInfo) {
      console.log(selectInfo);
      this.info.forEach((item, index) => {
        if (item.work_id === selectInfo.work_id) {
          item.class_id = selectInfo.class_id;
          item.class_name = selectInfo.class_name;
          item.lesson_id = selectInfo.lesson_id;
          item.lesson_name = selectInfo.lesson_name;
          item.work_name = selectInfo.work_name;
        }
      });
      //  11111111111111111111
      console.log(this.info);
    },
    editButClick(index, work_id) {
      this.workId = work_id;
      this.editAlertState = this.editAlertState === false;
    },
    historyCommentClick() {
      this.historyCommentState = this.historyCommentState === false;
    },
    showUnlockAlert() {
      this.$Dialog
        .confirm({
          message: "请先解锁课程",
          confirmButtonColor: "#FF8D15",
          showCancelButton: false,
        })
        .then(() => { })
        .catch(() => {
          // on cancel
        });
    },
    checkIfUnlocked(cb) {
      if (this.$store.getters.getUserData.role === 2) {
        UnloadClass.canUnlock({}, ({ type, list }) => {
          if ((type === 200 && list > 0) || localStorage.getItem('unloadGroupData') * 1 > 0) {
            cb(true);
          } else {
            cb(false);
          }
        })
      } else {
        cb(true);
      }
    },
    hrefClick(item) {
      this.checkIfUnlocked((unlocked) => {
        if (unlocked) this.openWork(item);
        else this.showUnlockAlert()
      })
    },
    openWork(item) {
      if (item.type === "sb3") {
        window.open("/gbscratch/?workId=" + item.work_id);
      }
      if (item.type === "python") {
        window.open("/pygame/?workId=" + item.work_id);
      }
      if (item.type === "sjr") {
        window.open(`/jr/?workId=${item.work_id}`);
      }
      if (item.type === "guodongsjr") {
        window.open(`/guobaojr/?&workId=${item.work_id}&aa=1`);
      }
    },
    searchButClick(index) {
      this.selectStyle = index;
      this.class_id = this.searchTypeData[index].id;
      this.page = 1;
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      if (this.historyCommentState === false) {
        this.getWorksShow();
      }
    },
    onLoad() {
      //上拉加载
      this.page++;
      this.getWorksShow();
    },
    getWorksShow() {
      let param = {
        class_id: this.class_id,
        group_id: this.$store.getters.getGroupData.group_id,
        page: this.page,
        student_id: this.$route.query.student_id,
        limit: 8,
      };
      // this.loading = true;
      Works.getWorksShow(param, ({ type, list }) => {
        if (type === 200) {
          this.flag = true;
          list.list.forEach((item, index) => {
            if (item.type === "sb3") {
              item.type_name = "scratch";
            } else if (item.type === "python") {
              item.type_name = "python";
            } else if (item.type === "sjr") {
              item.type_name = "scratchJr";
            } else if (item.type === "guodongsjr") {
              item.type_name = "果动";
            }
          });
          if (this.class_id === 0 && this.page === 1) {
            this.count = list.count;
            this.basicData = list.list[0];
          }
          // this.list = list.list;
          if (this.page == 1) {
            this.list = [];
            list.list.forEach((element) => {
              this.list.push(element);
            });
          } else {
            list.list.forEach((element) => {
              this.list.push(element);
            });
          }
          this.info = this.list;

          // this.loading = false;
          this.info = this.list;
          if (list.list.length < 8) {
            this.finished = true;
          }
          this.loading = false;

          console.log(this.finished, this.loading);
        }
      });
    },
    returnButClick() {
      this.showButState = true;
      if (this.historyCommentState === false) {
        let top = document.getElementById("periphery-class").scrollTop;
        // 实现滚动效果
        const timeTop = setInterval(() => {
          document.getElementById("periphery-class").scrollTop = top -= 50;
          if (top <= 0) {
            clearInterval(timeTop);
          }
        }, 10);
      } else {
        let top = document.getElementById("history-comment-id").scrollTop;
        console.log(document.getElementById("history-comment-id").scrollTop);
        const timeTop = setInterval(() => {
          document.getElementById("history-comment-id").scrollTop = top -= 50;
          if (top <= 0) {
            clearInterval(timeTop);
          }
        }, 10);
      }
    },
    //评价按钮
    commentButClick(work_id, index, teacher_comment) {
      console.log(teacher_comment);
      this.commentButState = index;
      this.commentState = true;
      this.commentData.work_id = work_id;
      this.commentData.content = teacher_comment;
    },
    //分享按钮
    shareButClick(index, work_id) {
      this.shareButState = index;
      this.shareState = true;

      const param = {
        work_id: work_id,
      };
      Works.getWorksQrCode(param, ({ type, list }) => {
        if (type === 200) {
          this.shareData = list;
        }
      });
    },
    //删除 按钮
    deleteButClick(index, workId) {
      this.$Dialog
        .confirm({
          message: "确定删除此作品吗？",
          confirmButtonColor: "#FF8D15",
        })
        .then(() => {
          const params = {
            work_id: workId,
          };
          Works.deleteWork(params, ({ type, list }) => {
            if (type === 200) {
              console.log(type);
              this.page = 1;
              this.finished = false;

              // 重新加载数据
              // 将 loading 设置为 true，表示处于加载状态
              this.loading = true;
              this.getWorksShow();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    closeCommentAlert() {
      this.commentState = false;
      this.commentButState = false;
    },
    closeShareAlert() {
      this.shareState = false;
      this.shareButState = false;
    },
    saveInfo(commentData) {
      this.info.forEach((item, index) => {
        if (item.work_id === commentData.work_id) {
          item.teacher_comment = commentData.content;
        }
      });
    },
  },
  filters: {
    /*
    时间格式自定义 只需把字符串里面的改成自己所需的格式
   */
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy.MM.dd");
    },
    formatDate2(time) {
      var date = new Date(time);
      return formatDate(date, "hh:mm:ss");
    },
    formatDate3(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy.MM.dd. hh:mm");
    },
  },
};
</script>

<style lang="less" scoped>
@import "./SingleStudentWorks";
</style>
