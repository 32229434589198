<template>
    <div id="login">
        <div id="left">
            <img src="https://storage.shubenji.cn/GuoBao/ipad/login-ip.png" alt="">
        </div>
        <div id="right">
            <div id="center">
                <img src="https://storage.shubenji.cn/GuoBao/ipad/login-page.png" alt="">
            </div>
            <div id="big-box">
                <div id="login-info">
                    <div class="title">学生端登录</div>
                    <div class="username">
                        <label>账号</label>
                        <input type="text" v-model="username" placeholder="请输入您的账号" />
                    </div>
                    <div class="password">
                        <label>密码</label>
                        <input type="password" v-model="password" placeholder="请输入您的密码" />
                    </div>
                    <div class="tip">{{ tipContent }}</div>
                    <div class="login-btn">
                        <div class="w-btn">
                            <div @click="handleLogin">登录</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createdLoad } from "@/views/ipad/common";
import fullscreen from "../Fullscreen.js";
import Login from "@/models/Login/Login";

export default {
    name: "index",
    data() {
        return {
            tipContent: '',
            username: '',
            password: '',
            fullscreen: false  // 是否全屏
        }
    },
    beforeCreate() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            if (this.$store.getters.getUserData != null) {
                if (this.$store.getters.getUserData.role === 1) {

                } else {
                    this.$router.push({ path: '/ipad/home' })
                }
            }
        }
    },
    created() {
        createdLoad();
        //解决弹出键盘页面高度变化bug



        // $("input").focus(function () { // 监听获取焦点事件
        //     $("body").css("height", viewHeight); //键盘弹起的时候把body高度设置可视高度
        // })

    },
    mounted() {

    },
    methods: {
        // 全屏事件
        handleFullScreen() {
            let full = new fullscreen(() => {
            });
            full.Fullscreen("#login");
        },
        handleLogin() {
            if (this.username === '') {
                this.tipContent = '请填写账号'
                return false
            }
            if (this.password === '') {
                this.tipContent = '请填写密码'
                return false
            }
            this.tipContent = '';
            // this.$router.push({ path: '/ipad/home' })
            const params = {
                passwd: this.password,
                name: this.username,
            };
            Login.getUserLogin(params, ({ type, list }) => {
                if (type === 200) {
                    if (list.role === 'teacher') {
                        list.role = 1;
                    } else if (list.role === 'student') {
                        list.role = 2;
                    }

                    if (list.role === 'admin') {
                        localStorage.setItem(
                            "adminInfoBoss",
                            JSON.stringify({
                                token: list.token,
                                name: list.username,
                            })
                        );
                        localStorage.setItem(
                            "ms_username_boss", list.username,
                        );
                        window.location.href = "/boss/#/dashboard";
                    } else {
                        this.$store.commit('setUserData', list);
                        if (this.$store.getters.getUserData.role === 1) {
                            alert('暂时不支持老师使用');
                            // this.$router.push({
                            //     path: '/VideoList',
                            //     name: 'VideoList',
                            //     params: {}
                            // });
                        } else {
                            this.$router.push({ path: '/ipad/home' })
                            let full = new fullscreen(() => {
                            });
                            full.Fullscreen("#login");
                        }
                    }
                }
            })
        }
    }
}
</script>

<style lang="less" scoped rel="stylesheet/less">
@import "../commom.css";
@import './portrait.less';


</style>
