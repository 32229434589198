<template>
    <div id="main">
        <!-- <back></back> -->
        <!--        <div class="top">-->
        <!--            <div class="img-box back-img-box" @click="$router.back()">-->
        <!--                <el-image :src="imgUrl + 'return-img.png'" fit="cover"></el-image>-->
        <!--            </div>-->
        <!--            <div class="lesson-name-box">-->
        <!--                <div class="lesson-btn">{{ $store.getters.getCourseData[0].title }}</div>-->
        <!--            </div>-->
        <!--            <div class="img-box set-img-box">-->
        <!--                <span @click="handleMenu">-->
        <!--                    <el-image :src="imgUrl + 'set-icon.png'" fit="cover"></el-image>-->
        <!--                </span>-->
        <!--                <SelectMenu v-if="menuState" :visible.sync='menuState'></SelectMenu>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="content" ref="contentBox">
            <div class="lesson-list-box" ref="lessonList">
                <div class="left-btn btn" @click="cutLesson(-1)"></div>
                <div class="right-btn btn" @click="cutLesson(1)"></div>
                <div class="lesson-box" v-for="(item, index) in info" :key="index" ref="lessonItem"
                     @click="lessonClick(index,item)">
                    <div class="img-box">
                        <el-image :src="item.lesson_image" style="width: 100%;"></el-image>
                    </div>
                    <div class="lesson-num-box">
                        <span class="num">第{{ index + 1 }}节</span>
                    </div>
                    <div class="lesson-title-box">
                        <div class="title">{{ item.lesson_name }}</div>
                    </div>
                    <div class="lock-box" v-if="item.lesson_status === 0">
                        <img src="http://bookperiod.oss-cn-shanghai.aliyuncs.com/GuoBao/ipad/lock-img.png" alt="">
                    </div>
                </div>
            </div>
            <div class="page-box">
                <div class="item-box">
                    <span class="page-item" :class="index === pageIndex ? 'clicked' : ''" v-for="item, index in total"
                          :key="item" @click="cutPage(index)">
                        {{ index * limit + 1 }}
                    </span>
                </div>
            </div>
        </div>
        <IpadAlert v-if="alertState" :visible.sync='alertState' tip="请解锁本节课程"
                   @handleAlertConfirm="alertState = false" :cancelBtn="false"></IpadAlert>
    </div>
</template>

<script>
import back from "@/views/ipad/Home/components/back";
import { createdLoad } from "@/views/ipad/common";
import LessonVideo from "@/models/Home/LessonVideo";
import SelectMenu from "@/components/ipad/SelectMenu";
import IpadAlert from "@/components/ipad/IpadAlert";
import eventVue from "@/assets/js/event";

export default {
    name: "index",
    components: {
        back,
        SelectMenu,
        IpadAlert
    },
    data() {
        return {
            imgUrl: 'https://storage.shubenji.cn/GuoBao/ipad/',
            name: 'JOJO',
            timer: null,
            windowWidth: 0,
            windowHeight: 0,

            t1: 0,
            t2: 0,
            isCanScroll: true,
            rect: null,
            // 当前选中的lessonIndex
            theIndex: 0,
            info: [],
            total: 0,
            limit: 5,
            pageIndex: 0,
            menuState: false,
            alertState: false,
            scroll_lesson_id: 0,
            timeSetInterval:null
        }
    },
    created() {
        createdLoad();
        this.getNewLastLesson();
        this.getLessonListInfo(1, () => {
            let LessonListDom = this.$refs.lessonList,
                contentDom = this.$refs.contentBox;
            this.windowWidth = document.documentElement.clientWidth;
            this.windowHeight = document.documentElement.clientHeight;

            setTimeout(() => {
                if (this.info.length == 0) return;
                this.rect = this.$refs.lessonItem[0].getBoundingClientRect();
                let gauge = 'calc(' + (this.windowWidth / 2 - ((this.rect.width / 2) * 1.2)) + 'px - 1.5rem)';
                LessonListDom.style.paddingLeft = LessonListDom.style.paddingRight = gauge;
                // this.domCenter(this.theIndex);

            }, 100);
            for (let i = 2; i <= this.total; i++) {
                setTimeout(()=>{
                    this.getLessonListInfo(i);
                },200 *i)
            }
        });

    },
    mounted() {

    },
    watch: {
        info: {
            // handler(newValue, oldValue) {
            //     for (let i = 0; i < newValue.length; i++) {
            //         if (oldValue[i] != newValue[i]) {
            //             console.log(newValue)
            //             newValue.forEach((item, index) => {
            //                 if (item.centerState !== undefined && item.centerState === true) {
            //
            //                     this.domCenter(index)
            //                 }
            //             })
            //         }
            //     }
            // },
            deep: true
        }
    },
    beforeDestroy() {
        let contentDom = this.$refs.contentBox;
        contentDom.removeEventListener('scroll', this.scrollChange, true);
    },
    methods: {
        getNewLastLesson() {
            let group_id = this.$store.getters.getGroupData.group_id;
            LessonVideo.getLatestStep({ lesson_id: -1, group_id: group_id }, ({ type, list }) => {
                if (type === 200) {
                    this.scroll_lesson_id = list.lesson_id;
                }
            })
        },
        lessonClick(index, item) {
            if (this.theIndex == index) {

                if (item.lesson_status === 0) {
                    this.alertState = true;
                } else {
                    let group_id = this.$store.getters.getGroupData.group_id;
                    LessonVideo.updateStepHistory({
                        lesson_id: item.lesson_id, step_id: 1, group_id: group_id
                    }, ({ type, list }) => {
                        if (type === 200) {

                        }
                    })
                    // window.open('/teacher/#/ipad/lessonDetail?lesson_id=' + item.lesson_id + '&lesson_name=' + item.lesson_name + '&group_id=' + this.$store.getters.getGroupData.group_id + '&course_id=' + this.$store.getters.getCourseData[0].id + '&text=' + item.lesson_text, '_blank');
                    this.$router.push({
                        path: "/ipad/lessonDetail",
                        query: {
                            lesson_id: item.lesson_id,
                            lesson_name: item.lesson_name,
                            group_id: this.$store.getters.getGroupData.group_id,
                            course_id: this.$store.getters.getCourseData[0].id,
                            text: item.lesson_text
                        },
                    });
                }
                return;
            }
            ;
            this.theIndex = index;
            this.domCenter(index);
        },
        // 切换上一个或下一个
        cutLesson(num) {
            let index = this.theIndex + num;
            if (index >= this.info.length || index < 0) {
                console.log('到底了');
                return;
            }
            ;
            this.theIndex = index;
            this.domCenter(this.theIndex);
        },

        // 元素居中
        domCenter(index, second = 0.4, fun = () => {
        }) {
            this.isCanScroll = false;

            let LessonDoms = this.$refs.lessonItem;
            if (!LessonDoms[index]) {
                return;
            }

            LessonDoms.forEach(element => {
                element.style.fontSize = '1rem';
                element.style.marginTop = '0px';
            });

            LessonDoms[index].style.fontSize = '1.2rem';
            LessonDoms[index].style.marginTop = '-2.4rem';

            let lessonListDom = this.$refs.lessonList;
            let newLeft = (this.rect.width + (this.rect.width * 0.1)) * index;
            lessonListDom.style.left = -newLeft + 'px';

            this.pageIndex = Math.floor(index / this.limit);
        },
        getLessonListInfo(page = 1, fun = () => {
        }) {
            let params = {
                course_id: this.$store.getters.getCourseData[0].id,
                group_id: this.$store.getters.getGroupData.group_id,
                page: page,
                limit: this.limit,
                goods_category: localStorage.getItem('goodsCategory'),
            }

            LessonVideo.getStudentLessonList(params, ({ type, list }) => {
                if (type === 200) {
                    list.list.forEach(item => {
                        if (item.lesson_name.indexOf('<br>') > -1) {
                            item.lesson_name = item.lesson_name.replace("<br>", "-")
                        }
                    })

                    this.info = this.info.concat(list.list);
                    if (list.list.length < 5) {
                        clearInterval(this.timeSetInterval)
                    }

                    console.log(this.info)
                    if (this.total === 0) {
                        eventVue.$emit('onAttrCourse', {
                            title: list.course_name
                        })
                        this.total = list.total;
                    }
                    if (this.total === page) {
                        if (this.scroll_lesson_id > 0) {
                            let infoIndex = this.info.findIndex(item => item.lesson_id === this.scroll_lesson_id);
                            console.log(infoIndex)
                            this.$set(this.info[infoIndex], 'centerState', true);
                            console.log(this.info);
                            this.theIndex = infoIndex;
                            setTimeout(() => {
                                this.domCenter(infoIndex)
                            }, 300)

                        } else {
                            this.theIndex = 0;
                            this.domCenter(0)
                        }

                        // setTimeout(() => {
                        //     this.domCenter(infoIndex)
                        // }, 200)
                    }
                    fun();
                }
            })
        },
        handleMenu() {
            this.menuState = true;
        },
        cutPage(index) {
            this.theIndex = this.limit * index;
            this.domCenter(this.theIndex);
        }
    }
}
</script>
<style lang="less" scoped>
@import "../commom.css";
@import "./course-index";
</style>
