<template>
    <div id="download">
        <div id="div1">
            <ul>
                <li v-for="item in list">
                    <div>{{ item.category_name }}</div>
                    <span class="span-class" v-for="val in item.items"  @click="downloadZip(val.url)">{{val.item_name}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { createdLoad } from "@/views/ipad/common";
import SelectMenu from "@/components/ipad/SelectMenu";
import ResourceDown from "@/models/Down/ResourceDown";
import Button1 from "@/components/ipad/Button-1";
export default {
    name: "index",
    components:{
        SelectMenu,
        Button1
    },
    data(){
        return {
            imgUrl: 'https://storage.shubenji.cn/GuoBao/ipad/',
            name: 'JOJO',
            menuState: false,
            attr: {
                name: '下载',
                // click: { click: _ => this.handleUpdateInfo() }
            },
            list:[
                {
                    name:'scratch课程专区',
                    items:[{
                        name:'课后作业',
                    }, {
                        name:'课后作业',
                    }]
                },
                {
                    name:'scratch课程专区',
                    items:[{
                        name:'课后作业',
                    }, {
                        name:'课后作业',
                    }]
                },
                {
                    name:'scratch课程专区',
                    items:[{
                        name:'课后作业',
                    }, {
                        name:'课后作业',
                    }]
                },
                {
                    name:'scratch课程专区',
                    items:[{
                        name:'课后作业',
                    }, {
                        name:'课后作业',
                    }]
                },
                {
                    name:'scratch课程专区',
                    items:[{
                        name:'课后作业',
                    }, {
                        name:'课后作业',
                    }]
                },
                {
                    name:'scratch课程专区',
                    items:[{
                        name:'课后作业',
                    }, {
                        name:'课后作业',
                    }]
                }
            ]
        }
    },
    created() {
        createdLoad();
        this.getResourceDownInfo();
    },
    methods: {
        handleMenu() {
            this.menuState = true;
        },
        getResourceDownInfo() {
            const params = {
                page: 1,
                limit: 40,
            }
            ResourceDown.getExtraResource(params, ({type, list}) => {
                if (type === 200) {
                    console.log(list)
                    this.list = list.list;
                }
            })
        },
        downloadZip(url) {
            const aTag = document.createElement('a')
            aTag.href = url //文件id
            aTag.click()
        }
    }
}
</script>

<style lang="less" scoped>
@import "./download.less";

/deep/.btn .btn-name{
    padding-left: 4rem;
    padding-right: 4rem;
}
</style>
