<template>
    <div class="comment-class" ref="treeWrap" :class="{'close-transform-class':clickCloseButState === true}">
        <div class="comment-box-class">
            <el-form ref="commentData" :model="commentData">
                <div class="title-class">
                    <span>评价</span>
                    <span class="close-class" @click="closeClick"><i class="el-icon-close"></i></span>
                </div>
                <div class="textarea-class">
                    <el-form-item>
                        <el-input type="textarea" v-model="commentData.content"
                                  :class="{'active-class':commentData.content!== ''}"
                                  placeholder="填写您对学生这组作品的评价吧~"></el-input>
                    </el-form-item>
                </div>

                <div class="upload-class">
                    <div class="upload-box-class">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadUrl"
                            list-type="picture-card"
                            :file-list="studentWorkfileList"
                            :on-preview="handlePictureCardPreview"
                            :before-upload="studentWorkBeforeUpload"
                            :on-remove="handleRemove"
                            :on-success="studentWorkSuccess">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </div>
                    <div class="upload-but-class" :class="{'active-but':commentData.content!== ''}" @click="onSubmit">
                        发表评论
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import Works from "../../models/Home/Works";
import Api from "../../models/Home/Api";
import Qs from 'qs';

export default {
    props: ['commentData'],
    name: "commentAlert",
    data() {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            form: {
                content: ''
            },
            uploadUrl: '/resource/v1/uploadFile?token=' + JSON.parse(localStorage.getItem('info')).token,
            studentWorkfileList: [],
            clickCloseButState: false
        }
    },
    mounted() {
        let _this = this;
        document.addEventListener('mouseup', (e) => {
            let tree = this.$refs.treeWrap
            if (tree) {
                if (!tree.contains(e.target)) {
                    _this.$emit('closeCommentAlert')
                    // this.isShowTree =false
                }
            }
        })


        // 显示作品文件框框时触发
        this.showStudentWorkList();
    },
    methods: {
        onSubmit() {
            console.log(this.commentData);
            console.log(this.form);
            const _this = this;
            this.$Dialog.confirm(
                {
                    message: '确定提交吗？',
                    confirmButtonColor: '#FF8D15',
                })
                .then(() => {
                    const request = Qs.stringify({
                        work_id: this.commentData.work_id,
                        content: this.commentData.content,
                    });

                    this.studentWorkfileList.forEach(item => {
                        if (item.rec_id === 0) {
                            Api.fetchData(
                                {
                                    works_id: this.commentData.work_id,
                                    resource_id: item.id,
                                    type: item.type
                                },
                                '/bureau/v1.2/bindWorksResource',
                                ({type, list}) => {
                                })
                        }
                    });

                    Works.saveEvaluateWork(request, ({type, list}) => {
                        if (type === 200) {
                            this.$parent.saveInfo(this.commentData)
                            // this.$refs[`popover-` + row.work_id].doClose()
                        }
                    });

                })
                .catch(() => {
                    // on cancel
                });
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            // console.log(file)
            // this.dialogImageUrl = file.url;
            // this.dialogVisible = true;
        },
        closeClick() {
            this.clickCloseButState = this.clickCloseButState === false;
            const _this = this
            setTimeout(function () {
                _this.$emit('closeCommentAlert')
            },400)

        },
        studentWorkBeforeUpload(file) {
            console.log(file)
            var fileSize = file.size / 1024 / 1024 < 5;
            if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov', 'image/png', 'image/jpeg', 'image/gif'].indexOf(file.type) == -1) {
                this.$alert('请上传正确的格式', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {

                    }
                });
                return false;
            }
            if (!fileSize) {
                this.$alert('大小不能超过5MB', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {

                    }
                });
                return false;
            }
        },
        studentWorkSuccess(res) {
            console.log(res)
            var data = res.data,
                urlArr = data.url.split('/'),
                name = urlArr[urlArr.length - 1],
                video = ['mp4'],
                // img = ['png','PNG','jpg','JPG','gif','GIF'],
                suf = name.split('.')[1],
                type = video.indexOf(suf) === 0 ? 'video' : 'image';

            // if (this.myUploadFileList === '') {
            //     this.myUploadFileList = [];
            // }
            // this.myUploadFileList.push({
            //     id: data.id,
            //     relativeUrl: data.relativeUrl,
            //     url: data.url,
            //     name: name,
            //     type: type
            // });
            // this.myUploadFileList
            this.clickBufferPhoto({
                id: data.id,
                name: name,
                relativeUrl: data.relativeUrl,
                type: type,
            });
        },
        // 点击缓冲的图片时
        clickBufferPhoto(row) {
            document.getElementsByClassName('el-upload--picture-card')[0].className += ' active-upload-class';
            var n = 0;
            this.studentWorkfileList.forEach(item => {
                if (item.id === row.id) {
                    this.$message({
                        message: '已经有添加过了',
                        type: 'warning',
                        iconClass: 'iconClass',
                    });
                    n++;
                }
            });
            if (n === 0) {
                let url = '';
                if (row.type === 'video') {
                    url = './img/teacher-msg.jpg';
                } else {
                    url = row.relativeUrl;
                }
                this.studentWorkfileList.push({
                    id: row.id,
                    name: row.name,
                    url: url,
                    type: row.type,
                    rec_id: row.rec_id ? row.rec_id : 0
                });
            }

        },
        //上传成功回调
        handleVideoSuccess(res, file) {
            this.isShowUploadVideo = true;
            this.videoFlag = false;
            this.videoUploadPercent = 0;
            //后台上传地址
            if (res.code == 1) {
                console.log('执行了', res.data.image_url)
                this.curgimg = res.data.image_url
                this.videoForm.showVideoPath = (this.httpurl + res.data.image_url);
            } else {
                this.$alert(res.info, '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.$message({
                            type: 'info',
                            message: `action: ${action}`
                        });
                    }
                });
            }
        },
        showStudentWorkList() {
            this.studentWorkfileList = [];
            Api.fetchData({
                    works_id: this.commentData.work_id
                },
                '/bureau/v1.2/listWorksResource', ({type, list}) => {
                    list.forEach((item, index) => {
                        this.clickBufferPhoto({
                            id: item.resource_id,
                            name: (item.type === 'image' ? '照片' : '视频') + (index + 1),
                            relativeUrl: item.url,
                            type: item.type,
                            rec_id: item.rec_id
                        });
                    });
                })
        }
    }
}
</script>

<style lang="less" scoped>
.comment-class {
    width: 760px;
    height: 300px;
    margin: 0 auto;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 10px;
    z-index: 2;
    box-shadow: -3px 3px 6px rgba(18, 18, 18, 0.16);
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    @-webkit-keyframes slide-top {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(-170px);
            transform: translateY(-170px);
        }
    }
    @keyframes slide-top {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(-170px);
            transform: translateY(-170px);
        }
    }

    .comment-box-class {
        width: 700px;
        margin: 0 auto;

        .title-class {
            font-size: 20px;
            //font-weight: bold;
            height: 52px;
            border-bottom: 1px solid rgba(18, 18, 18, 0.10196078431372549);;
            margin: 8px 0;
            line-height: 52px;
            color: #333333;

            .close-class {
                display: inline-block;
                float: right;
                cursor: pointer;

                i {
                    font-size: 30px;
                    color: #C4C4C4;
                }

                i:hover {
                    color: #FF8300;
                }
            }
        }

        .active-class {

            /deep/ textarea {
                border: 1px solid #FFC17F !important;
                background: #FFFFFF !important;
            }
        }

        .textarea-class {
            margin-top: 20px;
            background: #E6E6E6 !important;
            border-radius: 5px;

            /deep/ textarea {

                //opacity: 0.25;
                border: 1px solid rgba(18, 18, 18, 0.25098039215686274);
                height: 100px;
                background: #E6E6E6;
                //margin-top: 10px;
                resize: none;
                color: #666666 !important;
                font-size: 14px;
            }

            /deep/ textarea:hover {
                cursor: pointer;
                border: 1px solid #FFC17F !important;
                background: #FFFFFF !important;
            }

            /* 设置滚动条的样式 */

            ::-webkit-scrollbar {
                width: 6px;
            }

            /* 滚动槽 */

            ::-webkit-scrollbar-track {
                -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
                border-radius: 10px;
            }

            /* 滚动条滑块 */

            ::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: rgba(0, 0, 0, 0.1);
                -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
            }

            ::-webkit-scrollbar-thumb:window-inactive {
                background: rgba(0, 0, 0, 0.1);
            }

            /deep/ textarea::-webkit-input-placeholder {
                color: #666666;
            }

            /deep/ textarea::-moz-placeholder { /* Mozilla Firefox 19+ */
                color: #666666;
            }

            /deep/ textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color: #666666;
            }

            /deep/ textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #666666;
            }
        }

        .upload-class {
            display: flex;
            padding-bottom: 20px;

            /* 设置滚动条的样式 */

            ::-webkit-scrollbar {
                width: 6px;
            }

            /* 滚动槽 */

            ::-webkit-scrollbar-track {
                -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
                border-radius: 10px;
            }

            /* 滚动条滑块 */

            ::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: rgba(0, 0, 0, 0.1);
                -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
            }

            ::-webkit-scrollbar-thumb:window-inactive {
                background: rgba(0, 0, 0, 0.1);
            }

            .upload-box-class {
                width: 560px;
                height: 80px;
                overflow-y: scroll;

                /deep/ .el-upload--picture-card {
                    width: 80px;
                    height: 80px;
                    position: relative;
                    background: #C4C4C4;
                }

                /deep/ .el-upload--picture-card:hover {
                    border: 5px solid #FFC17F;
                    background: #FFFFFF;

                    .el-icon-plus {
                        left: 24px;
                        top: 24px;
                        color: #FF8300;
                    }
                }

                /deep/ .el-icon-plus {
                    position: absolute;
                    font-size: 22px;
                    left: 28px;
                    top: 28px;
                    color: #FFFFFF;
                }

                /deep/ .el-upload-list__item {
                    width: 80px;
                    height: 80px;
                    transition: none !important;
                }

                /deep/ .el-upload-list__item-actions {
                    width: 80px;
                    height: 80px;
                }
            }

            .upload-but-class {
                width: 140px;
                height: 40px;
                background: #C4C4C4;
                color: #FFFFFF;
                border-radius: 10px;
                text-align: center;
                line-height: 40px;
                font-size: 14px;
                margin-top: 40px;
                cursor: pointer;
            }

            .upload-but-class:hover {
                border: 3px solid #FF8300;
                color: #FF8300;
                background: #FFFFFF;
            }

            .active-but {
                border: 3px solid #FF8300;
                color: #FF8300;
                background: #FFFFFF;
            }
        }
    }
}

.close-transform-class {
    -webkit-animation: css-active 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: css-active 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes css-active {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(140px);
        transform: translateY(140px);
    }
}

@keyframes css-active {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(140px);
        transform: translateY(140px);
    }
}

/deep/ .active-upload-class {
    border: 5px solid #FFC17F !important;
    background: #FFFFFF !important;

    .el-icon-plus {
        left: 24px !important;
        top: 24px !important;
        color: #FF8300 !important;
    }
}
</style>
