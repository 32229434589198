<template>
    <div class="save-pwd">
        <div class="z-index-box"></div>
        <div class="orange-box">
            <div class="info">
                <div class="div0">
                    <div></div>
                    <div>修改密码</div>
                    <div @click="handleClose">
                        <svg-icon icon-class="close-icon" class="close-icon-class"></svg-icon>
                    </div>
                </div>

                <div class="div3">
                    <div>
                        <label>旧密码：</label>
                        <input v-model="oldPassword"></input>
                    </div>
                    <div>
                        <label>新密码：</label>
                        <input v-model="newPassword"></input>
                    </div>
                </div>
                <div class="div4">
                    <Button1 :attr='attr' :audio="true"></Button1>
                    <Button1 :attr='attr1' :audio="true" :color="false"></Button1>
                </div>
            </div>
        </div>
        <IpadAlert v-if="alertState" :visible.sync='alertState' tip="修改密码成功后会重新登录，确认修改吗？" @handleAlertConfirm="handleAlertConfirm"></IpadAlert>
    </div>
</template>

<script>
import Button1 from "@/components/ipad/Button-1";
import IpadAlert from "@/components/ipad/IpadAlert";
import Qs from "qs";
import Login from "@/models/Login/Login";

export default {
    name: "SavePassword",
    components: {
        Button1,
        IpadAlert
    },
    data() {
        return {
            alertState: false,
            attr: {
                name: '密码修改',
                click: { click: _ => this.handleUpdateInfo() }
            }, attr1: {
                name: '取消',
                click: { click: _ => this.handleClose() }
            },
            oldPassword: '',
            newPassword: '',
        }
    },
    methods: {
        handleClose() {
            // this.state = false;
            this.$emit('update:visible', false)
        },
        handleUpdateInfo() {
            this.alertState = true;
        },
        handleAlertConfirm(val){
            if(val === true){
                const request = Qs.stringify({
                    oldpassword: this.oldPassword,
                    newpassword: this.newPassword,
                    repassword: this.newPassword,
                });
                Login.savePassword(request, ({type, list}) => {
                    if (type === 200) {
                        localStorage.clear();
                        this.$store.commit('setClearState')
                        this.$router.push({ path: '/ipad/login' })
                    }
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.save-pwd {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .z-index-box {
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3000);
        position: fixed;
        top: 0;
        left: 0;
    }

    .orange-box {
        width: 50rem;
        background: #ff921d;
        border-radius: 2rem;
        position: relative;
        z-index: 12;

        .info {
            //width: 46rem;
            margin: 1.2rem;
            //margin: 0 auto;
            background: #FFFFFF;
            border-radius: 2rem;
            padding: 2rem;

            .div0 {
                display: grid;
                grid-template-columns: 4rem auto 4rem;
                align-items: center;

                font-size: 2rem;
                font-weight: bold;
                color: #FDA349;

                div:nth-child(2) {
                    text-align: center;
                }

                div:nth-child(3) {
                    width: 4rem;
                    height: 4rem;
                    border: 0.1rem solid #FDA249;
                    border-radius: 100%;
                    text-align: center;

                    .close-icon-class {
                        margin-top: 0.5rem;
                        width: 3rem;
                        height: 3rem;
                        border-radius: 3rem;
                        background: #FC9D3E;
                        color: #FFFFFF !important;
                        box-shadow: 0 0.3rem 0.5rem 0.5rem rgba(251, 135, 16, 0.451);
                    }
                }
            }

            .div3 {
                div {
                    display: grid;
                    grid-template-columns: 8rem auto;
                    background: #EEEEEE;
                    padding: 1rem 2rem;
                    margin-top: 2rem;
                    border-radius: 2rem;
                    font-size: 1.6rem;

                    input {
                        background: #EEEEEE;
                        width: 100%;
                        font-size: 1.6rem;
                        border: none;
                    }
                }
            }

            .div4 {
                display: flex;
                margin: 1rem 0 2rem 0;
                justify-content: space-around;

                /deep/ .no-select-color .btn-name {
                    padding-left: 4rem;
                    padding-right: 4rem;

                }
            }
        }
    }
}
</style>
