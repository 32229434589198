<template>
  <div class="share-alert-class">
    <div class="mask-layer-class"></div>
    <div class="share-box-class" ref="treeWrap">
      <div class="share-padding-class">
        <i class="el-icon-close" @click="$emit('closeShareAlert')"></i>
      </div>
      <div class="work-text-name">
        <div class="text">我完成了</div>
        <div class="text text-name" style="color: #ffc000">{{ shareData.work_name }}</div>
        <div class="text">真的很棒！</div>
      </div>

      <div class="share-mian">
        <div class="share-center-main">
          <div class="work-title" style="">
            <img src="../../assets/img/share/radius-icon.png" alt="" />
            <div>作品封面</div>
          </div>

          <div class="work-img">
            <img
              v-if="
                shareData.work_image_url !== '' &&
                shareData.work_image_url != undefined
              "
              :src="shareData.work_image_url"
              alt=""
            />
            <img v-else :src="workImg" alt="" />
          </div>

          <div class="boby-msg">
            <div class="box-info">
              <div class="name">我家宝贝：{{ shareData.user_name }}</div>
              <div class="boss-name">{{ shareData.bureau_name }}</div>
              <div class="text">学习果宝编程课程</div>
              <div class="work-num">
                累计完成作品
                <span>{{ shareData.work_num }}</span>
                件
              </div>
            </div>
          </div>

          <img
            class="static-img1"
            src="../../assets/img/share/robot.png"
            alt=""
          />
          <img
            class="static-img2"
            src="../../assets/img/share/robot-2.png"
            alt=""
          />
        </div>
      </div>

      <div
        class="school-msg-qrcode"
        style="height: 83px; border-radius: 0 0 15px 15px"
      >
        <div class="address-phone">
          <div class="school-msg">
            <img src="../../assets/img/share/school-icon1.png" alt="" />
            <div class="msg-it">
              {{ shareData.bureau_name }}
            </div>
          </div>
          <div class="school-msg">
            <img src="../../assets/img/share/school-icon2.png" alt="" />
            <div class="msg-it">
              <span
                v-if="
                  shareData.group_phone !== undefined &&
                  shareData.group_phone !== ''
                "
                >{{ shareData.group_phone }}</span
              >
              <span v-else>{{ shareData.bureau_phone }}</span>
            </div>
          </div>
          <div class="school-msg">
            <img src="../../assets/img/share/school-icon3.png" alt="" />
            <div class="msg-it">
              {{ shareData.bureau_location }}
            </div>
          </div>
        </div>
        <div class="tip-qrcode">
          <div class="tip-text" style="">扫码查看作品</div>
          <img :src="shareData.qr_code" alt="" @click="hanleQrcode" class="samll-img"/>

          <div class="big-qr-code" v-show="qrCodeState">
            <img :src="shareData.qr_code" alt="" class="big-img"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["shareData"],
  mounted() {
    console.log(this.shareData.work_image_url);
    let _this = this;
    document.addEventListener("mouseup", (e) => {
      let tree = this.$refs.treeWrap;
      if (tree) {
        if (!tree.contains(e.target)) {
          _this.$emit("closeShareAlert");
          // this.isShowTree =false
        }
      }
    });
  },
  data() {
    return {
      qrCodeState: false,
      workImg: "./img/bf-works.png",
    };
  },
  methods: {
    hanleQrcode() {
        this.qrCodeState = !this.qrCodeState;
    },
  },
};
</script>

<style lang="less" scoped>
.share-alert-class {
  width: 100%;
  // 遮罩层
  .mask-layer-class {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(18, 18, 18, 0.64);
  }

  .share-box-class {
    position: fixed;
    width: 340px;
    height: 680px;
    border-radius: 10px;
    background-image: url("../../assets/img/share/share-pop-bck.png");
    background-size: 100% 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /*向左向上分别平移自身的一半*/
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    .share-padding-class {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      right: 20px;
      z-index: 1;
      display: flex;

      i {
        font-size: 30px;
        margin-left: 10px;
        color: #ffffff;
        cursor: pointer;
      }

      i:hover {
        color: #4d97ff;
      }
    }
    .work-text-name {
      color: #ffffff;
      margin: 0 62px;
      padding-top: 44px;
      .text {
        font-size: 20px;
        font-weight: bold;
      }
      .text-name{
        font-size: 24px;
        white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
      }
    }

    .share-mian {
      background: #8ebaef;
      border-radius: 15px;
      margin: 10px 22px;
      padding: 8px 0;

      .share-center-main {
        background: #ffffff;
        position: relative;
        border-radius: 15px;
        margin: 0 8px;
        height: 400px;
      }

      .work-title {
        background: linear-gradient(180deg, #89c5ea 0%, #1d66bf 100%);
        color: #ffffff;
        display: flex;
        align-items: center;
        width: 110px;
        height: 28px;
        border-radius: 0px 130px 130px 0;
        margin-left: -8px;
        position: relative;
        top: 10px;
        font-size: 14px;
        img {
          width: 16px;
          height: 16px;
          margin: 0 7px;
        }
      }
      .work-img {
        width: 241px;
        height: 170px;
        margin: 20px auto;
        img {
          width: 100%;
          height: 100%;
          border-radius: 15px;
        }
      }

      .boby-msg {
        color: #ffffff;
        font-weight: bold;
        background: linear-gradient(137deg, #83a3de 0%, #285fc5 100%);
        display: flex;
        margin-left: auto;
        position: relative;
        width: 165px;
        height: 138px;
        border-radius: 15px;
        margin-right: 14px;

        .box-info {
          margin: 14px;
          .name {
            font-size: 14px;
            color: #ffc000;
          }
          .boss-name {
            font-size: 16px;
            text-align: center;
            margin: 6px 0;
          }
          .text {
            font-size: 12px;
          }

          .work-num {
            font-size: 8px;
            position: absolute;
            bottom: 10px;
            span {
              font-size: 16px;
              color: #ffc000;
            }
          }
        }
      }

      .static-img1 {
        position: absolute;
        width: 88px;
        right: 0;
        top: -30px;
      }
      .static-img2 {
        position: absolute;
        height: 217px;
        bottom: -10px;
        left: -30px;
      }
    }
  }

  .school-msg-qrcode {
    background: linear-gradient(180deg, #90d4f1 0%, #6cbbeb 100%);
    color: #1748a2;
    font-weight: bold;
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    .address-phone {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .school-msg {
        display: flex;
        align-items: center;
        img {
          width: 10px;
          height: 10px;
          margin: 0 12px;
        }

        .msg-it {
          width: 220px;
          font-size: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
    .tip-qrcode {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      position: relative;
      .tip-text {
        width: 70px;
        height: 14px;
        line-height: 14px;
        font-size: 8px;
        color: #1748a2;
        background: #ffffff;
        border-radius: 7px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .samll-img {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }

      .big-img{
        width: 200px;
        height: 200px;
        position: absolute;
        top: -125px;
        right: -240px;
      }
    }
  }
}
</style>
