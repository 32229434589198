<template>
  <!-- v-on:mousemove="mousemove" -->
  <div>
    <div style="width: 100%; height: 100vh; position: absolute; z-index: 1" @mousemove="mousemove"
      @mouseenter="mouseenter" @click="bindVmClick"></div>

    <div class="cut-content-class">
      <div class="up" @click="handleUpBut()">
        <div>
          <!-- <i class="el-icon-arrow-up"></i> -->
          <i class="iconfont icon-point up-point"></i>
        </div>
      </div>
      <div class="down" @click="handleDownBut()">
        <div>
          <!-- <i class="el-icon-arrow-up"></i> -->
          <i class="iconfont icon-point up-point"></i>
        </div>
      </div>
    </div>

    <!-- <div class="step-content-class" v-show="drawer === false">
          <div>{{ initState.stepIndex + 1 }}/{{ stepData.length }}</div>
          <div>{{ initState.stepTitle }}</div>
        </div> -->
    <div class="header" :style="{ width: headerFooter.width }" v-show="initState.headerState" @mouseleave="headerLeave">
      <!-- @click="quanping"-->
      <div style="flex: 1">
        <div v-if="lessonInfo != undefined" style="display: flex">
          <div class="ja-btn" @click="teachPlanClick(lessonInfo.act_id)" v-if="
            lessonInfo.act_id != 0 && $store.getters.getUserData.role === 1
          ">
            <div class="jiao-an">
              <img src="../../assets/img/book-icon.png" alt="" class="img-icon open-class" />
              <span>教案</span>
            </div>
          </div>
          <div class="ja-btn-1" @click="
            handleStuRowHref(
              lessonInfo.lesson_id,
              lessonInfo.extra_code[0].id,
              courseType
            )
            " v-if="
              lessonInfo.raw_code_urls.length !== 0 &&
              $store.getters.getUserData.role === 1
            ">
            <div class="stu-ym">
              <img src="../../assets/img/task-icon.png" alt="" class="img-icon task-icon" />
              <span>学生源码</span>
            </div>
          </div>
          <div class="ja-btn-1" @click="handleOriginSoft(courseType)" v-if="
            lessonInfo.raw_code_urls.length == 0 &&
            $store.getters.getUserData.role === 1
          ">
            <div class="stu-ym">
              <img src="../../assets/img/task-icon.png" alt="" class="img-icon task-icon" />
              <span>学生源码</span>
            </div>
          </div>

          <div v-if="$store.getters.getUserData.role === 1">
            <!-- <div class="btn-class open-box" @click="handleControll" v-if="controllBtnState === true">
                            <img src="../../assets/img/open-icon.png" alt="" class="img-icon open-class" />
                            <span>打开</span>
                        </div>

                        <div class="btn-class open-box" @click="handleControll" v-else>
                            <img src="../../assets/img/close-icon.png" alt="" class="img-icon open-class" />
                            <span>遮住</span>
                        </div> -->

            <div class="open-class-btn" @click="handleOpenClass" v-if="$store.getters.getUserData.role === 1">
              <div class="open-class-box" v-if="openClassState === true">
                <img src="https://storage.shubenji.cn/GuoBao/game/open-class-page.png" alt=""
                  class="img-icon open-class" />
                <span>打开</span>
              </div>
              <div class="open-class-box" v-else>
                <img src="https://storage.shubenji.cn/GuoBao/game/close-class-page.png" alt=""
                  class="img-icon open-class" />
                <span>遮住</span>
              </div>
            </div>
          </div>
        </div>

        <div class="ja-btn" @click="teachPlanTextClick" v-if="$store.getters.getUserData.role === 2">
          <div class="jiao-an">
            <img src="../../assets/img/book-icon.png" alt="" class="img-icon open-class" />
            <span>课时文字</span>
          </div>
        </div>
      </div>
      <div class="big-title-box">
        <div class="title-name">
          <i class="iconfont icon-point left-point"></i>
          {{ $route.query.lesson_name }}
          <i class="iconfont icon-point right-point"></i>
        </div>
      </div>

      <div class="top-btn">
        <div v-if="$store.getters.getUserData.role === 1">
          <div class="btn-class open-box" @click="handleControll" v-if="controllBtnState === true">
            <img src="../../assets/img/open-icon.png" alt="" class="img-icon open-class" />
            <span>打开</span>
          </div>

          <div class="btn-class open-box" @click="handleControll" v-else>
            <img src="../../assets/img/close-icon.png" alt="" class="img-icon open-class" />
            <span>遮住</span>
          </div>
        </div>

        <div class="btn-class video-box" @click="fullVideoClick" v-if="allVideoState">
          <img src="../../assets/img/video-icon.png" alt="" class="img-icon video-icon" />
          <span>完整视频</span>
        </div>
        <div :class="{ openStepClass: drawer === true }" v-if="
          courseType === 'scratch' ||
          courseType === 'scratchJr' ||
          courseType === 'guodong'
        ">
          <div v-if="$store.getters.getUserData.role === 2" class="btn-class task-box"
            @click="studentHrefScratch(courseType, lessonInfo.lesson_id)">
            <img src="../../assets/img/task-icon.png" alt="" class="img-icon task-icon" />
            <span v-if="studentAiPackList.length !== 0">AI探险</span>
            <span v-else>小豪出码</span>
          </div>
          <div v-else>
            <div v-if="
              listState &&
              lessonInfo.extra_code.length === 1 &&
              $store.getters.getUserData.role === 1
            " class="btn-class task-box" @click="
              teacherHrefScratch(
                lessonInfo.lesson_id,
                lessonInfo.extra_code[0].id,
                courseType
              )
              ">
              <img src="../../assets/img/task-icon.png" alt="" class="img-icon task-icon" />
              <span>源码</span>
            </div>
            <!--需要加东西 判断-->
          </div>
        </div>
        <div class="btn-class all-task" @click="bindOpenStep" v-if="drawer === false">
          <img src="../../assets/img/l-point.png" alt="" />
          <!-- <span class="point-close-but">
                      <img src="../../assets/img/l-point.png" alt="">
                      </span> -->
          <span>全部任务</span>
        </div>
      </div>
    </div>
    <!-- <span v-if="stepDataState"> -->
    <div class="video-class">
      <div class="pageccc" v-html="htmlStr"></div>

      <div class="control">
        <div>
          <span class="progress"></span>
        </div>
      </div>

      <div class="video-but" style="display: none">
        <button id="one">播放</button>
        <button id="two">暂停</button>
        <button id="three">快进</button>
        <button id="four">后退</button>
      </div>
    </div>
    <!-- </span> -->

    <span v-if="initState.htmlType">
      <div class="play-btn" @click="play()" v-if="initState.playBtnState"
        :class="{ openPlayBtnClass: drawer === true }">
        <img src="../../assets/img/play-icon.png" alt="" />
      </div>

      <div class="play-btn" @click="pause()" v-else-if="initState.timeOutButState"
        :class="{ openPlayBtnClass: drawer === true }">
        <img src="../../assets/img/Time-out.png" alt="" />
      </div>
    </span>

    <!-- v-show="initState.footerState == false" -->
    <div class="video-footer" :style="{ width: headerFooter.width }" v-show="initState.footerState == true"
      @mouseleave="footerLeave">
      <!-- <div class="Play-progress-bar">
              <input
                v-model="playVolume"
                type="range"
                min="0"
                max="100"
                id="playVolume"
                :style="`background-size: ${playVolume}% 100%;`"
              />
            </div> -->
      <div class="Play-progress-bar" v-show="initState.htmlType">
        <el-slider v-model="playVolume" :show-tooltip="false" id="playVolume" style="position: relative; z-index: 0"
          change="playColumeChange1"></el-slider>

        <el-slider style="margin-top: -38px; opacity: 0; position: relative; z-index: 1" v-model="playVolume1"
          :show-tooltip="false" id="playVolume" @change="playColumeChange1"></el-slider>
      </div>
      <div class="footer-left" v-show="initState.htmlType == false"></div>
      <div class="footer-left" v-show="initState.htmlType">
        <img src="../../assets/img/lb-icon.png" class="lb-icon" alt="" />
        <input v-model="videoVolume" type="range" min="0" max="100" id="ranVolume"
          :style="`background-size: ${videoVolume}% 100%;`" />
        <div class="timer">
          <span class="progress_timer">00:00:00</span>/
          <span class="duration_timer">00:00:00</span>
        </div>
      </div>
      <div class="footer-center">
        <div class="left-but" v-if="initState.upTitle != ''">
          <div class="border-class" @click="handleUpBut()">
            <div class="step-num">上一页</div>
            <div class="step-name">{{ initState.upTitle }}</div>
          </div>
        </div>
        <div class="left-but" style="background: #ffffff" v-else></div>
        <div class="center-but">
          <div class="border-class">
            <div class="step-num">
              <span class="num1">{{ initState.stepIndex + 1 }}/</span>
              <span class="num2">{{ stepData.length }}</span>
            </div>
            <div class="step-name">{{ initState.stepTitle }}</div>
          </div>
        </div>
        <div class="right-but" v-if="initState.nextTitle != ''">
          <div class="border-class" @click="handleDownBut()">
            <div class="step-num">下一页</div>
            <div class="step-name">{{ initState.nextTitle }}</div>
          </div>
        </div>
        <div class="right-but" style="background: #ffffff" v-else></div>
      </div>
      <div class="footer-right" v-show="initState.htmlType == false"></div>
      <div class="footer-right" v-show="initState.htmlType">
        <div class="back-off" @click="bindBackOff">后退十秒</div>

        <el-popover placement="bottom" trigger="hover" popper-class="popperClass">
          <ul class="extraCodeList">
            <li v-for="(item, index) in speedData" :key="index" @click="bindSpeed(item)">
              {{ item }}
            </li>
          </ul>
          <template #reference>
            <div class="multiple">{{ speed }}X</div>
          </template>
        </el-popover>
      </div>
    </div>

    <!-- <el-drawer
          :visible.sync="drawer"
          :direction="direction"
          :with-header="false"
          :modal="false"
        >
          <span>我来啦!</span>
        </el-drawer> -->
    <div class="right-drawer" v-if="drawer">
      <div class="close-but" @click="bindCloseStep">
        <!-- <span class="point-close-but"><i class="el-icon-arrow-left"></i></span> -->
        <img src="../../assets/img/qp.icon.png" alt="" />
      </div>

      <div class="title-list">
        <div class="title-item" v-for="(item, index) in stepData" :key="index"
          :class="{ 'step-active': initState.stepIndex === index }">
          <div class="round-box">
            <span class="line-class" v-if="stepData.length !== index + 1"></span>
            <img src="../../assets/img/snip-icon.png" alt="" class="snip-class" v-if="initState.stepIndex === index" />
          </div>
          <div class="name-box" @click="handleStep(item, index)">
            <div class="step">{{ index + 1 }}</div>
            <div class="name">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="header-no"></div>
    <VideoAlert v-if="fullVideoState" :fullVideoData="fullVideoData"></VideoAlert>
  </div>
</template>


<script>
// window.onload = function () {

function formatTime(t) {
  var h = parseInt(t / 3600);
  h = h < 10 ? "0" + h : h;
  var m = parseInt((t % 3600) / 60);
  m = m < 10 ? "0" + m : m;
  var s = parseInt(t % 60);
  s = s < 10 ? "0" + s : s;
  return h + ":" + m + ":" + s;
}

// 播放
// };
import { loadJs } from "../../assets/js/loadVideo";
import LessonVideo from "../../models/Home/LessonVideo";
import axios from "axios";
import VideoAlert from "../../components/video/VideoAlert";
import websocket from "../../utils/websocket";
import Qs from "qs";
import UnloadClass from "../../models/Home/UnloadClass";
export default {
  components: {
    VideoAlert,
  },
  data() {
    return {
      stepDataState: false,
      stepData: [
        {
          name: "教案1",
        },
        {
          name: "教案2",
        },
        {
          name: "教案3",
        },
      ],
      drawer: true,
      direction: "rtl",
      initState: {
        playBtnState: true,
        htmlType: false, //img 图片类型， veido 视频类型
        timeOutButState: false,
        mousemoveNum: 0,
        footerState: true,
        headerState: true,
        stepIndex: 0,
        upTitle: "",
        stepTitle: "",
        nextTitle: "",
        Time: "",
      },
      headerFooter: {
        width: "100%",
      },
      playBtn: "./img/play-btn.png",
      speedData: [0.2, 0.5, 0.7, 1, 1.5, 2],
      speed: 1, //播放速度
      playVolume: 0,
      playVolume1: 0,
      videoVolume: 50,
      htmlStr: "",
      // '<div style="position: relative;"><img class="theImg" style="width:100%;" src="https://storage.shubenji.cn/GuoBao/video/%E8%80%83%E7%BA%A7%E8%AF%BE%E7%A8%8B/%E4%B8%80%E7%BA%A7%E5%9F%BA%E7%A1%80/%E7%94%B5%E5%AD%90%E7%9B%B8%E5%86%8C/%E7%94%B5%E5%AD%90%E7%9B%B8%E5%86%8C_01.png?OSSAccessKeyId=LTAI4Fdpcm5Gwue9tEaZfiGS&Expires=1649314816&Signature=yWXmDjF4tZJcJeFmQvzGM960yKw%3D" alt=""><p class="textCon" style="font-size:64%;position: absolute; bottom: 8%;right: 10%;font-weight: bold;"></p></div>',
      // '<div><video controls class="theVideo" style="width:100%;" src="https://muiplayer.oss-cn-shanghai.aliyuncs.com/static/media/media.mp4" poster=" " alt=""></div>',
      fullscreen: false,
      videoTotalTime: 0, //视频总时长
      videoNewPlayTime: 0,
      videoDrapState: false, //拖拽状态
      // newVideoPlayTime: 0,//拖动后的赋值
      lessonInfo: undefined,
      listState: false,
      fullVideoState: false,
      fullVideoData: {},
      controllBtnState: true, //按钮控制
      presentT: 0,
      RawCodeState: false,
      allVideoState: false,
      courseType: "",

      studentAiPackList: [],

      openClassState: true, //老师打开控制学生课程页面播放状态
    };
  },
  inject: ["conn_id", "isClassStateFun"],
  computed: {
    theConnId() {
      return this.conn_id();
    },
    theIsClassStateFun() {
      return this.isClassStateFun();
    },
  },
  watch: {
    theConnId() {
      console.log("conn_id改变");
    },
    videoVolume(newVlaue, oldValue) {
      this.videoVolume = newVlaue;
      var video = document.getElementsByTagName("video");
      for (let i = 0; i < video.length; i++) {
        video[i].volume = newVlaue / 100;
      }
    },
    theIsClassStateFun(newValue) {
      if (newValue === true) {
        this.pause();

        var audio = document.getElementsByTagName("audio");
        for (let i = 0; i < audio.length; i++) {
          audio[i].pause();
        }
      }
      console.log(newValue);
      console.log("isClass在改变");
    },
  },
  async created() {
    console.log(this.isClassState);
    if (localStorage.getItem("info") !== null) {
      // 服务端拿的 token 可以从 vuex、localStorage 等地方取
      axios.defaults.headers["token"] = JSON.parse(
        localStorage.getItem("info")
      ).token;
    } else {
      axios.defaults.headers["token"] = ``;
    }
    this.bindOpenStep();

    if (this.$store.getters.getUserData.role === 1) {
      await this.getLessonByInfo();
    } else {
      await this.getStudentLessonList();
      this.realStudentStudyTime();
      this.getRawCode();
      this.getListCourseCartoonAsset();
    }
    const res = await this.getListStepData()
    const studentData = res.filter((it) => it.visible_type !== "only_teacher" && it.title.indexOf("教案") === -1);
    this.stepData = studentData;

    this.initState.upTitle = "";
    this.initState.stepTitle = studentData[0].title;
    if (studentData[1]) {
      this.initState.nextTitle = studentData[1].title;
    }

    await this.videoDmoSave(studentData);

    this.handleStep(studentData[0], 0);
    //激活课时  注意
    if (this.$store.getters.getUserData.role === 2) {
      this.getActivateLesson();
    }

    this.getCourseType();
    this.keyDown();

  },
  mounted() {
    this.controllBtnState =
      localStorage.getItem("covertState") === "close" ? false : true;
    this.openClassState =
      localStorage.getItem("isClassState") === "close" ? false : true;

    let _this = this;
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem("info") === null) {
        _this.$Dialog
          .confirm({
            message: "请重新登录",
            confirmButtonColor: "#FF8D15",
            showCancelButton: false,
          })
          .then(() => {
            window.location.href = "/";
          })
          .catch(() => {
            // on cancel
          });
      }
      if (e.key === "info") {
        if (e.newValue === null) {
          _this.$Dialog
            .confirm({
              message: "请重新登录",
              confirmButtonColor: "#FF8D15",
              showCancelButton: false,
            })
            .then(() => {
              window.location.href = "/";
            })
            .catch(() => {
              // on cancel
            });
        }
      } else if (e.key === "unloadGroupData") {
        if (e.newValue === null || e.newValue === "0") {
          _this.$Dialog
            .confirm({
              message: "请先解锁课程",
              confirmButtonColor: "#FF8D15",
              showCancelButton: false,
              showConfirmButton: false,
            })
            .then(() => {
              window.location.href = "/";
            })
            .catch(() => {
              // on cancel
            });
        }
      } else if (e.key === "covertState") {
        _this.controllBtnState =
          localStorage.getItem("covertState") === "close" ? false : true;
      }
    });
    this.screenWidth = document.body.clientWidth;
    this.screenHeight = document.body.clientHeight;
  },

  methods: {
    getListCourseCartoonAsset() {
      LessonVideo.listCourseCartoonAsset(
        { course_id: this.$route.query.course_id },
        ({ type, list }) => {
          console.log(list);
          this.studentAiPackList = list;
        }
      );
    },
    /***老师点击教案页面关闭 */
    handleOpenClass() {
      this.openClassState = !this.openClassState;
      this.pause();
      websocket.Send({
        // token: JSON.parse(localStorage.getItem('info')).token,
        token: this.$store.getters.getUserData.token,
        conn_id: this.theConnId,
        data: "openClass",
        group_id: this.$store.getters.getGroupData.group_id,
        action: "flip",
      });
    },
    /**老师端学生源码跳转*/
    handleStuRowHref(lesson_id, rec_id, courseType) {
      if (courseType === "scratch") {
        window.open(
          "/gbscratch/?rawLessonId=" + lesson_id + "&recUrlId=" + rec_id
        );
      } else if (courseType === "guodong") {
        window.open(`/guobaojr/?rawLessonId=${lesson_id}&recUrlId=${rec_id}`);
      } else {
        window.open(`/jr/?rawLessonId=${lesson_id}&recUrlId=${rec_id}`);
      }
    },
    /**打开原版 */
    handleOriginSoft(courseType) {
      if (courseType === "scratch") {
        window.open("/gbscratch/");
      } else if (courseType === "guodong") {
        window.open(`/guobaojr/`);
      } else {
        window.open(`/jr/`);
      }
    },
    getCourseType() {
      LessonVideo.getAdminListCourse(
        { course_id: this.$route.query.course_id },
        ({ type, list }) => {
          if (type === 200) {
            console.log(type, list);
            if (list.list.length > 0) {
              console.log(list.list);
              this.courseType = list.list[0].type;
            }
          }
        }
      );
    },
    //学生端初始化
    getRawCode() {
      LessonVideo.getListEduLessonRawCode(
        { edu_lesson_id: this.$route.query.lesson_id },
        ({ type, list }) => {
          if (type === 200) {
            if (list.length > 0) {
              this.RawCodeState = true;
            }
          }
        }
      );
    },
    realStudentStudyTime() {
      const request = Qs.stringify({
        group_id: this.$store.getters.getGroupData.group_id,
        lesson_id: this.$route.query.lesson_id,
        duration: 1,
        course_id: this.$store.getters.getCourseData[0].id,
      });
      setInterval(function () {
        LessonVideo.updateDuration(request, ({ type, list }) => {
          if (type === 200) {
          }
        });
      }, 60000);
    },
    teachPlanTextClick() {
      if (this.$route.query.text === null) {
        this.$message({
          message: "暂无文字",
          type: "error",
          iconClass: "iconClass",
        });
      } else {
        window.open(
          this.$fileName + "/#/LessonText?textData=" + this.$route.query.text,
          "_blank"
        );
      }
    },
    bindVmClick() {
      if (this.drawer == false) {
        this.initState.headerState = !this.initState.headerState;
        this.initState.footerState = !this.initState.footerState;
      }
    },
    teachPlanClick(act_id) {
      window.open(this.$fileName + "/#/lessonPlan?act_id=" + act_id);
    },
    getActivateLesson() {
      let group_id = 0;
      if (this.$route.query.unlock != undefined) {
        group_id = this.$route.query.unlock_group_id;
      } else {
        group_id = this.$route.query.group_id;
      }
      const params = {
        group_id: group_id,
        lesson_id: this.$route.query.lesson_id,
      };

      LessonVideo.getActivateLesson(params, ({ type, list }) => {
        if (type === 200) {
          if (this.$route.query.unlock != undefined) {
            setTimeout(() => {
              UnloadClass.getListUnlockedLessonNum({}, ({ type, list }) => {
                if (type === 200) {
                  localStorage.setItem("unloadGroupData", list.list.lesson_num);
                }
              });
            }, 1000);
          } else {
            setTimeout(() => {
              UnloadClass.getListUnlockedLessonNum({}, ({ type, list }) => {
                if (type === 200) {
                  localStorage.setItem("unloadGroupData", list.list.lesson_num);
                }
              });
            }, 1000);
          }
        }
      });
    },
    handleControll() {
      this.controllBtnState = !this.controllBtnState;
      websocket.Send({
        // token: JSON.parse(localStorage.getItem('info')).token,
        token: this.$store.getters.getUserData.token,
        conn_id: this.theConnId,
        data: "",
        group_id: this.$store.getters.getGroupData.group_id,
        action: "flip",
      });
    },
    fullVideoClick() {
      // this.$emit('videoData', this.fullVideoData);
      this.fullVideoState = this.fullVideoState === false;
    },
    quanping() {
      let element = document.documentElement;
      console.log(element.requestFullscreen);
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        // IE11
        element.msRequestFullscreen();
      }
      // this.fullscreen = !this.fullscreen;
    },
    mouseenter() {
      // if (this.initState.timeOutButState === true) {
      clearTimeout(this.Time);
      // }
    },
    //如果打开弹窗 页面头部底部不隐藏
    mousemove(event) {
      if (this.initState.playBtnState === false) {
        if (this.initState.timeOutButState === false) {
          let cc = setTimeout(() => {
            this.initState.timeOutButState = false;
          }, 2000);
        }
        this.initState.timeOutButState = true;
      }

      if (this.drawer === false) {
        //头部底部高度
        let footerHeaderHeight =
          document.getElementsByClassName("header-no")[0].clientHeight;
        if (
          event.offsetY < footerHeaderHeight &&
          event.offsetX <= document.body.clientWidth
        ) {
          //滑倒顶部放出顶部
          this.initState.headerState = true;
          this.initState.footerState = true;
        } else {
          // this.initState.headerState = false;
        }
        if (
          event.offsetY >= document.body.clientHeight - footerHeaderHeight &&
          event.offsetX <= document.body.clientWidth
        ) {
          //滑倒顶部放出顶部
          this.initState.headerState = true;
          this.initState.footerState = true;
        }
        if (this.initState.footerState === false) {
        }

        if (this.initState.headerState === false) {
        }
      }
    },
    //移动头部
    headerLeave() {
      if (this.drawer === false) {
        this.initState.headerState = false;
        this.initState.footerState = false;
      }
    },
    footerLeave() {
      if (this.drawer === false) {
        this.initState.headerState = false;
        this.initState.footerState = false;
      }
    },
    menuHidden() {
      clearTimeout(this.Time);
      this.initState.footerState = true;
    },
    //打开弹窗 需要头部跟底部
    async bindOpenStep() {
      clearTimeout(this.Time);
      this.initState.footerState = true;
      this.initState.headerState = true;

      return this.$nextTick().then(() => {
        this.drawer = true;
        this.headerFooter.width = "calc(100vw - 1.62rem)";
        var video = document.getElementsByTagName("video");
        if (video.length != 0) {
          for (let i = 0; i < video.length; i++) {
            video[i].style.width = "calc(100vw - 1.62rem)";
            video[i].style.left = "4.2rem";
            video[i].style.height = "calc(100vh - 0.9rem)";
            video[i].style.marginLeft = "-2px";
          }
        }

        var img = document.getElementsByClassName("theImg");
        if (img.length != 0) {
          for (let i = 0; i < img.length; i++) {
            img[i].style.width = "auto";
            img[i].style.left = "4.2rem";
            img[i].style.height = "calc(100vh - 0.9rem)";
            img[i].style.marginLeft = "- 2px";
          }
        }
      });
    },
    //关闭弹窗
    bindCloseStep() {
      // this.initState.footerState = true;
      // if (this.initState.footerState === true) {
      // this.Time = setTimeout(() => {
      this.initState.footerState = false;
      this.initState.headerState = false;
      // clearTimeout(this.Time);
      // }, 2500);

      this.drawer = false;
      this.headerFooter.width = "100%";
      var video = document.getElementsByTagName("video");
      for (let i = 0; i < video.length; i++) {
        video[i].style.width = "100%";
        video[i].style.left = "50%";
        video[i].style.height = "calc(100vh - 32px)";
        video[i].style.marginLeft = "0px";
      }

      var img = document.getElementsByClassName("theImg");

      for (let i = 0; i < img.length; i++) {
        img[i].style.width = "auto";
        img[i].style.left = "50%";
        img[i].style.height = "calc(100vh - 32px)";
        img[i].style.marginLeft = "0px";
      }
    },
    async handleStep(item, index) {
      console.debug("load step", index, item)
      this.htmlStr = item.htmlContent;
      this.initState.stepIndex = index;

      if (this.initState.stepIndex == "0") {
        this.initState.upTitle = "";
      } else {
        this.initState.upTitle =
          this.stepData[this.initState.stepIndex - 1].title;
      }

      this.initState.stepTitle = item.title;

      if (this.initState.stepIndex + 1 == this.stepData.length) {
        this.initState.nextTitle = "";
      } else {
        this.initState.nextTitle =
          this.stepData[this.initState.stepIndex + 1].title;
      }

      this.initState.playBtnState = true;
      if (item.htmlType === "video") {
        this.initState.htmlType = true;
      }

      if (item.htmlType === "img") {
        this.initState.htmlType = false;
      }

      this.videoDrapState = true;
      this.videoNewPlayTime = 0;

      await this.videoDmoSave();
      await this.bindOpenStep();

      if (item.js_url) loadJs(item.js_url);
    },
    formatTime(t) {
      var h = parseInt(t / 3600);
      h = h < 10 ? "0" + h : h;
      var m = parseInt((t % 3600) / 60);
      m = m < 10 ? "0" + m : m;
      var s = parseInt(t % 60);
      s = s < 10 ? "0" + s : s;
      return h + ":" + m + ":" + s;
    },
    //倍数选择
    bindSpeed(speed) {
      this.speed = speed;
      var video = document.getElementsByTagName("video");
      for (let i = 0; i < video.length; i++) {
        video[i].playbackRate = speed;
      }
    },
    //后退十秒
    bindBackOff() {
      var video = document.getElementsByTagName("video");
      for (let i = 0; i < video.length; i++) {
        video[i].currentTime -= 10;
      }
    },
    //开始播放
    play() {
      var video = document.getElementsByTagName("video");
      for (let i = 0; i < video.length; i++) {
        video[i].play();
        video[i].currentTime = this.videoNewPlayTime;
      }
      this.initState.playBtnState = false;
      this.videoDrapState = false;
      // this.bindCloseStep();

      // this.initState.footerState = false;
      // this.initState.headerState = false;
      // this.Time = setTimeout(() => {
      //   this.timeOutButState = false;
      // }, 2000);
    },
    //暂停播放
    pause() {
      var video = document.getElementsByTagName("video");
      this.videoNewPlayTime = this.videoNewPlayTime;
      for (let i = 0; i < video.length; i++) {
        video[i].pause();
      }
      this.initState.playBtnState = true;
      this.initState.timeOutButState = false;
    },
    //右侧数据
    async getListStepData() {
      const lesson_id = this.$route.query.lesson_id;
      return new Promise((res) => {
        LessonVideo.getListLessonStep(
          { lesson_id: lesson_id, page: 1, limit: 999 },
          ({ type, list }) => {
            if (type === 200) {
              const ps = list.list.map(async (item) => {
                const info = (await this.getListLessonTemConInfo(item))[0]
                if (info) {
                  item.content = info.content
                  if (item.content.includes("video controls")) {
                    item.htmlType = "video"
                  }
                  if (item.content.includes("img class=")) {
                    item.htmlType = "img"
                  }
                  item.js_url = info.js_url;
                  item.htmlContent = info.content;
                }
                return item
              })
              Promise.all(ps).then(res)
            }
          }
        );
      })
    },
    async videoDmoSave() {
      // 视频
      const _this = this;
      return this.$nextTick().then(() => {
        var img = document.getElementsByClassName("theImg");
        for (let i = 0; i < img.length; i++) {
          if (this.drawer === true) {
            img[i].style.cssText =
              "width: auto;height: calc(100vh - 0.9rem);position: absolute;top: 50%;left: 4.2rem;transform: translate(-50%, -50%);border-radius: 20px;";
          } else {
            img[i].style.cssText =
              "width: auto;height:calc(100vh - 32px);position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);border-radius: 20px;";
          }

          let parents = img[i].parentNode;
          parents.style.position = "unset";
        }

        var video = document.getElementsByTagName("video");
        var progressTimer = document.querySelector(".progress_timer");
        var durationTimer = document.querySelector(".duration_timer");
        var progress = document.querySelector(".progress");
        let { totalT, presentT } = { totalT: 0, presentT: 0 };
        //获取视频总时间
        for (let i = 0; i < video.length; i++) {
          video[i].addEventListener("volumechange", function () {
            var volume = video[i].volume; //获取当前音量
          });

          //  video[i].currentTime = 5; //从几秒开始播放

          video[i].addEventListener("canplay", function () {
            _this.videoTotalTime = this.duration; //赋值 时长
            totalT = this.duration;
            var videoDuration = formatTime(totalT);
            durationTimer.innerHTML = videoDuration;
          });

          //获取视频当前播放的时间
          video[i].addEventListener("timeupdate", function () {
            if (_this.videoDrapState === true) {
              presentT = _this.videoNewPlayTime;
            } else {
              presentT = this.currentTime;
            }
            _this.videoNewPlayTime = presentT;
            _this.presentT = presentT;

            var videoCurrent = formatTime(presentT);
            progressTimer.innerHTML = videoCurrent;
            // 进度条
            _this.playVolume = (100 / totalT) * presentT;
            if (durationTimer.innerHTML === progressTimer.innerHTML) {
              _this.initState.playBtnState = true;
              _this.videoNewPlayTime = 0;
            }
            // this.currentTime = 15;
          });
          document.onkeydown = function (e) {
            //对整个页面监听
            var keyNum = window.event ? e.keyCode : e.which; //获取被按下的键值
            //判断如果用户按下了空格键(keycode=32)，
            if (keyNum == 32) {
              if (_this.initState.playBtnState) {
                video[i].play();
                _this.initState.timeOutButState = true;
                _this.initState.playBtnState = false;
              } else {
                video[i].pause();
                _this.initState.playBtnState = true;
                _this.initState.timeOutButState = false;
              }

              _this.videoDrapState = false;
              // alert('您按下了空格');
            }
          };
          document.getElementById("one").onclick = function () {
            video[i].play();
          };
          // 暂停
          document.getElementById("two").onclick = function () {
            video[i].pause();
          };
          // 快进
          document.getElementById("three").onclick = function () {
            video[i].currentTime += 25 / 5;
          };
          // 后退
          document.getElementById("four").onclick = function () {
            video[i].currentTime -= 25 / 5;
          };

          video[i].volume = this.videoVolume / 100;
          video[i].playbackRate = this.speed;
          video[i].style.cssText =
            "width: 100%;height:calc(100vh - 32px);position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);border-radius: 20px;";
          video[i].controls = false;
        }
        console.debug("dom saved")
      });
    },
    async getListLessonTemConInfo(item) {
      return new Promise((res, rej) => {
        LessonVideo.getListLessonTemCon(
          { step_id: item.id },
          ({ type, list }) => {
            if (type === 200) {
              res(list);
            } else {
              rej(type)
            }
          }
        );
      })
    },
    playColumeChange1() {
      this.videoNewPlayTime = (this.videoTotalTime / 100) * this.playVolume1;
      this.videoDrapState = true;
      this.play();
    },
    async getLessonByInfo() {
      let params = {
        course_id: this.$route.query.course_id,
        group_id: this.$route.query.group_id,
        lesson_id: this.$route.query.lesson_id,
      };
      this.loading = true;
      return new Promise((res) => {
        LessonVideo.getLessonList(params, ({ type, list }) => {
          if (type === 200) {
            this.lessonInfo = list.list[0];
            console.log(this.lessonInfo);
            if (this.lessonInfo.video_url !== undefined) {
              const video_url = this.lessonInfo.video_url;
              const video_img = this.lessonInfo.video_image;

              this.fullVideoData = { video_url: video_url, video_img: video_img };
              this.allVideoState = true;
            } else {
              this.allVideoState = false;
            }

            this.listState = true;
          }
          res()
        });
      })
    },
    async getStudentLessonList() {
      let group_id = 0;
      if (this.$route.query.unlock != undefined) {
        group_id = this.$route.query.unlock_group_id;
      } else {
        group_id = this.$route.query.group_id;
      }
      let params = {
        course_id: this.$route.query.course_id,
        group_id: group_id,
        lesson_id: this.$route.query.lesson_id,
        goods_category: localStorage.getItem("goodsCategory"),
      };
      this.loading = true;
      return new Promise((res) => {
        LessonVideo.getStudentLessonList(params, ({ type, list }) => {
          if (type === 200) {
            this.lessonInfo = list.list[0];
            console.log(this.lessonInfo);
            let video_url;
            let video_img;
            if (
              this.lessonInfo.video_url !== "" ||
              this.lessonInfo.video_url !== undefined
            ) {
              video_url = this.lessonInfo.video_url;
              video_img = this.lessonInfo.video_image;
              this.fullVideoData = { video_url: video_url, video_img: video_img };
            }

            if (this.lessonInfo.video_url !== undefined) {
              this.allVideoState = true;
            } else {
              this.allVideoState = false;
            }
            this.listState = true;
          }
          res(list)
        });
      })

    },
    studentHrefScratch(courseType, lesson_id) {
      console.log(lesson_id);
      if (courseType === "scratch") {
        if (this.RawCodeState) {
          window.open("/gbscratch/?stuLessonId=" + this.$route.query.lesson_id);
        } else {
          window.open("/gbscratch/");
        }
      } else if (courseType === "guodong") {
        window.open(`/guobaojr/?stuLessonId=${lesson_id}&aa=1`);
      } else {
        window.open(`/jr/?stuLessonId=${lesson_id}`);
      }
    },
    teacherHrefScratch(lesson_id, rec_id, courseType) {
      if (courseType === "scratch") {
        window.open(
          "/gbscratch/?loadUrlId=" + lesson_id + "&recUrlId=" + rec_id
        );
      } else if (courseType === "guodong") {
        window.open(`/guobaojr/?loadUrlId=${lesson_id}&recUrlId=${rec_id}`);
      } else {
        window.open(`/jr/?loadUrlId=${lesson_id}&recUrlId=${rec_id}`);
      }
    },
    handleUpBut() {
      if (this.stepData.length >= 1 && this.initState.stepIndex > 0) {
        this.initState.stepIndex--;
        let item = this.stepData[this.initState.stepIndex];

        this.htmlStr = item.htmlContent;
        this.initState.stepIndex = this.initState.stepIndex;

        if (this.initState.stepIndex - 1 == "-1") {
          this.initState.upTitle = "";
        } else {
          this.initState.upTitle =
            this.stepData[this.initState.stepIndex - 1].title;
        }

        // this.initState.upTitle =
        //   this.stepData[this.initState.stepIndex - 1].title;

        this.initState.stepTitle =
          this.stepData[this.initState.stepIndex].title;

        this.initState.nextTitle =
          this.stepData[this.initState.stepIndex + 1].title;

        this.initState.playBtnState = true;
        if (item.htmlType === "video") {
          this.initState.htmlType = true;
        }

        if (item.htmlType === "img") {
          this.initState.htmlType = false;
          this.initState.playBtnState = false;
          this.initState.timeOutButState = false;
        }

        this.videoDrapState = true;
        this.videoNewPlayTime = 0;

        this.videoDmoSave();
        this.bindCloseStep();

        loadJs(item.js_url);
      }
    },
    handleDownBut() {
      if (this.stepData.length - 1 > this.initState.stepIndex) {
        this.initState.stepIndex++;
        let item = this.stepData[this.initState.stepIndex];

        this.htmlStr = item.htmlContent;
        this.initState.stepIndex = this.initState.stepIndex;

        this.initState.upTitle =
          this.stepData[this.initState.stepIndex - 1].title;

        this.initState.stepTitle =
          this.stepData[this.initState.stepIndex].title;

        if (this.initState.stepIndex + 1 == this.stepData.length) {
          this.initState.nextTitle = "";
        } else {
          this.initState.nextTitle =
            this.stepData[this.initState.stepIndex + 1].title;
        }

        this.initState.playBtnState = true;
        if (item.htmlType === "video") {
          this.initState.htmlType = true;
        }

        if (item.htmlType === "img") {
          this.initState.htmlType = false;
        }

        this.videoDrapState = true;
        this.videoNewPlayTime = 0;

        this.videoDmoSave();
        this.bindCloseStep();

        loadJs(item.js_url);
      }
    },
    keyDown() {
      document.onkeydown = (e) => {
        //事件对象兼容
        let e1 =
          e || event || window.event || arguments.callee.caller.arguments[0];
        //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
        //左
        if (e1 && e1.keyCode == 38) {
          // 按下左箭头
          this.handleUpBut();
        } else if (e1 && e1.keyCode == 40) {
          // 向下滚动
          this.handleDownBut();
        }
      };
    },
  },
};
</script>
<style lang="less" scoped>
@import "./lessonDetail3.less";
</style>
