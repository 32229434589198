<template>
    <div class="logout">
        <div class="z-index-box"></div>
        <div class="orange-box">
            <div class="info">
                <div class="div1">
                    确定要退出吗？
                </div>
                <div class="div2">
                    <div class="course-btn btn1" @click="handleConfirm">
                        <div>确认</div>
                    </div>
                    <div class="course-btn btn1" @click="handleClose">
                        <div>取消</div>
                    </div>
                </div>
                <div class="div3">
                    <img src="https://storage.shubenji.cn/GuoBao/ipad/ip-12.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Logout",
    methods: {
        handleClose() {
            // this.state = false;
            this.$emit('update:visible', false)
        },
        handleConfirm(){
            this.$emit('update:visible', false)
            localStorage.clear();
            this.$store.commit('setClearState')
            this.$router.push({path:'/ipad/login'})
            // window.location.reload();
        }
    }
}
</script>

<style lang="less" scoped>
.logout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .z-index-box {
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3000);
        position: fixed;
        top: 0;
        left: 0;
    }

    .orange-box {
        width: 40rem;
        background: #ff921d;
        border-radius: 2rem;
        position: relative;
        z-index: 12;

        .info {
            //width: 46rem;
            margin: 1rem;
            //margin: 0 auto;
            background: #FFFFFF;
            border-radius: 2rem;
            padding: 2rem;
            position: relative;


            .div1 {
                padding: 2rem 0;
                text-align: center;
                font-size: 2.4rem;
                font-weight: bold;
                color: #FDA349;
            }

            .div2 {
                display: flex;
                margin: 1rem 0 2rem 0;
                justify-content: space-around;

                .course-btn{
                    border-radius: 1.8rem;

                    padding: 0.5rem 0.6rem;
                }

                .course-btn div{
                    padding: 0.5rem 2.4rem;
                    border-radius: 1.8rem;
                    font-size: 2rem;
                    font-weight: bold;
                    transition: 0.2s all;
                    position: relative;
                    text-align: center;
                }

                .btn1{
                    background: #FFFFFF;
                    box-shadow: 0 0.2rem 0.1rem 0 #FA9860;
                }
                .btn1 div{
                    background-image: linear-gradient(to right, #FEB169, #FB8000);
                    box-shadow: 0 0.3rem 0.9rem 0.1rem rgba(251, 135, 16, 0.45);
                    color: #FFFFFF;
                }

                .btn2{
                    background: #FFFFFF;
                    box-shadow: 0 0.2rem 0.1rem 0 #969594;
                }
                .btn2 div{
                    background-image: linear-gradient(to right, #ffffff, #ffffff);
                    box-shadow: 0 0.3rem 0.9rem 0.1rem rgba(161, 158, 156, 0.45);
                    color: #999999;
                }
            }

            .div3 {
                position: absolute;
                left: -7rem;
                bottom: -4rem;
                z-index: 1;

                img {
                    width: 12rem;
                }
            }
        }
    }
}
</style>
