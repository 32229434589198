<template>
    <div class="help-list">
        <div class="z-index-box"></div>
        <div class="orange-box">
            <div class="info">
                <div class="div0">
                    <div></div>
                    <div>帮助</div>
                    <div @click="handleClose">
                        <svg-icon icon-class="close-icon" class="close-icon-class"></svg-icon>
                    </div>
                </div>
                <div class="div1">
                    <ul>
                        <li v-for="(item,index) in list" :class="{'select-active':index === selectIndex}"
                            @click="handleSelect(index)">
                            <div>{{ index + 1 }}.</div>
                            <div>{{ item.name }}</div>
                            <div>
                                <svg-icon icon-class="play-icon" class="play-icon-class"></svg-icon>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="div2">
                    <div id="course-btn">
                        <div class="w-btn">
                            <div>播放</div>
                            <i>′</i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HelpList",
    data() {
        return {
            list: [
                {
                    name: '特色事项'
                }, {
                    name: '特色事项'
                }, {
                    name: '特色事项'
                }, {
                    name: '特色事项'
                }, {
                    name: '特色事项'
                }
            ],
            selectIndex: null
        }
    },
    methods: {
        handleClose() {
            // this.state = false;
            this.$emit('update:visible', false)
        },
        handleSelect(index) {
            this.selectIndex = index;
        }
    }
}
</script>

<style lang="less" scoped>
.help-list {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .z-index-box {
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3000);
        position: fixed;
        top: 0;
        left: 0;
    }

    .orange-box {
        width: 60rem;
        background: #ff921d;
        border-radius: 2rem;
        position: relative;
        z-index: 12;

        .info {
            //width: 46rem;
            margin: 2rem;
            //margin: 0 auto;
            background: #FFFFFF;
            border-radius: 2rem;
            padding: 2rem;

            .div0 {
                display: grid;
                grid-template-columns: 4rem auto 4rem;
                align-items: center;

                font-size: 2rem;
                font-weight: bold;
                color: #FDA349;

                div:nth-child(2) {
                    text-align: center;
                }

                div:nth-child(3) {
                    width: 4rem;
                    height: 4rem;
                    border: 0.1rem solid #FDA249;
                    border-radius: 100%;
                    text-align: center;

                    .close-icon-class {
                        margin-top: 0.5rem;
                        width: 3rem;
                        height: 3rem;
                        border-radius: 3rem;
                        background: #FC9D3E;
                        color: #FFFFFF !important;
                        box-shadow: 0 0.3rem 0.5rem 0.5rem rgba(251, 135, 16, 0.451);
                    }
                }
            }

            .div1 {
                .select-active {
                    background: #FDA349 !important;
                    color: #FFFFFF !important;

                    .play-icon-class {
                        color: #FFFFFF !important;
                    }
                }

                ul {
                    li {
                        margin-top: 2rem;
                        font-size: 1.8rem;
                        display: flex;
                        background: #EEEEEE;
                        padding: 1rem 2rem;
                        border-radius: 2rem;
                        align-items: center;
                        color: #999999;
                        font-weight: bold;

                        div:nth-child(3) {
                            margin-left: auto;

                            .play-icon-class {
                                width: 3rem;
                                height: 3rem;
                                color: #999999;
                            }
                        }
                    }
                }
            }

            .div2 {
                #course-btn {
                    width: 40%;
                    margin: 0 auto;
                    padding: 0.5rem;
                    display: flex;
                    justify-content: center;

                    .w-btn {
                        width: 100%;
                        background: #FFFFFF;
                        padding: 1rem;
                        border-radius: 3rem;
                        position: relative;
                        z-index: 1;
                        box-shadow: 0 0.42rem 0.03rem 0.001rem #FA9860;

                        div {
                            padding: 0.8rem 3rem;
                            flex: 0 0 100%;
                            text-align: center;
                            color: #FFFFFF;
                            font-size: 2rem;
                            border-radius: 3rem;
                            font-weight: bold;
                            background: linear-gradient(135deg, #FEB169 0%, #FB8000 100%);
                            box-shadow: 0 0.3rem 0.9rem 0.12rem rgba(251, 135, 16, 0.451);
                        }

                        i {
                            font-weight: bold;
                            font-size: 2rem;
                            position: absolute;
                            left: 2.5rem;
                            top: 1rem;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
}
</style>
