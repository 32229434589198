<template>
    <div class="history-comment-class">
        <van-list
            v-model="loading"
            :finished="finished"
            :offset="offset"
            @load="onLoad"
        >
            <div class="main-class" v-for="(item,index) in info" :key="index">
                <div class="box1-class">
                    <div class="work-img-class">
                        <!--                    <img :src="workImg" alt="">-->
                        <span v-if="item.work_image_url === '' || item.work_image_url === '0'">
                        <el-image v-if="item.class_name === '课后作业'"
                                  class="work-back-img" :src="worksImg1"
                                  fit="cover">
                        </el-image>
                        <el-image v-if="item.class_name === '课堂练习'"
                                  class="work-back-img" :src="worksImg2"
                                  fit="cover">
                        </el-image>
                        <el-image v-if="item.class_name === '自由编程'"
                                  class="work-back-img" :src="worksImg3"
                                  fit="cover">
                        </el-image>
                    </span>
                        <span v-else>
                        <el-image class="work-back-img" :src="item.work_image_url"
                                  fit="cover">
                        </el-image>
                    </span>
                    </div>
                </div>
                <div class="box2-class">
                    <div class="work-data-class">
                        <div class="name-class">{{ item.work_name }}</div>
                        <div class="tag-class">
                            <span>{{ item.class_name }}</span>
                        </div>
                    </div>
                    <div class="lesson-data-class">
                        <div class="lesson-name-class">{{ item.lesson_name }}</div>
                        <div class="time-box-class">{{
                                Number(item.issue_time) * 1000 | formatDate3
                            }}
                        </div>
                    </div>
                </div>
                <div class="box3-class">
                    <div class="grade-class" v-for="(val,key) in item.comment_options" :key="key">
                        <div class="title-class">{{ val.field_name }}</div>
                        <div class="sing-grade-class">
                            <van-rate
                                v-model="val.field_value"
                                :size="20"
                                color="#FF8300"
                                void-icon="star"
                                void-color="#c4c4c4"
                                touchable
                                readonly
                            />
                        </div>
                    </div>
                </div>
                <div class="box4-class"></div>
                <div class="box5-class">
                    <div class="comment-back-box-class" >
                        <div class="img-class" v-if="item.resImage[0]">
                            <img :src="item.resImage[0].url" alt="">
                        </div>
                        <div class="img-class" v-else>
                            <!--                        <img :src="workImg" alt="">-->
                        </div>
                        <div class="cover-box-class" @click="lookCommentImgClick(item.resImage)">
                            <div class="search-icon-class">
                                <el-image
                                    :src="searchIcon"
                                    fit="cover"></el-image>
                            </div>
                            <div>查看评论图片</div>
                        </div>
                    </div>

                    <div class="comment2-back-box-class">
                        <div class="img-class" v-if="item.resVideo[0]">
                            <video :src="item.resVideo[0].url"></video>
                        </div>
                        <div class="img-class" v-else>
                            <!--                        <img :src="defaultVideoImg" alt="">-->
                        </div>
                        <div class="cover-box-class" @click="lookCommentVideoClick(item.resVideo)">
                            <div class="bf-icon-class">
                                <el-image
                                    :src="bfIcon"
                                    fit="cover"></el-image>
                            </div>
                            <div>
                                播放评论视频
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box6-class">
                    <div class="content-box-class">
                        <div class="text-class">{{ item.comment }}</div>
                    </div>
                </div>
            </div>
        </van-list>
        <CommentImageAlert v-if="commentImgState" @lookCommentImgClick="lookCommentImgClick([1])"
                           :resultImg="resultImg"></CommentImageAlert>
        <CommentVideoAlert v-if="commentVideoState" @lookCommentVideoClick="lookCommentVideoClick([1])"
                           :resultVid="resultVid" class="video-Alert-class"></CommentVideoAlert>
    </div>
</template>

<script>
import Works from "../../models/Home/Works";
import {formatDate} from "../../assets/js/date.js";
import CommentImageAlert from "../../components/comment/CommentImageAlert";
import CommentVideoAlert from "../../components/comment/CommentVideoAlert";
import {Toast} from 'vant';

export default {
    props: ['classId'],
    name: "HistoryComment",
    components: {CommentImageAlert, CommentVideoAlert},
    data() {
        return {
            bfIcon: './img/bf4-icon.png',
            searchIcon: './img/search-icon.png',
            defaultVideoImg: './img/teacher-msg.jpg',
            workImg: './img/works-1.png',
            value: 4,
            // class_id: this.classId,
            page: 1,
            list: [],
            info: [],
            offset: 10,
            loading: false,
            finished: false,
            worksImg1: './img/works-1.png',
            worksImg2: './img/works-2.png',
            worksImg3: './img/works-3.png',
            flag: false,
            commentImgState: false,
            commentVideoState: false,
            resultImg: [],
            resultVid: [],

        }
    },
    created() {
        this.getWorksShow();
    },
    computed: {
        CourseData() {//监听store的数据
            return this.$store.getters.getCourseData
        }
    },
    watch: {
        classId() {
            this.page = 1;
            this.finished = false;
            this.loading = true;
            this.getWorksShow();
        },
        CourseData() {
            this.page = 1;
            this.finished = false;
            this.loading = true;
            this.getWorksShow();
        },
    },
    methods: {
        onLoad() {
            //上拉加载
            this.page++;
            this.getWorksShow();
        },
        lookCommentVideoClick(res) {
            if (res.length === 0) {
                Toast({
                    message: '没有评论视频',
                });
                return false;
            }
            this.commentVideoState = this.commentVideoState === false;
            this.resultVid = res;
        },
        lookCommentImgClick(res) {
            console.log(res)
            if (res.length === 0) {
                Toast({
                    message: '没有评论图片',
                });
                return false;
            }
            this.commentImgState = this.commentImgState === false;
            this.resultImg = res;
        },
        getWorksShow() {
            let param =
                {
                    class_id: this.classId,
                    group_id: this.$store.getters.getGroupData.group_id,
                    page: this.page,
                    student_id: this.$route.query.student_id,
                    limit: 8,
                }
            this.loading = true;
            Works.getWorksShow(param, ({type, list}) => {
                if (type === 200) {
                    this.flag = true;
                    let listLength = list.list.length;
                    let listData = list.list;
                    listData.forEach((item, index) => {
                        if (item.type === 'sb3') {
                            item.type_name = 'scratch';
                        } else if (item.type === 'python') {
                            item.type_name = 'python';
                        }
                        item.resImage = [];
                        item.resVideo = [];

                        Works.getListWorksResource({works_id: item.work_id}, ({type, list}) => {
                            if (type === 200) {
                                console.log(list)
                                let resImage = [];
                                let resVideo = [];
                                list.forEach((val, key) => {
                                    if (val.type === 'image') {
                                        resImage.push(val);
                                        this.$set(listData[index], 'resImage', resImage)
                                    }
                                    if (val.type === 'video') {
                                        resVideo.push(val);
                                        this.$set(listData[index], 'resVideo', resVideo)
                                    }
                                })
                                // item.resImage = resImage;
                                // item.resVideo = resVideo;
                                // this.flag = true;
                                console.log(listData);

                            }
                        })
                    });

                    // this.list = list.list;
                    if (this.page == 1) {
                        this.list = [];
                        list.list.forEach((element) => {
                            this.list.push(element);
                        });
                    } else {
                        list.list.forEach((element) => {
                            this.list.push(element);
                        });
                    }
                    this.info = this.list;
                    console.log(this.info);
                    // this.loading = false;
                    this.info = this.list;
                    if (list.list.length < 8) {
                        this.finished = true;
                    }
                    this.loading = false;
                    // this.ResourceInfo(this.info);
                }
            })


        },
        ResourceInfo(Data) {
            console.log(Data);
            Data.forEach((item, index) => {
                Works.getListWorksResource({works_id: item.works_id}, ({type, list}) => {
                    if (type === 200) {
                        let resImage = [];
                        let resVideo = [];
                        list.forEach((val, key) => {
                            if (val.type === 'image') {
                                resImage.push(val);
                            }
                            if (val.type === 'video') {
                                resVideo.push(val);
                            }
                        })
                        item.resImage = resImage;
                        item.resVideo = resVideo;
                        this.flag = true;
                    }
                })
            });
            console.log(Data);
            //     Works.getListWorksResource({works_id: item.works_id}, ({type, list}) => {
            //         if (type === 200) {
            //             console.log(list)
            //             let resImage = [];
            //             let resVideo = [];
            //             list.forEach((val, key) => {
            //                 if (val.type === 'image') {
            //                     resImage.push(val);
            //                 }
            //                 if (val.type === 'Video') {
            //                     resVideo.push(val);
            //                 }
            //             })
            //             item.resImage = resImage;
            //             item.resVideo = resVideo;
            //             console.log(Data);
            //         }
            //     })
            // })

        }
    },
    filters: {
        /*
    时间格式自定义 只需把字符串里面的改成自己所需的格式
   */
        formatDate(time) {
            var date = new Date(time);
            return formatDate(date, "yyyy.MM.dd");
        },
        formatDate2(time) {
            var date = new Date(time);
            return formatDate(date, "hh:mm:ss");
        },
        formatDate3(time) {
            var date = new Date(time);
            return formatDate(date, "yyyy.MM.dd. hh:mm");
        },
    },
}
</script>

<style lang="less" scoped>

.history-comment-class {

    position: relative;

    .main-class:not(:first-child) {
        margin-top: 20px;
    }

    .main-class {
        display: flex;
        height: 190px;
        width: 1240px;
        background: #FFFFFF;

        border-radius: 5px;
        align-items: center;

        .box1-class {
            width: 180px;
            height: 140px;
            margin: 20px 10px 30px 20px;

            .work-img-class {
                .work-back-img {
                    width: 180px;
                    height: 140px;
                    border-radius: 5px;
                }

                //img {
                //    width: 180px;
                //    height: 140px;
                //    border-radius: 5px;
                //}
            }
        }

        .box2-class {
            width: 180px;
            height: 140px;

            .work-data-class {
                padding-bottom: 20px;

                .name-class {
                    color: #333333;
                    font-size: 18px;
                    font-weight: bold;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .tag-class {
                    margin-top: 4px;

                    span {
                        font-size: 14px;
                        color: #A0A0A0;
                        padding: 2px 10px;
                        background: #F0F3F7;
                        border-radius: 10px 10px 10px 0px;
                    }

                }
            }

            .lesson-data-class {
                color: #c4c4c4;
                font-size: 14px;
                position: relative;

                .lesson-name-class {
                    width: 180px;
                    height: 50px;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .time-box-class {
                    width: 180px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

            }
        }

        .box3-class {
            .grade-class {
                display: flex;
                font-size: 12px;
                color: #999999;
                line-height: 26px;

                .title-class {
                    width: 100px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .sing-grade-class {
                    padding: 0 10px;
                }

                /deep/ .van-icon-star {
                    font-size: 20px !important;
                }
            }
        }

        .box4-class {
            height: 130px;
            border: 1px solid rgba(18, 18, 18, 0.10196078431372549);
        }

        .box5-class {
            margin: 0 10px;

            .comment-back-box-class{
                position: relative;
            }

            .comment2-back-box-class{
                position: relative;
                margin-top: 10px;
            }
            .img-class {
                width: 150px;
                height: 70px;

                img {
                    width: 150px;
                    height: 70px;
                    border-radius: 5px;
                }

                video {
                    width: 150px;
                    height: 70px;
                    border-radius: 5px;
                }
            }
            //.video-class{
            //    margin-top: 10px;
            //    img {
            //        width: 150px;
            //        height: 70px;
            //        border-radius: 5px;
            //    }
            //    video {
            //        width: 150px;
            //        height: 70px;
            //        border-radius: 5px;
            //    }
            //}

            .cover-box-class {
                width: 150px;
                height: 70px;
                border-radius: 5px;
                background: rgba(18, 18, 18, 0.25);
                position: absolute;
                top: 0;
                color: #FFFFFF;
                font-size: 12px;
                text-align: center;
                cursor: pointer;

                .search-icon-class{
                    width: 30px;
                    height: 30px;
                    margin: 5px auto;
                }
                .bf-icon-class{
                    width: 26px;
                    height: 26px;
                    margin: 5px auto;
                }
            }
        }

        .box6-class {
            margin-left: auto;
            margin-right: 20px;

            .content-box-class {
                width: 412px;
                height: 150px;
                border: 1px solid #FFC17F;
                border-radius: 10px;

                .text-class {
                    padding: 5px 10px;
                    font-size: 14px;
                    color: #666666;
                }
            }
        }
    }

    .video-Alert-class {
        position: absolute;
        top: 0;
    }
}
</style>
