<template>
    <div class="comment-class" ref="treeWrap" :class="{'close-transform-class':clickCloseButState === true}">
        <div class="comment-box-class">
            <el-form ref="commentData" :model="commentData">
                <div class="title-class">
                    <div class="text-title-class">评价</div>
                    <div class="grade-class">
                        <div class="grade-main-class" v-for="(item,index) in gradeData" :key="index">
                            <div class="grade-name-class">{{ item.name }}</div>
                            <div class="click-grade-class">
                                <van-rate
                                    v-model="item.value"
                                    :size="20"
                                    color="#FF8300"
                                    void-icon="star"
                                    void-color="#c4c4c4"
                                    touchable
                                    @change="onRateChange(item)"
                                />
                            </div>
                            <div class="hortative-text-class">{{ item.tip }}</div>
                        </div>
                    </div>
                    <div>
                        <el-image
                            class="comment-back-class"
                            :src="commentBackImg"
                            fit="cover"></el-image>
                    </div>
                    <div class="close-class" @click="closeClick"><i class="el-icon-close"></i></div>
                </div>
                <div class="award-box-class">
                    <span class="award-but-class" v-for="(item,index) in defaultTag" :key="index"
                          :class="{'active-tag':item.is_selected === 1}"
                          @click.stop="tagClick(item,index)">
                        {{ item.tag_name }}
                        <i
                            :class="{'delete-icon-i':item.is_selected === 1}"
                            v-if="item.is_selected === 1"
                            class="el-icon-close"
                            @click.stop="deleteTagClick(item)"
                        ></i>
                    </span>
                    <span class="icon-plus-but-class" :class="{'creatTagCss':creatTagState}" @click="creatTagClick"><i
                        class="el-icon-plus"></i></span>
                    <div class="creat-tag-class" ref="creatTagRef" v-if="creatTagState">
                        <div class="box1-class">
                            <div class="return-but-class" @click="creatTagClick"><i class="el-icon-arrow-left"></i>
                            </div>
                            <div class="creat-but-class" @click="createTagClick">创建</div>
                        </div>
                        <div class="box2-class">
                            <input type="text" placeholder="请输入你的标签" v-model="tag_name" ref="textCount">
                        </div>
                        <div class="box3-class">
                            {{ inputTextLength }}/6
                        </div>
                    </div>
                </div>
                <div class="textarea-class">
                    <el-form-item>
                        <el-input type="textarea" v-model="commentData.content"
                                  :class="{'active-class':commentData.content!== ''}"
                                  placeholder="填写您对学生这组作品的评价吧~"></el-input>
                    </el-form-item>
                </div>

                <div style="display: flex;justify-content: end;">
                    <div @click="handleTipComment(commentData.content)" class="tip-btn" >生成提示语</div>
                </div>
                <div class="upload-class">
                    <div class="upload-box-class">
                        <el-upload
                            class="avatar-uploader"
                            action=""
                            list-type="picture-card"
                            :file-list="studentWorkfileList"
                            :http-request="Uploadfile"
                            :on-preview="handlePictureCardPreview"
                            :before-upload="studentWorkBeforeUpload"
                            :on-remove="handleRemove">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </div>
                    <div class="upload-but-class" :class="{'active-but':commentData.content!== ''}" @click="onSubmit">
                        发表评论
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import Works from "../../models/Home/Works";
import Api from "../../models/Home/Api";
import CommentTag from "../../models/Comment/CommentTag";
import Upload from "../../models/Upload/UploadFile";
import Qs from 'qs';
import {Toast} from 'vant';
import tipComment from "../../utils/tipComment";

export default {
    props: ['commentData'],
    name: "commentAlert",
    data() {
        return {
            commentBackImg: './img/comment-background.png',
            dialogImageUrl: '',
            dialogVisible: false,
            form: {
                content: ''
            },
            // uploadUrl: '/resource/v1/uploadFile?token=' + JSON.parse(localStorage.getItem('info')).token,
            studentWorkfileList: [],
            clickCloseButState: false,
            creatTagState: false,
            tag_name: '',
            gradeData: [
                {
                    option: 'star_a',
                    name: '逻辑思维能力',
                    value: 0,
                    tip: ''
                }, {
                    option: 'star_b',
                    name: '动手搭建能力',
                    value: 0,
                    tip: ''
                }, {
                    option: 'star_c',
                    name: '创新创造思维',
                    value: 0,
                    tip: ''
                }, {
                    option: 'star_d',
                    name: '兴趣程度',
                    value: 0,
                    tip: ''
                }, {
                    option: 'star_e',
                    name: '语言表达能力',
                    value: 0,
                    tip: ''
                },
            ],
            defaultTag: [],
            inputTextLength: 0,
            DataState: false,// true,有数据   false没数据,
            defaultTagState: false,
            commentId: 0,
            initTag: [],//默认标签 跟新数据比较
            dataObj: {
                policy: '',
                signature: '',
                key: '',
                ossaccessKeyId: '',
                dir: '',
                host: ''
            },
        }
    },
    created() {
        //展示评论内容
        this.getListCommentInfo();
        // Upload.getUploadFileConfig({}, ({type, list}) => {
        //     if (type === 200) {
        //         // this.typeOptions = list.list;
        //         this.dataObj.policy = list.policy
        //         this.dataObj.signature = list.signature
        //         this.dataObj.ossaccessKeyId = list.accessid
        //         this.dataObj.key = list.dir + this.getUUID() + '_${filename}'
        //         this.dataObj.dir = list.dir
        //         this.dataObj.host = list.host
        //     }
        // })
    },
    mounted() {
        let _this = this;
        document.addEventListener('mouseup', (e) => {
            let tree = this.$refs.treeWrap
            if (tree) {
                if (!tree.contains(e.target)) {
                    _this.$emit('closeCommentAlert')
                    // this.isShowTree =false
                }
            }
        })
        document.addEventListener('mouseup', (e) => {
            let tree = this.$refs.creatTagRef
            if (tree) {
                if (!tree.contains(e.target)) {
                    this.creatTagState = false;
                    this.tag_name = '';
                    // this.isShowTree =false
                }
            }
        })

        // 显示作品文件框框时触发
        this.showStudentWorkList();
    },
    watch: {
        tag_name() {
            let _this = this;
            let _sum = 6;
            _this.inputTextLength = _this.$refs.textCount.value.length;
            if (_this.$refs.textCount.value.length > 6) {
                Toast({
                    message: '创建标签不得超过6个字',
                });
                // _this.$refs.textCount.value
                _this.tag_name = _this.tag_name.substring(0, 6);
                _this.inputTextLength = 6;
            }

            if (_this.inputTextLength >= 6) {
                _this.$refs.textCount.setAttribute("maxlength", _sum);
            }

        }
    },
    methods: {
        /**生成提示语 */
        handleTipComment(){
            const key = Math.floor(Math.random() * 50);
            this.commentData.content = tipComment[key];
        },
        getUUID() {
            return 'x-xxx-4xx-yxx-xxxx'.replace(/[xy]/g, c => {
                return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16);
            });
        },
        Uploadfile(param) {
            console.log(param)
            const loading = this.$loading({
                lock: true,
                text: '上传中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            loading.$el.style.fontSize = "30px"
            let file = param.file; // 得到文件的内容
            // 发送axios请求获取签名
            Upload.getUploadFileConfig({}, ({type, list}) => {
                if (type === 200) {
                    let ossUrl = list.host
                    let accessUrl = list.dir + this.getUUID() + file.name;//设置上传的访问路径
                    let sendData = new FormData();// 上传文件的data参数
                    sendData.append('ossaccessKeyId', list.accessid);
                    sendData.append('policy', list.policy);
                    sendData.append('signature', list.signature);
                    sendData.append('key', accessUrl);//上传的文件路径
                    sendData.append('success_action_status', 200); // 指定返回的状态码
                    sendData.append('file', file);
                    this.$http.post(ossUrl, sendData).then((res) => {
                        let numberValidateForm = accessUrl;//获得到的url需要将其存数据库中
                        const request = Qs.stringify({
                            resource_url: numberValidateForm
                        });
                        Upload.saveResource(request, ({type, list}) => {
                            if (type === 200) {

                                let url = '';
                                let type = '';
                                if (param.file.type.indexOf('video') > -1) {
                                    url = './img/teacher-msg.jpg';
                                    type = 'video';
                                } else {
                                    url = 'https://bookperiod.oss-cn-shanghai.aliyuncs.com/' + numberValidateForm;
                                    type = 'image';
                                }
                                this.studentWorkfileList.push({
                                    id: list.resource_id,
                                    name: 123,
                                    url: url,
                                    type: type,
                                    rec_id: 0
                                });
                                loading.close();
                            }
                        })
                    })
                }
            })
            // this.$http
            //     .get('/oos/policy')
            //     .then(response => {
            //         if (response.status === 200) {
            //
            //         }
            //     })
            //     .catch(function (error) { // 请求失败处理
            //         console.log(error)
            //     });
        },
        //展示评论内容
        getListCommentInfo() {
            CommentTag.getListComment({works_id: this.commentData.work_id}, ({type, list}) => {
                if (type === 200) {
                    if (list.id !== 0) {
                        this.DataState = true;
                        this.commentId = list.id;
                        this.commentData.content = list.comment;
                        list.comment_options.forEach((item, index) => {
                            this.gradeData.forEach((val, key) => {
                                if (item.option_name === val.option) {
                                    val.value = item.field_value;
                                }
                            })
                        })

                        //选中标签
                        if (list.comment_tags.length !== 0) {
                            this.defaultTagState = true;
                            list.comment_tags.forEach((item, index) => {
                                item.active = true
                            })
                            this.defaultTag = list.comment_tags;
                            this.initTag = list;
                        } else {
                            //展示评论默认标签
                            this.getRateTagInfo();
                        }

                        // list.comment_tags.forEach((item,index) =>{
                        //     this.defaultTag
                        // })

                    } else if (list.id === 0) {
                        this.DataState = false;
                        //展示评论默认标签
                        this.getRateTagInfo();
                    }
                }
            })
        },
        //删除标签
        deleteTagClick(item) {
            CommentTag.deleteCommentTag({tag_id: item.tag_id}, ({type, list}) => {
                if (type === 200) {
                    this.defaultTag.forEach((element, index) => {
                        if (element.tag_id === item.tag_id) {
                            this.defaultTag.splice(index, 1)
                        }
                    })
                }
            })
        },
        //点击标签
        tagClick(item, index) {
            if (item.is_selected === 1) {
                this.$set(item, 'is_selected', 0);//为item添加不存在的属性，需要使用vue提供的Vue.set( object, key, value )方法。
                this.editCommentTag(item.tag_id, 0, this.commentData.work_id, '取消标签');
            } else {
                this.$set(item, 'is_selected', 1);
                this.editCommentTag(item.tag_id, 1, this.commentData.work_id, '选中标签');
            }

            // if (item.active && this.DataState === true) {
            //     this.commentOneBindTag(this.commentId, item.tag_id);
            //     console.log(1)
            // } else if (item.active === false && this.DataState === true) {
            //     this.commentUnbindCommentTag(this.commentId, item.tag_id);
            //
            //     console.log(2)
            // }
            //
            // console.log(item)
        },
        //修改标签
        editCommentTag(tag_id, is_selected, works_id, tip) {
            CommentTag.editCommentTag({tag_id, is_selected, works_id}, ({type, list}) => {
                if (type === 200) {
                    Toast({
                        message: tip,
                    });
                }
            })
        },
        //展示评论默认标签
        getRateTagInfo() {
            if (this.defaultTagState === false) {
                CommentTag.getListCommentTags({works_id: this.commentData.work_id}, ({type, list}) => {
                    if (type === 200) {
                        list.forEach((item, index) => {
                            // item.active = true
                        })
                        this.defaultTag = list;
                    }
                })
            }
        },
        //创建新的标签
        createTagClick() {
            if (this.defaultTag.length >= 4) {
                Toast.setDefaultOptions({duration: 2000});
                Toast({
                    message: '当前的标签不得超过4个，创建先删除。',
                });
                return false;
            }
            const request = Qs.stringify({
                tag_name: this.tag_name,
                works_id: this.commentData.work_id
            });
            const _this = this;
            CommentTag.createCommentTag(request, function ({type, list}) {
                if (type === 200) {
                    _this.creatTagState = false;
                    _this.defaultTag.push({tag_id: list, tag_name: _this.tag_name});

                    // _this.getRateTagInfo();
                    _this.tag_name = '';
                }
            });
        },

        //选择星星打分
        onRateChange(item) {
            const tip = this.rateGrade(item.value);
            this.gradeData.forEach(element => {
                if (element.name === item.name) {
                    element.tip = tip;
                }
            })
        },
        rateGrade(num = 1) {
            let textContent = '很棒';
            switch (num) {
                case 1:
                    textContent = '加油';
                    break;
                case 2:
                    textContent = '再接再厉';
                    break;
                case 3:
                    textContent = '满意';
                    break;
                case 4:
                    textContent = '很棒';
                    break;
                case 5:
                    textContent = '非常棒';
                    break;
            }
            return textContent;
        },
        creatTagClick() {
            this.creatTagState = this.creatTagState === false;
        },
        onSubmit() {
            const _this = this;
            this.$Dialog.confirm(
                {
                    message: '确定提交吗？',
                    confirmButtonColor: '#FF8D15',
                })
                .then(() => {
                    this.studentWorkfileList.forEach(item => {
                        if (item.rec_id === 0) {
                            Api.fetchData(
                                {
                                    works_id: this.commentData.work_id,
                                    resource_id: item.id,
                                    type: item.type
                                },
                                '/bureau/v1.2/bindWorksResource',
                                ({type, list}) => {
                                })
                        }
                        if (item.deleteState != undefined && item.deleteState === true && item.rec_id !== 0) {
                            Api.fetchData(
                                {
                                    rec_id: item.rec_id,
                                },
                                '/bureau/v1.2/unbindWorksResource',
                                ({type, list}) => {
                                })
                        }
                    });

                    if (this.DataState === false) {
                        const request = Qs.stringify({
                            works_id: this.commentData.work_id,
                            comment: this.commentData.content,
                            star_a: this.gradeData[0].value,
                            star_b: this.gradeData[1].value,
                            star_c: this.gradeData[2].value,
                            star_d: this.gradeData[3].value,
                            star_e: this.gradeData[4].value,
                        });
                        Works.createComment(request, ({type, list}) => {
                            if (type === 200) {
                                const commentId = list;
                                const param = {
                                    work_id: this.commentData.work_id,
                                }
                                Works.getWorksQrCode(param, ({type, list}) => {
                                    if (type === 200) {
                                        // this.shareData = list;
                                    }
                                })
                            }
                        });
                    } else {
                        const request = Qs.stringify({
                            comment_id: this.commentId,
                            comment: this.commentData.content,
                            star_a: this.gradeData[0].value,
                            star_b: this.gradeData[1].value,
                            star_c: this.gradeData[2].value,
                            star_d: this.gradeData[3].value,
                            star_e: this.gradeData[4].value,
                        });
                        CommentTag.editComment(request, ({type, list}) => {
                            if (type === 200) {
                                // 如果有数据绑定标签被移除到了按钮上
                            }
                        });
                    }
                })
                .catch(() => {
                    // on cancel
                });
        },
        //评论绑定tag
        commentBindTag(comment_id) {
            this.defaultTag.forEach((item, index) => {
                if (item.active !== undefined && item.active === true) {
                    const request = Qs.stringify({
                        comment_id: comment_id,
                        tag_id: item.tag_id,
                    });
                    CommentTag.bindCommentTag(request, ({type, list}) => {
                        if (type === 200) {
                            Toast({
                                message: '发表评论成功',
                            });
                            this.$parent.saveInfo(this.commentData)
                        }
                    })
                }
            })
        },//评论绑定tag
        commentOneBindTag(comment_id, tag_id) {
            const request = Qs.stringify({
                comment_id: comment_id,
                tag_id: tag_id,
            });
            CommentTag.bindCommentTag(request, ({type, list}) => {
                if (type === 200) {
                    Toast({
                        message: '绑定成功',
                    });
                }
            })
        },
        //解绑评论tag
        commentUnbindCommentTag(comment_id, tag_id) {
            const request = Qs.stringify({
                comment_id: comment_id,
                tag_id: tag_id,
            });
            CommentTag.unbindCommentTag(request, ({type, list}) => {
                if (type === 200) {
                    Toast({
                        message: '解绑成功',
                    });
                }
            })
        },
        handleRemove(file, fileList) {
            // console.log(this.studentWorkfileList)
            // console.log(file, fileList);
            // console.log(file.rec_id);
            let index = this.studentWorkfileList.findIndex((item) => item.rec_id === file.rec_id);
            // console.log(this.studentWorkfileList[index])
            this.studentWorkfileList[index].deleteState = true;
            // this.studentWorkfileList.splice(index, 1);
            // console.log(this.studentWorkfileList)
        },
        handlePictureCardPreview(file) {
            // console.log(file)
            // this.dialogImageUrl = file.url;
            // this.dialogVisible = true;
        },
        closeClick() {
            this.clickCloseButState = this.clickCloseButState === false;
            const _this = this
            setTimeout(function () {
                _this.$emit('closeCommentAlert')
            }, 400)

        },
        studentWorkBeforeUpload(file) {
            var fileSize = file.size / 1024 / 1024 < 150;
            if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov', 'image/png', 'image/jpeg', 'image/gif'].indexOf(file.type) == -1) {
                this.$alert('请上传正确的格式', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {

                    }
                });
                return false;
            }
            if (!fileSize) {
                this.$alert('大小不能超过100MB', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {

                    }
                });
                return false;
            }
        },
        studentWorkSuccess(res, file) {
            var data = res.data,
                urlArr = data.url.split('/'),
                name = urlArr[urlArr.length - 1],
                video = ['mp4'],
                // img = ['png','PNG','jpg','JPG','gif','GIF'],
                suf = name.split('.')[1],
                type = video.indexOf(suf) === 0 ? 'video' : 'image';

            // if (this.myUploadFileList === '') {
            //     this.myUploadFileList = [];
            // }
            // this.myUploadFileList.push({
            //     id: data.id,
            //     relativeUrl: data.relativeUrl,
            //     url: data.url,
            //     name: name,
            //     type: type
            // });
            // this.myUploadFileList
            this.clickBufferPhoto({
                id: data.id,
                name: name,
                relativeUrl: data.relativeUrl,
                type: type,
            });
        },
        // 点击缓冲的图片时
        clickBufferPhoto(row) {
            document.getElementsByClassName('el-upload--picture-card')[0].className += ' active-upload-class';
            var n = 0;
            this.studentWorkfileList.forEach(item => {
                if (item.id === row.id) {
                    this.$message({
                        message: '已经有添加过了',
                        type: 'warning',
                        iconClass: 'iconClass',
                    });
                    n++;
                }
            });
            if (n === 0) {
                let url = '';
                if (row.type === 'video') {
                    url = './img/teacher-msg.jpg';
                } else {
                    url = row.relativeUrl;
                }
                this.studentWorkfileList.push({
                    id: row.id,
                    name: row.name,
                    url: url,
                    type: row.type,
                    rec_id: row.rec_id ? row.rec_id : 0
                });
            }

        },
        //上传成功回调
        handleVideoSuccess(res, file) {
            this.isShowUploadVideo = true;
            this.videoFlag = false;
            this.videoUploadPercent = 0;
            //后台上传地址
            if (res.code == 1) {
                this.curgimg = res.data.image_url
                this.videoForm.showVideoPath = (this.httpurl + res.data.image_url);
            } else {
                this.$alert(res.info, '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.$message({
                            type: 'info',
                            message: `action: ${action}`
                        });
                    }
                });
            }
        },
        showStudentWorkList() {
            this.studentWorkfileList = [];
            Api.fetchData({
                    works_id: this.commentData.work_id
                },
                '/bureau/v1.2/listWorksResource', ({type, list}) => {
                    list.forEach((item, index) => {
                        this.clickBufferPhoto({
                            id: item.resource_id,
                            name: (item.type === 'image' ? '照片' : '视频') + (index + 1),
                            relativeUrl: item.url,
                            type: item.type,
                            rec_id: item.rec_id
                        });
                    });
                })
        }
    }
}
</script>

<style lang="less" scoped>
@import "./CommentAlert";

</style>
