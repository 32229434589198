<template>
  <div id="download">
    <!--头部头像-->
    <div id="div1">
      <ul>
        <li v-for="(item, index) in info" @click="gameClick(item.game_url)">
          <div class="top">
            <div>
              <img :src="item.game_img" alt="" />
            </div>
          </div>
          <div class="bottom">
            <div>{{ item.game_name }}</div>
          </div>
        </li>
      </ul>
    </div>
    <IpadAlert
      v-if="alertState"
      :visible.sync="alertState"
      tip="请先激活课程"
      @handleAlertConfirm="alertState = false"
    ></IpadAlert>
  </div>
</template>

<script>
import { createdLoad } from "@/views/ipad/common";
import SelectMenu from "@/components/ipad/SelectMenu";
import Button1 from "@/components/ipad/Button-1";
import UnlockClass from "@/models/Home/UnloadClass";
import IpadAlert from "@/components/ipad/IpadAlert";

export default {
  name: "index",
  components: {
    SelectMenu,
    Button1,
    IpadAlert,
  },
  data() {
    return {
      list: [
        {
          img: "https://storage.shubenji.cn/GuoBao/ipad/game-2.png",
          name: "天平",
          state: 1,
          url: "/miniGames/ShapeEquate",
        },
        {
          img: "https://storage.shubenji.cn/GuoBao/ipad/game-1.png",
          name: "泡泡打字",
          state: 1,
          url: "/miniGames/bubble-typing-game",
        },
      ],
      info: [],
      alertState: false,
    };
  },
  created() {
    createdLoad();
    this.getListGamesInfo();
  },
  methods: {
    getListGamesInfo() {
      //添加缓存 只有学生端的时候
      UnlockClass.getListGames({}, ({ type, list }) => {
        if (type === 200) {
          console.log(list);
          list.forEach((item) => {
            if (item.game_name === "天平") {
              item.game_img =
                "https://storage.shubenji.cn/GuoBao/ipad/game-2.png";
            }
            if (item.game_name === "泡泡打字") {
              item.game_img =
                "https://storage.shubenji.cn/GuoBao/ipad/game-1.png";
            }
            item.game_url = item.game_url + "/";
          });
          this.info = list;
          console.log(this.info);
          if (this.$store.getters.getGameData !== null) {
            if (this.$store.getters.getGameData.length !== list.length) {
              this.$store.commit("setGameData", list);
            }
          } else {
            this.$store.commit("setGameData", list);
          }
        }
      });
    },
    gameClick(url) {
      if (this.$store.getters.getUserData.role === 2) {
        if (
          this.$store.getters.getUnloadGroupData !== null &&
          this.$store.getters.getUnloadGroupData > 0
        ) {
          const params = {
            // group_id: this.$store.getters.getGroupData.group_id
          };
          UnlockClass.getLockGamesByStudent(params, ({ type, list }) => {
            if (type === 200) {
              localStorage.setItem("lockGameId", JSON.stringify(list));
              if (list.indexOf(id) > -1) {
                window.open(url);
              }
            }
          });
        } else {
          this.alertState = true;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./game.less";
</style>
