<template>
  <div class="detail-box-class" :style="{ height: detailHeight + 'px' }">
    <div class="big-box-class">
      <div class="init-class" v-if="initState"></div>
      <div v-if="$store.getters.getUserData.role === 1">
        <div
          class="theBtn"
          @click="cutType"
          :class="{ 'float-icon': cIconImg === 'close-icon' }"
        >
          <!--                            <el-image class="covert-icon-class" :src="cIconImg"-->
          <!--                                      fit="cover"></el-image>-->
          <svg-icon :icon-class="cIconImg" class="svg-icon-class" />
          <span
            style="position: relative; top: -10px; color: #666666"
            v-if="cIconImg === 'close-icon'"
            >遮住</span
          >
          <span style="position: relative; top: -3px; color: #666666" v-else
            >打开</span
          >
        </div>
      </div>
      <div class="init2-class" id="aaa-id" ref="detailRef">
        <div class="title-content-class">
          <span>
            <img :src="titleLeftImg" alt="" style="vertical-align: middle" />
            {{ $route.query.lesson_name }}
            <img :src="titleRightImg" alt="" style="vertical-align: middle" />
          </span>
        </div>
        <div class="top-radian-class"></div>
        <div class="bottom-radian-class"></div>
        <div class="left-radian-class"></div>
        <div class="right-radian-class"></div>
        <div style="width: 92%; margin: 0 auto">
          <div id="wrap" :style="{ height: screenHeight + 'px' }">
            <div id="main" :style="{ top: nowTop + 'px' }">
              <!-- <div v-for="item in stepData" :key="item" :id="item.anchorId"
                                 class="pageccc" v-if="studentDataState" v-html="item.content"
                                 style="font-size: 22px;color: black;overflow-y: auto"> -->
              <div
                v-for="item in stepData"
                :key="item.anchorId"
                :id="item.anchorId"
                class="pageccc"
                v-if="studentDataState"
                v-html="item.content"
                style="font-size: 22px; color: black; overflow: hidden"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="type-box-class" v-if="studentDataState">
        <ul
          id="pageUl"
          type="circle"
          v-if="$store.getters.getUserData.role === 1"
        >
          <li
            :id="item.pageUlLi"
            class="pageUlLi"
            ref="pageUlLiRef"
            v-for="(item, index) in stepData"
            :key="index"
            @click="goAnchor(item.anchor)"
            :class="{ active: curIndex == index + 1 }"
          >
            &nbsp;
            <!--                        <div class="step-type-class">-->
            <span
              class="step-type-class"
              :title="`第 ${index + 1}步 ${item.title}`"
              >第{{ index + 1 }}步 {{ item.title }}</span
            >
            <!--                        </div>-->
          </li>
        </ul>
        <ul id="pageUl" type="circle" v-else>
          <li
            :id="item.pageUlLi"
            class="pageUlLi"
            ref="pageUlLiRef"
            v-for="(item, index) in studentInfo"
            :key="index"
            @click="goAnchor(item.anchor)"
            :class="{ active: curIndex == index + 1 }"
          >
            &nbsp;
            <!--                        <div class="step-type-class">-->
            <span class="step-type-class"
              >第{{ index + 1 }}步 {{ item.title }}</span
            >
            <!--                        </div>-->
          </li>
        </ul>
      </div>
    </div>

    <div class="sup-class">
      <el-image class="menu-icon-class" :src="supImg" fit="cover"></el-image>
    </div>

    <div class="pattern-class" v-if="switchState">
      <span>
        <span :class="{ 'css-pattern-class': patternState === 1 }"
          >备课模式</span
        >
        /
        <span :class="{ 'css-pattern-class': patternState === 2 }"
          >上课模式</span
        >
      </span>
      <el-switch
        v-model="value"
        :width="80"
        active-color="#f1bc82"
        @change="switchChange"
      >
      </el-switch>
    </div>

    <div
      class="title-class"
      v-if="
        rightTopState &&
        $store.getters.getCourseData[0].title
          .toLowerCase()
          .indexOf('scratch') !== -1
      "
    >
      <span v-if="$store.getters.getUserData.role === 2">
        <span class="span-1-class">
          <span class="span-2-class" @click="studentHrefScratch">亲自出码</span>
        </span>
      </span>
      <span v-else>
        <span class="span-1-class" v-if="lessonList[0].extra_code.length === 1">
          <span
            class="span-2-class"
            @click="
              teacherHrefScratch(
                lessonList[0].lesson_id,
                lessonList[0].extra_code[0].id
              )
            "
            v-if="$store.getters.getUserData.role === 1"
            >源码</span
          >
        </span>
        <span class="span-1-class" v-else>
          <el-popover
            placement="bottom"
            trigger="hover"
            popper-class="popperClass"
          >
            <ul class="extraCodeList">
              <li
                v-for="(val, index) in lessonList[0].extra_code"
                :key="index"
                @click="teacherHrefScratch(lessonList[0].lesson_id, val.id)"
              >
                {{ val.code_title }}
              </li>
            </ul>
            <template #reference>
              <el-button class="span-2-class">源码</el-button>
            </template>
          </el-popover>
        </span>
      </span>
      <div class="full-but-class">
        <span class="span-1-class">
          <span class="span-2-class" @click="fullVideoClick">完整视频</span>
        </span>
      </div>
    </div>

    <div class="title-class-con" v-if="$store.getters.getUserData.role === 2">
      <span>
        <span class="span-1-class">
          <span class="span-2-class" @click="teachPlanClick">课时文字</span>
        </span>
      </span>
      <!--            <div class="full-but-class">-->
      <!--                <span class="span-1-class">-->
      <!--                    <span class="span-2-class" @click="fullVideoClick">完整视频</span>-->
      <!--                </span>-->
      <!--            </div>-->
    </div>

    <VideoAlert
      v-if="fullVideoState"
      :fullVideoData="fullVideoData"
    ></VideoAlert>
  </div>
</template>

<script>
import {loadJs} from "../../assets/js/loadVideo";
import LessonVideo from "../../models/Home/LessonVideo";
import axios from "axios";
import Qs from "qs";
import Login from "../../models/Login/Login";
import websocket from "../../utils/websocket";
import VideoAlert from "../../components/video/VideoAlert";
import eventVue from "../../assets/js/event";
import UnloadClass from "../../models/Home/UnloadClass";
export default {
    name: 'Home',
    components: {
        VideoAlert
    },
    data() {
        return {
            cIconImg: 'open-icon',
            openIcon: 'open-icon',
            coverIcon: 'close-icon',
            isShade: '',
            isShadeText: '没遮住',
            patternState: 1,
            value: false,
            switchState: true,
            titleLeftImg: './img/title-left.png',
            titleRightImg: './img/title-right.png',
            supImg: './img/superman.png',
            img1: './img/lesson/1.png',
            img2: './img/lesson/2.png',
            seen: false,
            screenWeight: 0,        // 屏幕宽度
            screenHeight: 0,        // 屏幕高度
            index: 1,               // 用于判断翻页
            curIndex: 1,            // 当前页的index
            startTime: 0,           // 翻屏起始时间
            endTime: 0,             // 上一次翻屏结束时间
            nowTop: 0,              // 翻屏后top的位置
            pageNum: 0,             // 一共有多少页
            main: Object,
            obj: Object,
            stepData: [],
            textContent: '',
            scrollState: 0,
            ulBoxHeight: 0,
            lessonList: [],
            rightTopState: false,
            stepInfo: [],
            stepCutInfo: [],
            stepOldInfo: [],
            scrollNum: 0,
            initState: true,
            studentDataState: false,
            info: [],
            studentInfo: [],
            detailHeight: document.documentElement.clientHeight,
            pageUlHeight: document.documentElement.clientHeight / 100 * 70,
            fullVideoState: false,
            fullVideoData: {}
        }
    },
    created() {

        console.log(document.documentElement.clientHeight)
        if (localStorage.getItem('info') !== null) {
            // 服务端拿的 token 可以从 vuex、localStorage 等地方取
            axios.defaults.headers['token'] = JSON.parse(localStorage.getItem('info')).token;
        } else {
            axios.defaults.headers['token'] = ``;
        }

        if (this.$store.getters.getUserData.role === 1) {
            this.getLessonByInfo();

        } else {
            this.getStudentLessonList();
            this.switchState = false;
            this.value = true;
            this.rightTopState = true;
            this.realStudentStudyTime()
        }

        const _this = this;
        setTimeout(function () {
            _this.initState = false;
        }, 1000)
        this.getLessonDetailInfo();
        this.getListLessonTemConInfo();

        //激活课时  注意
        if(this.$store.getters.getUserData.role === 2) {
            this.getActivateLesson();
        }
    },
    inject: ["conn_id"],
    computed: {
        theConnId() {
            return this.conn_id()
        }
    },
    watch: {
        theConnId() {
            console.log('conn_id改变')
        }
    },
    mounted() {
        const _this = this;
        this.cIconImg = localStorage.getItem('covertState') === 'close' ? this.coverIcon : this.openIcon;

        window.addEventListener('storage', function (e) {
            console.log(e)
            if (e.key === 'info') {
                if (e.newValue === null) {
                    _this.$Dialog.confirm(
                        {
                            message: '请重新登录',
                            confirmButtonColor: '#FF8D15',
                            showCancelButton: false
                        })
                        .then(() => {
                            window.location.href = '/'
                        })
                        .catch(() => {
                            // on cancel
                        });
                }
            } else if (e.key === 'unloadGroupData') {
                if (e.newValue === null || e.newValue === '0') {
                    _this.$Dialog.confirm(
                        {
                            message: '请先解锁课程',
                            confirmButtonColor: '#FF8D15',
                            showCancelButton: false,
                            showConfirmButton: false
                        })
                        .then(() => {
                            window.location.href = '/'
                        })
                        .catch(() => {
                            // on cancel
                        });
                }
            } else if (e.key === 'covertState') {
                _this.cIconImg = localStorage.getItem('covertState') === 'close' ? _this.coverIcon : _this.openIcon;
            }
        })
        this.keyDown()
    },
    methods: {
        getActivateLesson(){
            let group_id = 0;
            if(this.$route.query.unlock != undefined){
                group_id = this.$route.query.unlock_group_id
            }else{
               group_id = this.$route.query.group_id
            }
            const params = {
                group_id: group_id,
                lesson_id: this.$route.query.lesson_id,
            };

            LessonVideo.getActivateLesson(params, ({type, list}) => {
                if (type === 200) {
                    if(this.$route.query.unlock != undefined){
                        setTimeout(()=>{
                            UnloadClass.getListUnlockedLessonNum({}, ({ type, list }) => {
                                if (type === 200) {
                                    localStorage.setItem('unloadGroupData',list.list.lesson_num)
                                }
                            });
                        },1000)

                    }

                }
            })
        },
        // 监听键盘
        keyDown() {
            document.onkeydown = (e) => {
                //事件对象兼容
                let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
                //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
                //左
                let pageUlHeight = document.getElementById("pageUl").offsetHeight;
                let pageLlHieght = this.$refs.pageUlLiRef[0].offsetHeight + 10;
                if (e1 && e1.keyCode == 38) {
                    // 按下左箭头
                    if (this.index - 1 >= 1) {
                        this.index--;
                        // console.log(this.index);
                        if (this.index != 0) {
                            if (this.scrollNum !== 0) {
                                this.scrollNum--;
                            }
                            document.getElementById('pageUl').scrollTop = pageLlHieght * this.index - (parseInt(pageUlHeight / pageLlHieght / 2) * pageLlHieght);
                            this.toPage(this.index);
                        }
                    }
                } else if (e1 && e1.keyCode == 40) {
                    // 按下右箭头
                    if (this.index + 1 <= this.stepData.length) {
                        // 向下滚动
                        if (this.index < this.stepData.length) {
                            this.index++;
                            if (this.index <= this.pageNum) {
                                if (this.index > parseInt(pageUlHeight / pageLlHieght / 2)) {
                                    this.scrollNum++;
                                    document.getElementById('pageUl').scrollTop = pageLlHieght * (this.index - parseInt(pageUlHeight / pageLlHieght / 2));
                                }
                                this.toPage(this.index);
                            }
                        }
                    }


                    // } else if (delta < 0 && parseInt(this.main.offsetTop) < 0) {
                    //     // 向上滚动

                    //
                    // }
                    // 本次翻页结束，记录结束时间，用于下次判断
                    this.endTime = new Date().getTime();
                }
            }
        },
        fullVideoClick() {
            // this.$emit('videoData', this.fullVideoData);
            this.fullVideoState = this.fullVideoState === false;
        },
        realStudentStudyTime() {
            const request = Qs.stringify({
                group_id: this.$store.getters.getGroupData.group_id,
                lesson_id: this.$route.query.lesson_id,
                duration: 1,
                course_id: this.$store.getters.getCourseData[0].id
            });
            setInterval(function () {
                LessonVideo.updateDuration(request, ({type, list}) => {
                    if (type === 200) {

                    }
                })
            }, 60000)
        },
        teachPlanClick() {
            // this.teachPlanStatus = true;
            // this.plan = plan;
            if (this.$route.query.text === null) {
                this.$message({
                    message: '暂无文字',
                    type: 'error',
                    iconClass: 'iconClass',
                });
            } else {
                window.open(this.$fileName+'/#/LessonText?textData=' + this.$route.query.text, '_blank');
            }
        },
        getLessonByInfo() {
            let params = {
                course_id: this.$route.query.course_id,
                group_id: this.$route.query.group_id,
                lesson_id: this.$route.query.lesson_id
            }
            this.loading = true;
            LessonVideo.getLessonList(params, ({type, list}) => {
                if (type === 200) {
                    this.lessonList = list.list;
                    let video_url;
                    let video_img;
                    video_url = this.lessonList[0].video_url
                    video_img = this.lessonList[0].video_image
                    this.fullVideoData = {video_url: video_url, video_img: video_img}

                    this.rightTopState = true;
                }
            })
        },
        getStudentLessonList() {
            let params = {
                course_id: this.$route.query.course_id,
                group_id: this.$route.query.group_id,
                lesson_id: this.$route.query.lesson_id,
                goods_category: localStorage.getItem('goodsCategory'),
            }
            console.log(params);
            this.loading = true;
            LessonVideo.getStudentLessonList(params, ({type, list}) => {
                if (type === 200) {
                    this.lessonList = list.list;
                    let video_url;
                    let video_img;
                    if (this.lessonList[0].video_url !== '' || this.lessonList[0].video_url !== undefined) {
                        video_url = this.lessonList[0].video_url
                        video_img = this.lessonList[0].video_image
                    }
                    this.fullVideoData = {video_url: video_url, video_img: video_img}
                }
            })
        },

        studentHrefScratch() {
            window.open("/gbscratch/");
        },
        teacherHrefScratch(lesson_id, rec_id) {
            window.open("/gbscratch/?loadUrlId=" + lesson_id + '&recUrlId=' + rec_id);
        },
        switchChange() {
            const _this = this;
            if (this.value === true) {

                this.patternState = 2;
                this.stepCutInfo = this.stepData;

                this.stepOldInfo.forEach((item, index) => {
                    if (item.visible_type === 'all' || item.visible_type === 'only_student') {
                        _this.stepInfo.push(item)
                    }
                })
                _this.stepInfo.forEach((item, index) => {
                    item.anchor = '#anchor-' + (index + 1);
                    item.anchorId = 'anchor-' + (index + 1);
                    item.pageUlLi = 'pageUlLi' + (index + 1);
                })
                this.stepData = this.stepInfo;
                this.toPage(1)
                let top = document.getElementById('pageUl').scrollTop;
                let self = this;
                // 实现滚动效果
                const timeTop = setInterval(() => {
                    document.getElementById('pageUl').scrollTop = top -= 50;
                    if (top <= 0) {
                        clearInterval(timeTop);
                    }
                }, 0);
                _this.stepInfo = [];
                //获取行高
                this.scrollMain();
            } else {
                // this.stepData = this.stepInfo;
                this.patternState = 1;
                this.stepOldInfo.forEach((item, index) => {
                    item.anchor = '#anchor-' + (index + 1);
                    item.anchorId = 'anchor-' + (index + 1);
                    item.pageUlLi = 'pageUlLi' + (index + 1);
                })
                this.stepData = this.stepOldInfo;
                this.toPage(1)

                let top = document.getElementById('pageUl').scrollTop;
                let self = this;
                // 实现滚动效果
                const timeTop = setInterval(() => {
                    document.getElementById('pageUl').scrollTop = top -= 50;
                    if (top <= 0) {
                        clearInterval(timeTop);
                    }
                }, 0);
                _this.stepInfo = [];

                //获取行高
                this.scrollMain();

            }
        },
        scrollMain() {
            let _this = this;
            this.$nextTick(function () {
                console.log('v-for渲染已经完成')

                var videoElement = document.getElementsByTagName("video");
                $("video").bind('contextmenu', function () {
                    return false;
                })


                _this.screenWeight = document.documentElement.clientWidth;
                _this.screenHeight = document.documentElement.clientHeight / 100 * 78;
                console.log(_this.screenWeight,_this.screenHeight)
                // alert(_this.screenHeight)
                _this.main = document.getElementById("main");
                _this.obj = document.getElementsByTagName("div");
                for (let i = 0; i < this.obj.length; i++) {
                    if (this.obj[i].className == 'pageccc') {

                        // _this.obj[i].style.height = "78vh";
                        _this.obj[i].style.height = this.screenHeight + "px";
                        // _this.obj[i].style.width = "100%";
                        if (_this.screenHeight > 400 && _this.screenHeight < 500) {
                            _this.obj[i].style.width = this.screenHeight + 250 + "px";
                            _this.obj[i].style.height = this.screenHeight + "px";
                        }
                        if (_this.screenHeight > 600 && _this.screenHeight < 700) {
                            _this.obj[i].style.width = this.screenHeight + 380 + "px";
                            _this.obj[i].style.height = this.screenHeight + "px";
                        }
                    }
                }
                _this.pageNum = document.querySelectorAll(".pageccc").length;
                var content = document.getElementById("aaa-id");
                // 浏览器兼容
                if ((navigator.userAgent.toLowerCase().indexOf("firefox") != -1)) {
                    document.addEventListener("DOMMouseScroll", _this.scrollFun, false);
                } else if (document.addEventListener) {
                    content.addEventListener("mousewheel", _this.scrollFun, true);
                } else if (document.attachEvent) {
                    document.attachEvent("onmousewheel", _this.scrollFun);
                } else {
                    document.onmousewheel = this.scrollFun;
                }

                for (let i = 0; i < videoElement.length; i++) {
                    // videoElement[i].setAttribute('controlslist', 'nodownload');
                    videoElement[i].setAttribute('controlslist', 'nodownload noremoteplayback');
                    videoElement[i].setAttribute('disablePictureInPicture', true);
                    videoElement[i].setAttribute('contextmenu', false)
                    videoElement[i].setAttribute('controls', 'controls')
                }

                for (let i = 0; i < videoElement.length; i++) {
                    // videoElement[i].setAttribute('controlslist', 'nodownload');
                    videoElement[i].setAttribute('controlslist', 'nodownload noremoteplayback');
                    videoElement[i].setAttribute('disablePictureInPicture', true);
                    videoElement[i].setAttribute('contextmenu', false)
                    videoElement[i].setAttribute('controls', 'controls')
                }
            })
        },
        //步骤查询
        getLessonDetailInfo() {
            const lesson_id = this.$route.query.lesson_id;
            // console.log(lesson_id)
            LessonVideo.getListLessonStep({lesson_id: lesson_id, page: 1, limit: 999}, ({type, list}) => {
                if (type === 200) {
                    this.stepData = list.list;
                    this.getListLessonTemConInfo();
                }
            })
        },
        //步骤查询
        async getListLessonTemConInfo() {
            const lesson_id = this.$route.query.lesson_id;
            const _self = this;
            let DataLength = this.stepData.length;
            console.log(DataLength)

            // if (this.$store.getters.getUserData.role === 1) {
            this.stepData.forEach((item, index) => {
                LessonVideo.getListLessonTemCon({step_id: item.id}, ({type, list}) => {
                    if (type === 200) {
                        if (list.length === 0) {
                            item.content = '暂时没上传内容';
                        } else {
                            item.content = list[0].content;
                        }
                        let textString = '';
                        let js_url = '';
                        item.visible_type = list[0].visible_type;

                        list.forEach(val => {
                            textString += val.content;
                            loadJs(val.js_url)
                        })
                        if (DataLength === index + 1) {
                            _self.nextTickData(_self.stepData);
                        }
                    }
                })
            })

        },
        async studentApiData(step_id) {
            let contentData = [];
            LessonVideo.getListLessonTemCon({step_id: step_id}, ({type, list}) => {
                if (type === 200) {
                    let textString = '';
                    list.forEach(val => {
                        textString += val.content;
                        loadJs(val.js_url)
                    })
                    return textString;
                    // contentData.push({content: textString})
                }
            })
            // console.log(contentData)
        },
        nextTickData(listType) {
            if (this.$store.getters.getUserData.role === 2) {
                let studentData = [];
                listType.forEach(item => {
                    if (item.visible_type !== 'only_teacher' && item.title.indexOf('教案') === -1) {
                        studentData.push(item)
                    }
                })

                studentData.forEach((val, index) => {
                    val.anchor = '#anchor-' + (index + 1);
                    val.anchorId = 'anchor-' + (index + 1);
                    val.pageUlLi = 'pageUlLi' + (index + 1);
                })

                // listType.forEach(item => {
                //     if (item.visible_type !== 'only_teacher') {
                //         studentData.push(item)
                //     }
                // })
                this.studentInfo = studentData;
                this.stepData = studentData;
                this.studentDataState = true;
            } else {
                listType.forEach((item, index) => {
                    item.anchor = '#anchor-' + (index + 1);
                    item.anchorId = 'anchor-' + (index + 1);
                    item.pageUlLi = 'pageUlLi' + (index + 1);
                })
                console.log(listType);
                this.stepOldInfo = listType;
                this.studentDataState = true
            }
            //给div高度
            this.scrollMain();

            // this.stepInfo = this.stepData;
        },
        scrollFun(event) {
            this.startTime = new Date().getTime();
            // mousewheel事件中的 “event.wheelDelta” 属性值：返回的如果是正值说明滚轮是向上滚动
            // DOMMouseScroll事件中的 “event.detail” 属性值：返回的如果是负值说明滚轮是向上滚动
            let delta = event.detail || (-event.wheelDelta);
            // 如果当前滚动开始时间和上次滚动结束时间的差值小于1.5s，则不执行翻页动作，这样做是为了实现类似节流的效果
            if ((this.startTime - this.endTime) > 1500) {
                let pageUlHeight = document.getElementById("pageUl").offsetHeight;
                let pageLlHieght = this.$refs.pageUlLiRef[0].offsetHeight + 10;
                if (delta > 0 && parseInt(this.main.offsetTop) >= -(this.screenHeight * (this.pageNum - 2))) {

                    // 向下滚动
                    console.log(this.index, this.stepData.length)
                    if (this.index < this.stepData.length) {
                        this.index++;
                        if (this.index <= this.pageNum) {
                            if (this.index > parseInt(pageUlHeight / pageLlHieght / 2)) {
                                this.scrollNum++;
                                document.getElementById('pageUl').scrollTop = pageLlHieght * (this.index - parseInt(pageUlHeight / pageLlHieght / 2));
                            }
                            this.toPage(this.index);
                        }
                    }

                } else if (delta < 0 && parseInt(this.main.offsetTop) < 0) {
                    // 向上滚动
                    this.index--;
                    // console.log(this.index);
                    if (this.index != 0) {
                        // if (this.index < parseInt(pageUlHeight / pageLlHieght / 2)) {
                        if (this.scrollNum !== 0) {
                            this.scrollNum--;
                        }
                        document.getElementById('pageUl').scrollTop = pageLlHieght * this.index - (parseInt(pageUlHeight / pageLlHieght / 2) * pageLlHieght);
                        // }
                        this.toPage(this.index);
                    }

                }
                // 本次翻页结束，记录结束时间，用于下次判断
                this.endTime = new Date().getTime();
            }
        },
        // 翻页
        toPage(index) {
            var videoElement = document.getElementsByTagName("video");
            for (let i = 0; i < videoElement.length; i++) {
                videoElement[i].pause();
            }

            // console.log(this.stepData.length)
            // console.log(videoElement)
            console.log(index, this.curIndex)
            if (index != this.curIndex) {
                let delta = index - this.curIndex;
                this.nowTop = this.nowTop - delta * this.screenHeight;
                this.curIndex = index;
                this.index = index;
            }
        },
        /**
         * 锚点
         */
        goAnchor(selector) {
            console.log(selector)
            let company = selector.split("-")
            let index = company[1] * 1;

            this.toPage(index);
        },
        cutType() {
            // this.isShadeText = this.isShadeText === '没遮住' ? '遮住啦' : '没遮住';
            this.cIconImg = localStorage.getItem('covertState') === 'close' ? this.openIcon : this.coverIcon;
            // if(){
            //     this.openIcon = this.coverIcon;
            // }
            websocket.Send({
                // token: JSON.parse(localStorage.getItem('info')).token,
                token: this.$store.getters.getUserData.token,
                conn_id: this.theConnId,
                data: '',
                group_id: this.$store.getters.getGroupData.group_id,
                action: 'flip'
            });
        }
    }
}
</script>
<style scoped>
.title-class {
  position: absolute;
  top: 3%;
  right: 10px;
}

.title-class-con {
  position: absolute;
  top: 10%;
  right: 10px;
}

.span-1-class {
  font-size: 28px;
  font-weight: bold;
  color: hsla(0, 0%, 29%, 1);
  padding: 10px 6px;
  background: hsla(42, 100%, 84%, 1);
  border-radius: 20px;
  border: 3px solid #605a84;
}

.span-2-class {
  padding: 6px 40px;
  border-radius: 20px;
  border: 2px dashed hsla(43, 65%, 66%, 1);
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
  color: hsla(0, 0%, 29%, 1);
  background: hsla(42, 100%, 84%, 1);
}

.extraCodeList li {
  padding: 10px;
}

.extraCodeList li:hover {
  background: #fa9429;
  color: #ffffff;
  cursor: pointer;
}

.detail-box-class {
  width: 100%;
  height: 100%;
  min-width: 100%;
  /*min-height: 70vh;*/
  background: url("../../assets/img/lesson-back3.png");
  background-size: 100%;
}

#wrap {
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

#main {
  position: relative;
  transition: top 1.5s;
}

.page {
  /*谨删*/
  width: 100%;
  margin: 0;
}

.type-box-class {
  position: absolute;
  right: 2%;
  top: 17%;
}

#pageUl::-webkit-scrollbar {
  width: 0 !important;
}

#pageUl {
  -ms-overflow-style: none;
}

#pageUl {
  overflow: -moz-scrollbars-none;
}

#pageUl {
  position: absolute;
  right: 4%;
  top: 30%;
  height: 70vh;
  overflow-y: auto;
  color: #ffffff;
  font-size: 22px;
  /*overflow-y: auto;*/
  /*height: 100%;*/
}

.step-type-class {
  display: inline-block;
  width: 200px; /*要显示文字的宽度*/
  height: 100%;
  text-overflow: ellipsis; /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
  white-space: nowrap; /*让文字不换行*/
  overflow: hidden; /*超出要隐藏*/
  line-height: 60px;
}

.active {
  color: #ffffff !important;
  box-shadow: 0 20px 20px -20px #5e5e5e;
}

.pageUlLi {
  background: url("../../assets/img/lesson-type-but.png");
  background-size: 100% 100%;
  width: 240px;
  height: 60px;
  margin-top: 15px;
  text-align: center;
  cursor: pointer;
  color: #fdcf98;
}

.pageUlLi:hover {
  /*background: linear-gradient(119deg, #f6d181 0%, #fab76a 100%);*/
}

.init-class {
  width: 72%;
  height: 78vh;
  border-radius: 10%;
  background: #ffffff;
  position: absolute;
  top: 52%;
  left: 46%;
  transform: translate(-50%, -50%); /*向左向上分别平移自身的一半*/
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  z-index: 1003;
  padding: 10px 10px;
}

.init2-class {
  width: 72%;
  height: 78vh;
  color: white;
  border-radius: 10%;
  padding: 10px 10px;
  background: #ffffff;
  position: absolute;
  top: 52%;
  left: 46%;
  transform: translate(-50%, -50%); /*向左向上分别平移自身的一半*/
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  text-align: center;
  /*transition: 1s;*/
  z-index: 1002;
}

.big-box-class {
  position: relative;
  height: 100%;
}

.title-content-class {
  width: 100%;
  position: absolute;
  top: -70px;
  left: -2%;
  color: black;
  z-index: 3000;
}

.title-content-class span {
  background: #ffffff;
  font-size: 24px;
  font-weight: bold;
  color: #605a84;
  padding: 20px 30px;
  border-radius: 28px;
}

.theBtn {
  /*border: 1px solid;*/
  /*background-color: pink;*/
  border-radius: 12px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  z-index: 20000;
  position: fixed;
  top: 0px;
  right: 20%;
  width: 40px;
  height: 100px;
  text-align: center;
}

.svg-icon {
  width: 40px;
  height: 50px;

  margin-top: 20px;
}

.svg-icon:hover {
  text-align: end;
}

.covert-icon-class {
  width: 40px;
  height: 60px;
}

.top-radian-class {
  width: 105%;
  height: calc(78vh + 91px);
  border-radius: 100% 100% 0 0/120px 120px 0 0;
  clip: rect(0px, 74.5vw, 90vh, 27px);
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: -70px;
  left: -2%;
}

.bottom-radian-class {
  width: 105%;
  height: 70px;
  clip: rect(0px, 74.5vw, 70px, 27px);
  border-radius: 0 0 100% 100%/0 0 120px 120px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: -70px;
  left: -2%;
}

.left-radian-class {
  width: 50px;
  height: calc(78vh + 70px);
  border-radius: 100px 0 0 100px/120px 50px 50px 120px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: -50px;
  top: -25px;
}

.right-radian-class {
  width: 50px;
  height: calc(78vh + 80px);
  border-radius: 0 100px 100px 0/150px 100px 100px 150px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: -50px;
  top: -30px;
}

.sup-class {
  position: absolute;
  top: 20%;
  left: 10px;
}

.pattern-class {
  position: absolute;
  top: 2%;
  left: 10px;
}

/deep/ .el-switch__core {
  height: 30px !important;
  border-radius: 25px !important;
  border: 2px solid #000000 !important;
  /*background: none !important;*/
}

/deep/ .el-switch__core:after {
  width: 23px !important;
  height: 23px !important;
  background: #ff8b0c !important;
}

/deep/ .el-switch.is-checked .el-switch__core::after {
  margin-left: -23px !important;
}

/deep/ .el-switch__label {
}

/deep/ .el-switch__label--left span {
  font-weight: bold;
  font-size: 20px !important;
}

/deep/ .el-switch__label--right span {
  font-weight: bold;
  font-size: 20px !important;
}

/deep/ .is-active {
  color: #ff8b0c;
}

.css-pattern-class {
  color: #ff8b0c;
  font-size: 20px;
}

.pageccc {
  display: inline-flex;
  align-items: center;
}

.full-but-class {
  position: fixed;
  bottom: 20px;
  right: 10px;
}

/deep/ .pageccc img {
  width: 100%;
  border-radius: 20px;
}

/deep/ .pageccc video {
  width: 100%;
  border-radius: 20px;
}

.float-icon {
  color: #c4c4c4;
  margin-top: 7px;
}
</style>
