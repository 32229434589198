<template>
  <div class="plan-class">
    <div class="main-class" v-show="state">
      <div v-for="(item, index) in list" :key="index" class="img-box">
        <img :src="item.body_image_url" alt="" ref="img" class="img-class" />
      </div>
    </div>
  </div>
</template>

<script>
import LessonVideo from "../../models/Home/LessonVideo";
import axios from "axios";

export default {
  name: "PlanView",
  data() {
    return {
      list: "",
      state: false,
    };
  },
  created() {
    if (localStorage.getItem("info") !== null) {
      // 服务端拿的 token 可以从 vuex、localStorage 等地方取
      axios.defaults.headers["token"] = JSON.parse(
        localStorage.getItem("info")
      ).token;
    } else {
      axios.defaults.headers["token"] = ``;
    }
    LessonVideo.getListBodies(
      {
        act_id: this.$route.query.act_id,
      },
      ({ type, list }) => {
        if (type === 200) {
          this.list = list;
          this.state = true;
          this.imgDmoSave();
        }
      }
    );
  },
  methods: {
    imgDmoSave() {
      this.$nextTick(() => {
        console.log(this.$refs.img);
        this.$refs.img.forEach((item) => {
          console.log(item.style.clientHeight)
          // item.height = 
        });
      });

      // let el = document.getElementsByClassName('img-class');
      // console.log(el)
      // el.addEventListener("load", () => {
      //   let width = el.naturalWidth;
      //   let height = el.naturalHeight;
      //   console.log(width,height)
      //   // 获取父元素的设定宽度
      //   let parentWidth = Number(
      //     window.getComputedStyle(el.parentNode).width.replace("px", "")
      //   );
      //   let parentHeight = Number(
      //     window.getComputedStyle(el.parentNode).height.replace("px", "")
      //   );
      //   // 获取父元素宽高比例
      //   let scale = binding.value
      //     ? binding.value.scale
      //     : parentHeight / parentWidth;
      //   // 清除元素style值
      //   el.style = "";
      //   if (height / width < scale) {
      //     el.style.height = "100%";
      //     // 获取图片放入后实际的宽度
      //     // let imgWidth = Number(window.getComputedStyle(el).width.replace('px', ''));
      //     let imgWidth = (width * parentHeight) / height;
      //     el.style.marginLeft = `${(parentWidth - imgWidth) / 2}px`;
      //   } else if (height / width > scale) {
      //     el.style.width = "100%";
      //   } else {
      //     el.style.width = "100%";
      //     el.style.height = "100%";
      //   }
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.plan-class {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;

  .main-class {
    width: 70%;
    margin: 10px auto;
    .img-box {
      width: 100%;
      height: 8.7rem;
      // background: pink;
      display: flex;
      align-items: center;
      // margin-top: -210px;
      // float: left;
      // display: flex;
      overflow: hidden;
      // align-items: center;
      justify-content: center;
      img{
        width: 100%;
      }
    }
  }
}
</style>
